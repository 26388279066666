import {HandleErrorResponse} from "../HandleErrorResponse";
import {PlaylistPreferences, PlaylistSettingsResponse, Setting} from "./PlaylistPreferences";

const paths = {
    playlistPreferences: '/api/playlist-preferences',
    playlistSettings: '/api/playlist-preferences/settings',
    availablePlaylistPreferences: '/api/playlist-preferences/available',
}

export const getPlaylistPreferences = (): Promise<PlaylistPreferences> => {
    return fetch(paths.playlistPreferences)
        .then(HandleErrorResponse)
        .then(res => res.json());
}

export const getPlaylistSettings = (): Promise<Setting[]> => {
    return fetch(paths.playlistSettings)
        .then(HandleErrorResponse)
        .then(res => res.json())
        .then((res: PlaylistSettingsResponse) => res.settings);
}

export const updatePlaylistSettings = (createPlaylistsAsPrivate: boolean, removePlaylistPostfix: boolean): Promise<void> => {
    let request = {
        createPlaylistsAsPrivate: createPlaylistsAsPrivate,
        removePlaylistPostfix: removePlaylistPostfix
    };
    
    return fetch(paths.playlistSettings, {
        method: 'PUT',
        body: JSON.stringify(request),
        headers: {
            'Content-type': 'application/json; charset=UTF-8'
        },
    }).then(HandleErrorResponse).then();
}

export const updatePlaylistPreferences = (playlistPreferences: string[]): Promise<void> => {
    var request = {
        playlistPreferences: playlistPreferences
    };

    return fetch(paths.playlistPreferences, {
        method: 'PUT',
        body: JSON.stringify(request),
        headers: {
            'Content-type': 'application/json; charset=UTF-8'
        },
    }).then(HandleErrorResponse).then();

}
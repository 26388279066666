import React from "react";
import './Groups.css';
import {useAuthorizationContext} from "../../../context/authorizationContext";
import {PlaylistRow, PlaylistTable} from "./Groups";
import {GoToLoginButton} from "../../common/Buttons";

export const Genre = () => {
    const {loggedIn} = useAuthorizationContext();
    return (
        <div>
            <div className="group pageContentSegment">
                <h1>Genre and subgenre playlists</h1>
                <p>Liked Songs Manager supports creating playlists from any genre or subgenre found in Spotify.</p>
                <PlaylistTable>
                    <PlaylistRow name={"8 Bit"} availableFree={true}/>
                    <PlaylistRow name={"Acoustic"} availableFree={true}/>
                    <PlaylistRow name={"Baroque"} availableFree={true}/>
                    <PlaylistRow name={"Bluegrass"} availableFree={true}/>
                    <PlaylistRow name={"Blues"} availableFree={true}/>
                    <PlaylistRow name={"Christian"} availableFree={true}/>
                    <PlaylistRow name={"Classical"} availableFree={true}/>
                    <PlaylistRow name={"Country"} availableFree={true}/>
                    <PlaylistRow name={"Dancehall"} availableFree={true}/>
                    <PlaylistRow name={"Disco"} availableFree={true}/>
                    <PlaylistRow name={"Drum and Bass"} availableFree={true}/>
                    <PlaylistRow name={"EDM"} availableFree={true}/>
                    <PlaylistRow name={"Electronica"} availableFree={true}/>
                    <PlaylistRow name={"Emo"} availableFree={true}/>
                    <PlaylistRow name={"Folk"} availableFree={true}/>
                    <PlaylistRow name={"Funk"} availableFree={true}/>
                    <PlaylistRow name={"Grunge"} availableFree={true}/>
                    <PlaylistRow name={"Hip Hop"} availableFree={true}/>
                    <PlaylistRow name={"Indie"} availableFree={true}/>
                    <PlaylistRow name={"Industrial"} availableFree={true}/>
                    <PlaylistRow name={"J-Pop"} availableFree={true}/>
                    <PlaylistRow name={"Jazz"} availableFree={true}/>
                    <PlaylistRow name={"K-Pop"} availableFree={true}/>
                    <PlaylistRow name={"Latin"} availableFree={true}/>
                    <PlaylistRow name={"Metal"} availableFree={true}/>
                    <PlaylistRow name={"Pop"} availableFree={true}/>
                    <PlaylistRow name={"Punk"} availableFree={true}/>
                    <PlaylistRow name={"Rap"} availableFree={true}/>
                    <PlaylistRow name={"Reggae"} availableFree={true}/>
                    <PlaylistRow name={"RnB"} availableFree={true}/>
                    <PlaylistRow name={"Rock"} availableFree={true}/>
                    <PlaylistRow name={"Soul"} availableFree={true}/>
                    <PlaylistRow name={"Swing"} availableFree={true}/>
                    <PlaylistRow name={"Techno"} availableFree={true}/>
                    <PlaylistRow name={"Trance"} availableFree={true}/>
                    <PlaylistRow name={"Trap"} availableFree={true}/>
                </PlaylistTable>
                {!loggedIn ? <GoToLoginButton/> : null}
            </div>
        </div>
    );
}


import {HandleErrorResponse} from "../HandleErrorResponse";
import Urls from "../../util/Urls";
import {MembershipLevelResponse} from "./MembershipLevelResponse";
import {UserIdResponse} from "./UserIdResponse";

const paths = {
    displayName: '/api/user/display-name',
    deleteData: '/api/user/delete-data',
    getMembershipLevel: '/api/user/membership-level',
    getId: '/api/user/id',
}

export const deleteData = (): Promise<void> => {
    return fetch(paths.deleteData, {
        method: 'POST'
    }).then(HandleErrorResponse)
        .then();
}

export const getMembershipLevel = (): Promise<string> => {
    return fetch(paths.getMembershipLevel)
        .then(HandleErrorResponse)
        .then(res => res.json())
        .then((res: MembershipLevelResponse) => res.membershipLevel);
}

export const getId = (): Promise<string> => {
    return fetch(paths.getId)
        .then(HandleErrorResponse)
        .then(res => res.json())
        .then((res: UserIdResponse) => res.userId);
}
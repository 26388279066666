import React, {useEffect, useState} from "react";
import {SmallerConfirmationButton, SmallestConfirmationButton} from "../common/Buttons";
import {changeMembershipLevelBySpotifyId, changeMembershipLevelByUserId, getPremiumMembers} from "../../api/admin/UserApi";
import {ErrorMessage} from "../common/ErrorMessage";
import {formatDate, parseDate} from "../../util/Dates";
import {Link} from "react-router-dom";
import {User} from "../../api/admin/UserDetailsResponse";
import './MembershipLevel.css';

interface MembershipLevelProps {
    membershipLevelChanged: () => void;
}

const MembershipLevel = (props: MembershipLevelProps) => {
    const [userId, setUserId] = useState<string>("");
    const [spotifyId, setSpotifyId] = useState<string>("");
    const [premiumMembers, setPremiumMembers] = useState<User[]>();
    const [offset, setOffset] = useState<number>(0);

    useEffect(() => {
        getPremiumMembers(offset)
            .then((premiumMembers: User[]) => {
                setPremiumMembers(premiumMembers);
            });
    }, [offset]);

    if (premiumMembers === undefined) {
        return <React.Fragment/>
    }


    let premiumMemberRows = premiumMembers.map((premiumMember: User) =>
        <PremiumMemberRow
            key={premiumMember.userId + premiumMember.effectiveDate} userId={premiumMember.userId} effectiveDate={parseDate(premiumMember.effectiveDate)}/>);


    return (
        <div>
            <h3>Current premium members</h3>
            <table>
                <thead>
                <tr>
                    <th>User id</th>
                    <th>Effective date</th>
                </tr>
                </thead>
                <tbody>
                {premiumMemberRows}
                </tbody>
            </table>
            <div className={"controlButtonsUserTable"}>
                <SmallestConfirmationButton onClick={handlePreviousClick} text={"Previous"}/>
                <SmallestConfirmationButton onClick={handleNextClick} text={"Next"}/>
            </div>
            <h3>Find member</h3>
            <h4>By spotify id</h4>
            <input className="userIdStatisticsInput" placeholder="Spotify Id" onChange={(evt) => setSpotifyId(evt.currentTarget.value)} type="text" value={spotifyId}/>
            <Link to={`/admin/users?spotifyId=${spotifyId}`}><SmallestConfirmationButton text={"Go"}/></Link>
            <h4>By user id</h4>
            <input className="userIdStatisticsInput" placeholder="User Id (Guid)" onChange={(evt) => setUserId(evt.currentTarget.value)} type="text" value={userId}/>
            <Link to={`/admin/users?userId=${userId}`}><SmallestConfirmationButton text={"Go"}/></Link>

        </div>
    )


    function handleNextClick() {
        if (premiumMemberRows.length < 20) {
            return;
        }

        let newOffset = offset + 20;
        setOffset(newOffset);
    }

    function handlePreviousClick() {
        let newOffset = offset < 20 ? 0 : offset - 20;
        setOffset(newOffset);
    }
}

interface PremiumMemberProps {
    userId: string;
    effectiveDate: Date;
}

const PremiumMemberRow = (props: PremiumMemberProps) => {
    return (
        <tr>
            <td><Link to={`/admin/users?userId=${props.userId}`}>{props.userId}</Link></td>
            <td>{formatDate(props.effectiveDate)}</td>
        </tr>
    )
}

export default MembershipLevel;
export const updateReferrer = () => {
    let referrer = document.referrer;

    if (referrer.indexOf("likedsongsmanager.com") !== -1)
        return;

    if (referrer === undefined || referrer === null)
        return;

    sessionStorage.setItem("referrer", referrer);
}

export const getReferrer = (): string | null => {
    return sessionStorage.getItem("referrer");
}
import {AvailableIcon, NotAvailableIcon} from "../../common/Icons";
import React from "react";


interface PlaylistTableProps {
    children: React.ReactNode;
}

export const PlaylistTable = (props: PlaylistTableProps) => {
    return (
        <table>
            <thead>
            <tr>
                <th></th>
                <th>Free</th>
                <th className={"premiumHeader"}>Premium</th>
            </tr>
            </thead>
            <tbody>
            {props.children}
            </tbody>
        </table>
    );
}

interface PlaylistRowProps {
    name: string;
    availableFree: boolean;
}

export const PlaylistRow = (props: PlaylistRowProps) => {
    return (
        <tr>
            <td className={"playlistColumn"}>{props.name}</td>
            <td>{props.availableFree ? <AvailableIcon/> : <NotAvailableIcon/>}</td>
            <td><AvailableIcon/></td>
        </tr>
    )
}

export const GetAccessWithPremiumButton = () => {
    return (
        <div className="getAccessWithPremiumButtonContainer">
            <div className="accessWithPremiumButtonLink">
                <a href={'/plans'}>
                    <div className="unselectable accessWithPremiumButton">Get access with Premium</div>
                </a>
            </div>
        </div>
    )
}
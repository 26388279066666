import './Header.css';
import {ReactComponent as BurgerMenu} from './menu.svg';
import {ReactComponent as CloseMenu} from './x.svg';
import {ReactComponent as PlaylistIcon} from './headphones.svg';
import {ReactComponent as FaqIcon} from './help-circle.svg';
import {ReactComponent as FeedbackIcon} from './feedback.svg';
import {ReactComponent as LogoutIcon} from './log-out.svg';
import {ReactComponent as StarIcon} from './star.svg';
import {ReactComponent as Logo} from './logo.svg';
import {ReactComponent as Settings} from './settings.svg';
import React, {useEffect, useRef, useState} from 'react';
import {logout} from '../api/authorization/AuthorizationApi';
import {useAuthorizationContext} from '../context/authorizationContext';
import {Link} from "react-router-dom";
import {SmallestConfirmationButton} from "../pages/common/Buttons";

interface HeaderProps {
    navigate: (location: string) => void,
    location: string
}

const Header = (props: HeaderProps) => {
    const {loggedIn} = useAuthorizationContext();

    function doLogout() {
        logout()
            .then(() => window.location.replace(`/`));
    }

    if (loggedIn === undefined)
        return <LoadingHeader/>

    return (
        <div id="header">
            <HeaderDesktop loggedIn={loggedIn} navigate={props.navigate} location={props.location} logout={doLogout}/>
            <HeaderMobile loggedIn={loggedIn} navigate={props.navigate} location={props.location} logout={doLogout}/>
        </div>
    )
}

const LoadingHeader = () => {
    return (
        <div id="header">
            <div className="loadingHeader">

            </div>
        </div>
    )
}

interface HeaderMenuProps {
    navigate: (location: string) => void,
    location: string
    logout: () => void
    loggedIn: boolean
}

const HeaderDesktop = (props: HeaderMenuProps) => {
    return (
        <div id="headerDesktop">
            <Link className="headerTitleDesktopContainer" to={""}>
                <Logo/>
            </Link>
            <HeaderLink icon={<PlaylistIcon className={"headerIcon"} height={24} width={24}/>} location={props.location} defaultPage={true}
                        text="Playlists" linkPath="playlists"/>
            <HeaderLink icon={<StarIcon className={"headerIcon"} height={24} width={24}/>} location={props.location} text="Plans"
                        linkPath="plans"/>
            <HeaderLink icon={<FeedbackIcon className={"headerIcon"} height={24} width={24}/>} location={props.location} text="Feedback"
                        linkPath="feedback"/>
            <HeaderLink icon={<FaqIcon className={"headerIcon"} height={24} width={24}/>} location={props.location} text="FAQ"
                        linkPath="faq"/>
            {props.loggedIn
                ? <div className="headerEndSection">
                    <div className={"headerEndSectionButtonBackground"}>
                        <LogoutIcon onClick={props.logout} className={"headerEndSectionButton"} height={24} width={24}/>
                    </div>
                    <Link to={'/settings'} className={"headerEndSectionButtonBackground"}>
                        <div className={"headerEndSectionButton"}>
                            <Settings/>
                        </div>
                    </Link>
                </div>
                : <a href={'/login'} className="headerEndSection">
                    <SmallestConfirmationButton text={"Get started"}/>
                </a>
            }
        </div>
    );
}

const HeaderMobile = (props: HeaderMenuProps) => {
    const [burgerMenuShown, toggleBurgerMenu] = useState<boolean>(false);
    const ref: any = useRef(null);
    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                toggleBurgerMenu(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    function toggleMenu() {
        let toggledValue = !burgerMenuShown;
        toggleBurgerMenu(toggledValue);
    }

    return (
        <div ref={ref} id="headerMobile">
            <BurgerMenu onClick={toggleMenu} className={"burgerMenu"} height={40} width={40}/>
            {burgerMenuShown && <HeaderMobileSideMenu loggedIn={props.loggedIn} headerProps={props} toggleBurgerMenu={toggleMenu} logout={props.logout}/>}
            <div className="headerTitleMobileContainer" onClick={() => props.navigate("")}>
                <Logo/>
            </div>
        </div>
    )
}

interface HeaderMobileSideMenuProps {
    headerProps: HeaderProps,
    toggleBurgerMenu: () => void
    logout: () => void
    loggedIn: boolean
}

const HeaderMobileSideMenu = (props: HeaderMobileSideMenuProps) => {
    function logout() {
        props.toggleBurgerMenu();
        props.logout();
    }

    return (
        <div id="headerMobileSideMenu">
            <div className="closeBurgerMenuContainer">
                <div className="closeBurgerMenu" onClick={props.toggleBurgerMenu}>
                    <CloseMenu height={24} width={24}/>
                </div>
            </div>
            <HeaderLinkMobile icon={<PlaylistIcon className={"headerIconMobile"} height={24} width={24}/>}
                              location={props.headerProps.location}
                              defaultPage={true} text="Playlists"
                              linkPath="playlists"
                              toggleBurgerMenu={props.toggleBurgerMenu}/>
            <HeaderLinkMobile icon={<StarIcon className={"headerIconMobile"} height={24} width={24}/>}
                              location={props.headerProps.location}
                              text="Plans"
                              linkPath="plans"
                              toggleBurgerMenu={props.toggleBurgerMenu}/>
            <HeaderLinkMobile icon={<FeedbackIcon className={"headerIconMobile"} height={24} width={24}/>}
                              location={props.headerProps.location}
                              text="Feedback"
                              linkPath="feedback"
                              toggleBurgerMenu={props.toggleBurgerMenu}/>
            <HeaderLinkMobile icon={<FaqIcon className={"headerIconMobile"} height={24} width={24}/>}
                              location={props.headerProps.location}
                              text="FAQ"
                              linkPath="faq"
                              toggleBurgerMenu={props.toggleBurgerMenu}/>
            <>
                {props.loggedIn ?
                    <div className="headerMobileBottomSection">
                        <div onClick={() => logout()} className="headerLinkMobile">
                            <div className="headerLinkContentMobile">
                                <LogoutIcon className={"headerIconMobile"} height={24} width={24}/>
                                <div>Logout</div>
                            </div>
                        </div>
                        <Link to={'/settings'} onClick={() => props.toggleBurgerMenu()} className="headerLinkMobile">
                            <div className="headerLinkContentMobile">
                                <Settings className={"headerIconMobile"} height={24} width={24}/>
                                <div>Settings</div>
                            </div>
                        </Link>
                    </div>
                    : <div className="headerMobileLogin">
                        <a href={'/login'} className="headerLogin">
                            <SmallestConfirmationButton text={"Get started"}/>
                        </a>
                    </div>}
            </>
        </div>
    )
}

interface HeaderLinkProps {
    defaultPage?: boolean;
    text: string,
    linkPath: string,
    location: string,
    icon: React.ReactElement
}

const HeaderLink = (props: HeaderLinkProps) => {
    const beginningLocationPath = props.location.slice(1, props.location.length).split('/')[0].split('?')[0];
    const isSelectedHeaderLink = beginningLocationPath === props.linkPath;

    let classes = isSelectedHeaderLink || props.defaultPage && beginningLocationPath === "" ? "headerLink selected" : "headerLink";

    return (
        <Link to={props.linkPath} className={classes}>
            <div className="headerLinkContent">
                {props.icon}
                <div>{props.text}</div>
            </div>
        </Link>
    )
};

interface HeaderMobileLinkProps extends HeaderLinkProps {
    toggleBurgerMenu: () => void
}


const HeaderLinkMobile = (props: HeaderMobileLinkProps) => {
    const beginningLocationPath = props.location.slice(1, props.location.length).split('/')[0].split('?')[0];
    const isSelectedHeaderLink = beginningLocationPath === props.linkPath;

    let classes = isSelectedHeaderLink || props.defaultPage && beginningLocationPath === "" ? "headerLinkMobile selected" : "headerLinkMobile";

    return (
        <Link to={props.linkPath} className={classes} onClick={() => props.toggleBurgerMenu()}>
            <div className="headerLinkContentMobile">
                {props.icon}
                <div>{props.text}</div>
            </div>
        </Link>
    )
}

export default Header;
import React, {useEffect, useState} from "react";
import {CombinatorialPreference} from "./combinatorial/CombinatorialPreference";
import CombinatorialPreferenceManagement from "./combinatorial/CombinatorialPreferenceManagement";
import {SimplePreference} from "./simple/SimplePreference";
import SimplePreferenceManagement from "./simple/SimplePreferenceManagement";
import './PlaylistManagement.css';
import {Link} from "react-router-dom";
import {PreferenceGroup} from "../../../api/playlistpreferences/PlaylistPreferences";
import {distributionProgress} from "../../../api/distributor/DistributeApi";
import {DistributionProgress} from "../../../api/distributor/DistributionProgress";
import PlaylistsLoading from "../PlaylistsLoading";
import Progress from "../progress/Progress";
import Footer from "../../../footer/Footer";

interface PlaylistManagementProps {
    userIsNoneFree: boolean;
    currentSimplePreferences: SimplePreference[];
    preferenceGroups: PreferenceGroup[];
    currentCombinatorialPreferences: CombinatorialPreference[];
    userHasUnlimitedPlaylists: boolean;
    userHasAccessToCombinatorialPlaylists: boolean;
    navigate: (location: string) => void;
    location: string;
}

const PlaylistManagement = (props: PlaylistManagementProps) => {
    const paths = props.location.slice(1, props.location.length).split('/');
    const isCombinationsTabSelected = paths.includes("combinations");
    const [distributionProgressId, setDistributionProgressId] = useState<string>();
    const [distributionInProgress, setDistributionInProgress] = useState<boolean | undefined>(undefined);
    const [simplePreferences, setSimplePreferences] = useState<SimplePreference[]>(props.currentSimplePreferences)

    useEffect(() => {
        let latestDistributionProgressId = sessionStorage.getItem("latestDistributionProgressId");
        if (latestDistributionProgressId === null) {
            setDistributionInProgress(false);
            return;
        }

        distributionProgress(latestDistributionProgressId)
            .then((distributionProgress: DistributionProgress) => {
                if (distributionProgress.finished) {
                    setDistributionInProgress(false);
                    return;

                }

                if (latestDistributionProgressId === null) {
                    setDistributionInProgress(false);
                    return;
                }
                setDistributionProgressId(latestDistributionProgressId);
                setDistributionInProgress(true);
            });
    }, []);

    if (distributionInProgress === undefined)
        return <PlaylistsLoading/>

    let availableDecadePlaylists = props.currentSimplePreferences.filter(playlistPreference => playlistPreference.group === 'Decade');
    let availableGenrePlaylists = props.currentSimplePreferences.filter(playlistPreference => playlistPreference.group === 'Genre');
    let availableMoodPlaylists = props.currentSimplePreferences.filter(playlistPreference => playlistPreference.group === 'Mood');
    let availableNationalityPlaylists = props.currentSimplePreferences.filter(playlistPreference => playlistPreference.group === 'Nationality');

    let managementModule = isCombinationsTabSelected
        ? <CombinatorialPreferenceManagement
            availableDecadePlaylists={availableDecadePlaylists}
            availableGenrePlaylists={availableGenrePlaylists}
            availableMoodPlaylists={availableMoodPlaylists}
            availableNationalityPlaylists={availableNationalityPlaylists}
            currentCombinatorialPreferences={props.currentCombinatorialPreferences}
            userHasAccessToCombinatorialPlaylists={props.userHasAccessToCombinatorialPlaylists}/>
        : <SimplePreferenceManagement
            userHasUnlimitedPlaylists={props.userHasUnlimitedPlaylists}
            userIsNoneFree={props.userIsNoneFree}
            simplePreferences={simplePreferences}
            preferenceGroups={props.preferenceGroups}
            preferenceDeleted={preferenceDeleted}/>

    let preferenceContainerClasses = distributionInProgress ? "preferenceContainer distributionInProgress" : "preferenceContainer";
    return (
        <React.Fragment>
            <div id="pageContainer">
                <div id="pageContent">
                    <div className={preferenceContainerClasses}>
                        <div className="playlistTabContainer">
                            <PlaylistTab text={"Predefined"} linkPath={""} activeTab={!isCombinationsTabSelected}/>
                            <PlaylistTab text={"Combinations"} linkPath={"combinations"} activeTab={isCombinationsTabSelected}/>
                            <div className="tabRemainder"/>
                        </div>
                        {distributionProgressId !== undefined &&
                            <PlaylistProgress distributionCompletedCallback={distributionCompleted} distributionProgressId={distributionProgressId}/>
                        }
                        <div className="managementModuleContainer">
                            {managementModule}
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </React.Fragment>
    )

    function distributionCompleted() {
        setDistributionInProgress(false);
    }

    function preferenceDeleted(deletedPreference: SimplePreference) {
        setSimplePreferences((prevState) => prevState.map(preference => {
            if (deletedPreference.name === preference.name) {
                return {
                    ... preference,
                    userHasPreference: false
                }
            }
            
            return preference;
        }));
    }
}

interface PlaylistTabProps {
    text: string;
    linkPath: string;
    activeTab: boolean;
}

export const PlaylistTab = (props: PlaylistTabProps) => {
    let classes = props.activeTab ? "playlistTab activeTab" : "playlistTab noneActiveTab";
    return (
        <Link reloadDocument={props.activeTab} to={`/playlists/${props.linkPath}`} className={classes}>{props.text}</Link>
    )
}

interface PlaylistProgressProps {
    distributionProgressId: string;
    distributionCompletedCallback: Function;
}

const PlaylistProgress = (props: PlaylistProgressProps) => {
    return (
        <div className="pageContentSegment">
            <h2>Songs are being added to Spotify</h2>
            <p>Please wait until this has completed before changing playlists.</p>
            <Progress distributionCompletedCallback={props.distributionCompletedCallback} progressId={props.distributionProgressId}/>
        </div>
    )
}

export default PlaylistManagement;
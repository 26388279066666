import React from "react";
import { Link } from "react-router-dom";

const CreateFeedbackSuccess = () => {
    return (
        <React.Fragment>
            <div className="pageContentSegment">
                <h2>Thank you for your feedback</h2>
                <p>Your feedback is much appreciated.</p>
                <p>If you want to get in touch with us, then <a href="mailto:support@likedsongsmanager.com">send us an e-mail</a>.</p>
            </div>
            <div className="pageContentSegment">
                <h2>Anything else to add?</h2>
                <p>If there is anything else you would like to add, do not hesitate to provide <Link to={'/feedback'}>more feedback.</Link>.</p>
            </div>
        </React.Fragment>
    )
}

export default CreateFeedbackSuccess;
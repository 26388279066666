import './CreateCombinatorialPlaylist.css';
import React, {useEffect, useState} from "react";
import {Playlist} from "./Playlist";
import {ConfirmationButton} from '../../../common/Buttons';
import {ErrorMessage} from "../../../common/ErrorMessage";
import {ErrorCode} from "../../../../api/ApiError";
import {Link} from "react-router-dom";
import {Wizard} from "./wizard/Wizard";

interface CreateCombinatorialPlaylistProps {
    availableDecadePlaylists: Playlist[];
    availableGenrePlaylists: Playlist[];
    availableNationalityPlaylists: Playlist[];
    availableMoodPlaylists: Playlist[];
    numberOfCombinatorialPlaylists: number;
    updateWizardInProgress: (inProgress: boolean) => void;
    wizardInProgress: boolean;
    userHasAccessToCombinatorialPlaylists: boolean;
}

const MaximumNumberOfCombinatorialPlaylists: number = 10;

const CreateCombinatorialPlaylist = (props: CreateCombinatorialPlaylistProps) => {
    let preferenceCreationElement = props.numberOfCombinatorialPlaylists >= MaximumNumberOfCombinatorialPlaylists
        ? <NoMoreRoomForPreferences/>
        : props.wizardInProgress
            ? <Wizard
                availableMoodPlaylists={props.availableMoodPlaylists}
                availableNationalityPlaylists={props.availableNationalityPlaylists}
                availableGenrePlaylists={props.availableGenrePlaylists}
                availableDecadePlaylists={props.availableDecadePlaylists}
                goToOverview={() => props.updateWizardInProgress(false)}/>
            : <NewCombinationStartingPoint userHasAccessToCombinatorialPlaylists={props.userHasAccessToCombinatorialPlaylists}
                                           startWizard={() => props.updateWizardInProgress(true)}/>;
    return (
        <div className="pageContentSegment">
            <h2>Combination playlists</h2>
            {preferenceCreationElement}
        </div>
    )
}

interface NewCombinationStartingPointProps {
    startWizard: Function;
    userHasAccessToCombinatorialPlaylists: boolean;
}

const NewCombinationStartingPoint = (props: NewCombinationStartingPointProps) => {
    const [errorCode, setErrorCode] = useState<ErrorCode>();

    function start() {
        setErrorCode(undefined);
        if (!props.userHasAccessToCombinatorialPlaylists) {
            setErrorCode(ErrorCode.FeatureNotAvailableForCurrentMembershipLevel);
            return;
        }
        props.startWizard();
    }

    return (
        <div className="newCombinationStartingPoint">
            {!props.userHasAccessToCombinatorialPlaylists &&
                <Link to={'/plans'}><p className="premiumText">
                    Become premium ⭐ to get access to combination playlists and more.
                </p>
                </Link>
            }
            <p>In a combination playlist you can combine genres, decades, moods, and nationalities to make a playlist that fits your exact needs. This could be:</p>
            <ul>
                <li>a playlist of all of your rock and pop songs.</li>
                <li>a playlist of all of yours reggae songs from the 1980s and all of your jazz songs.</li>
                <li>a playlist of all your Brazilian party songs.</li>
            </ul>
            <p>You can have up to 20 combination playlists.</p>
            <ConfirmationButton
                onClick={() => start()}
                text={"Let's go!"}
                marginTop={true}
                marginBottom={true}/>
            <ErrorMessage errorCode={errorCode}/>
        </div>
    )
}

const NoMoreRoomForPreferences = () => {
    return (
        <div>
            You have no more room for additional combination playlists. Delete one to be able to create a new one.
        </div>
    )
}


export default CreateCombinatorialPlaylist;

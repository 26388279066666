import {ApiError} from "../ApiError";
import {HandleErrorResponse} from "../HandleErrorResponse";
import {StartBatchJobResponse} from "./StartBatchJobResponse";

const paths = {
    startJob: '/api/admin/batch/start',
}

export const startJob = (): Promise<boolean> => {
    return fetch(paths.startJob, {
        method: 'POST',
    }).then(HandleErrorResponse)
        .then(res => res.json())
        .then((response: StartBatchJobResponse) => response.jobStarted);
}
import {ApiError, ErrorCode} from "./ApiError";

export async function HandleErrorResponse(response: Response): Promise<Response> {
    if (!response.ok) {
        if (response.status === 500) {
            throw {
                error: "Unknown error",
                errorCode: ErrorCode.UnknownError
            }
        }
        await response.json()
            .then((error: ApiError) => {
                throw error;
            })
    }
    return response;
}
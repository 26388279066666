import React from "react";
import useDocumentTitle, { useMetaDescription } from "../util/ChangeTitleHook";
import Footer from "../footer/Footer";
import {useUserContext} from "../context/userContext";
import {useAuthorizationContext} from "../context/authorizationContext";

interface SimplePageProps {
    content: React.ReactElement
    title: string;
    metaDescription: string;
}

const SimplePage = (props: SimplePageProps) => {
    const {loggedIn} = useAuthorizationContext();
    const {membershipLevel} = useUserContext();
    useDocumentTitle(props.title)
    useMetaDescription(props.metaDescription);
    
    let userLoggedInLoading = loggedIn !== undefined && membershipLevel !== undefined;

    if (!userLoggedInLoading)
        return <div id="pageContainer"/>

    return (
        <React.Fragment>
            <div id="pageContainer">
                <div id="pageContent">
                    {userLoggedInLoading && props.content}
                </div>
            </div>
            {userLoggedInLoading && <Footer/>}
        </React.Fragment>
    )
}

const SimplePageBuilder = (defaultMetaDescription: string) => {
    return function (title: string, content: React.ReactElement, metaDescription?: string) {
        return <SimplePage metaDescription={metaDescription ?? defaultMetaDescription} content={content} title={title} />
    }
}

export default SimplePageBuilder;
import {HandleErrorResponse} from "../HandleErrorResponse";
import {User, UserDetailsResponse} from "./UserDetailsResponse";

const paths = {
    statistics: '/api/admin/statistics',
    changeMembershipLevelUserId: (userId: string) => `/api/admin/user/user-id/${userId}/membership`,
    changeMembershipLevelSpotifyId: (userId: string) => `/api/admin/user/spotify-id/${userId}/membership`,
    detailsByUserId: (userId: string) => `/api/admin/user/user-id/${userId}`,
    detailsBySpotifyId: (spotifyId: string) => `/api/admin/user/spotify-id/${spotifyId}`,
    premiumMembers: (offset: number) => `/api/admin/user/premium?offset=${offset}`,
}

export const changeMembershipLevelByUserId = (userId: string, membershipLevel: string): Promise<void> => {
    let request = {
        membershipLevel: membershipLevel
    };
    return fetch(paths.changeMembershipLevelUserId(userId), {
        method: 'POST',
        body: JSON.stringify(request),
        headers: {
            'Content-type': 'application/json; charset=UTF-8'
        },
    }).then(HandleErrorResponse)
        .then();
}

export const changeMembershipLevelBySpotifyId = (spotifyId: string, membershipLevel: string): Promise<void> => {
    let request = {
        membershipLevel: membershipLevel
    };

    return fetch(paths.changeMembershipLevelSpotifyId(spotifyId), {
        method: 'POST',
        body: JSON.stringify(request),
        headers: {
            'Content-type': 'application/json; charset=UTF-8'
        },
    }).then(HandleErrorResponse)
        .then();
}

export const getUserDetailsByUserId = (userId: string): Promise<UserDetailsResponse> => {
    return fetch(paths.detailsByUserId(userId))
        .then(HandleErrorResponse)
        .then(res => res.json());
}

export const getUserDetailsBySpotifyId = (spotifyId: string): Promise<UserDetailsResponse> => {
    return fetch(paths.detailsBySpotifyId(spotifyId))
        .then(HandleErrorResponse)
        .then(res => res.json());
}

export const getPremiumMembers = (offset: number): Promise<User[]> => {
    return fetch(paths.premiumMembers(offset))
        .then(HandleErrorResponse)
        .then(res => res.json());
}
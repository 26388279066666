import React from "react";

export const Refund = () => {
    return (
        <div className="pageContentSegment">
            <h1>A fair refund policy</h1>
            <i>Last modified: 21st October 2023.</i>
            <p>
                Bad refund policies are infuriating. You feel like the company is just trying to rip you off. We never want our customers to feel that way, so our refund policy is
                simple: If you're ever unhappy with our products* for any reason, just contact <a href="mailto:support@likedsongsmanager.com">our support team</a> and we'll take care
                of you.
            </p>
            <h2>Examples of full refunds we'd grant.</h2>
            <ul>
                <li>If you were just charged for your next year of service but you meant to cancel, we're happy to refund that extra charge.</li>
                <li>If you forgot to cancel your Premium subscription a couple months ago and you haven't used it since then, we'll give you a full refund. No problem.</li>
            </ul>
            <h2>Examples of partial refunds or credits we'd grant.</h2>
            <ul>
                <li>If we had extended downtime (multiple hours in a day, or multiple days in a month) or you emailed customer service and it took multiple days to get back to you,
                    we'd
                    issue a partial credit to your account.
                </li>
            </ul>
            <h2>Get in touch</h2>
            <p>At the end of the day, nearly everything on the edges comes down to a case-by-case basis. <a href="mailto:support@likedsongsmanager.com">Send us a note</a>, tell us
                what's up, and we'll work with you to make sure you're happy.</p>
            <p><i>*This policy applies to any product created and owned by Holdsoft Solutions. That includes Liked Songs Manager Premium subscription.</i></p>
            <p>Adapted from the <a href="https://github.com/basecamp/policies">Basecamp open-source policies</a> / <a href="https://creativecommons.org/licenses/by/4.0/">CC BY
                4.0</a></p>
        </div>
    )
}
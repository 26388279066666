import React, {useEffect, useState} from "react";
import './Settings.css';
import {deleteData} from "../../api/user/UserApi";
import {ApiError, ErrorCode} from "../../api/ApiError";
import {WarningButton} from "../common/Buttons";
import {MembershipLevel, useUserContext} from "../../context/userContext";
import NotFoundSegment from "../notfound/NotFoundSegment";
import {useAuthorizationContext} from "../../context/authorizationContext";
import {getPlaylistSettings, updatePlaylistSettings} from "../../api/playlistpreferences/PlaylistPreferencesApi";
import {Setting} from "../../api/playlistpreferences/PlaylistPreferences";
import PageSegmentLoading from "../common/PageSegmentLoading";
import {Toggle} from "../common/Toggle";

export const Settings = () => {
    const {loggedIn} = useAuthorizationContext();
    const {membershipLevel} = useUserContext();
    const [error, setError] = useState<string>();
    const [privatePlaylists, setPrivatePlaylists] = useState<boolean>(false);
    const [removePlaylistPostfix, setRemovePlaylistPostfix] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    
    useEffect(() => {
        getPlaylistSettings()
            .then((settings: Setting[]) => {
                for (let setting of settings) {
                    if (setting.name === 'CreatePlaylistsAsPrivate') {
                        setPrivatePlaylists(setting.value);
                    } else if (setting.name === 'RemovePlaylistPostfix') {
                        setRemovePlaylistPostfix(setting.value);
                    }
                }
            }).then(() => setLoading(false));
    }, []);

    let isPremium = membershipLevel == MembershipLevel.PremiumYearly;

    if (loggedIn !== true) {
        return <NotFoundSegment/>
    }

    if (loading) {
        return <PageSegmentLoading/>
    }

    return (
        <div>
            <div className="pageContentSegment">
                <h1>Settings</h1>
                <table>
                    <tbody>
                    <tr>
                        <td className={"settingConfiguration"}>Hide playlists on Spotify profile.</td>
                        <td>
                            <Toggle value={privatePlaylists} onClick={() => togglePrivatePlaylists()}/>
                        </td>
                    </tr>
                    <tr>
                        <td className={"settingConfiguration"}>Remove 'Managed Playlist' postfix from playlist names.</td>
                        <td>
                            <Toggle value={removePlaylistPostfix} onClick={() => toggleRemovePlaylistPostfix()}/>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div id="privacyAndDataContainer" className="pageContentSegment">
                <h2>Delete my data from the application</h2>
                <p>Should you wish your data deleted from Liked Songs Manager, you can click the button below. Clicking the button will also log you out of the application. Your
                    playlists created by the application will still remain in Spotify, but they will no longer be kept up to date.</p>
                <p>To revoke the access to Spotify that you have granted Liked Songs Manager <a href="https://www.spotify.com/uk/account/apps/">click here</a>.</p>
                {isPremium &&
                    <div>
                        <h3 className="premiumText">Premium membership disclaimer</h3>
                        <p>We will keep the information of your subscription and premium membership up to 14 months after your deletion counting from the last payment date. This is
                            to honor your contract and ensure that you will keep your premium subscription if you wish to log in again.</p>
                        <p>Note that even if you delete your data, if you have an active subscription, you will still be billed. To cancel your Premium subscription go to <a
                            href="/plans">Plans</a>.</p>
                    </div>
                }
                <WarningButton
                    text={"Delete my data"}
                    onClick={deleteUserData}
                    marginTop={true}/>
                <div className="errorMessage">
                    {error}
                </div>
            </div>
        </div>
    )

    function togglePrivatePlaylists(): Promise<void> {
        setPrivatePlaylists(prevState => !prevState);
        return updatePlaylistSettings(!privatePlaylists, removePlaylistPostfix);
    }
    
    function toggleRemovePlaylistPostfix(): Promise<void> {
        setRemovePlaylistPostfix(prevState => !prevState);
        return updatePlaylistSettings(privatePlaylists, !removePlaylistPostfix);
    }

    function deleteUserData() {
        let confirmed = window.confirm("Are you certain you want to delete all of your data?");
        if (!confirmed)
            return;

        sessionStorage.removeItem("latestDistributionProgressId")

        deleteData()
            .then(() => window.location.replace('/settings/data-deleted'))
            .catch((error: ApiError) => {
                if (error.errorCode == ErrorCode.Unauthorized)
                    setError("You are not logged in.")
                else
                    setError("An unknown error occured. Try again later.")
            })
    }

}
import {HandleErrorResponse} from "../HandleErrorResponse";

const paths = {
    createFeedback: `/api/feedback`,
}

export const createFeedback = (feedback: string): Promise<void> => {
    let request = {
        feedback: feedback
    };

    return fetch(paths.createFeedback, {
        method: 'POST',
        body: JSON.stringify(request),
        headers: {
            'Content-type': 'application/json; charset=UTF-8'
        },
    }).then(HandleErrorResponse).then();
}
import React from "react";
import './Success.css';
import {useSearchParams} from "react-router-dom";
import Progress from "../../../progress/Progress";
import {MembershipLevel, useUserContext} from "../../../../../context/userContext";

const DistributionSuccess = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const {membershipLevel} = useUserContext();

    let distributionProgressId = searchParams.get("distributionProgressId");
    if (distributionProgressId !== null)
        sessionStorage.setItem("latestDistributionProgressId", distributionProgressId);

    let createdPlaylistsString = sessionStorage.getItem(`${distributionProgressId}-created`);
    let numberOfLikedSongs = Number(sessionStorage.getItem(`${distributionProgressId}-numberOfLikedSongs`));

    let createdPlaylists: string[] = createdPlaylistsString != undefined ? JSON.parse(createdPlaylistsString) : [];

    createdPlaylists.sort((a, b) => {
        return a.localeCompare(b, undefined, {numeric: true});
    });

    return (
        <div>
            <div className="pageContentSegment">
                <h2>Playlists updated</h2>
                {membershipLevel === MembershipLevel.Free && numberOfLikedSongs > 1000 &&
                    <div>
                        <h4>Do you want entire library of {numberOfLikedSongs} liked songs to be considered for the playlists?</h4>
                        <p className={"becomePremium"}>Then become a <a href="/plans">premium member ⭐</a>.</p>
                    </div>
                }
                <Progress progressId={distributionProgressId}/>
            </div>
            <div className="pageContentSegment">
                <h2>Playlists created</h2>
                <ul>
                    {createdPlaylists.map((createdPlaylist) => <li key={`created-playlist-${createdPlaylist}`}>{createdPlaylist}</li>)}
                </ul>
            </div>
            <div className="pageContentSegment">

                <h2>Need to make changes?</h2>
                <p>Go to back to <a href="/playlists">playlists</a> to make further changes.</p>
            </div>
            <div className="pageContentSegment">
                <h2>Playlists not appearing in Spotify?</h2>
                <p>In the newer versions of the Spotify app, it is sometimes necessary to restart the app before the playlists appear.</p>
                <p>Hopefully, this will be fixed in a future update.</p>
            </div>
        </div>
    )
}

export default DistributionSuccess;
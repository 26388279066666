import {HandleErrorResponse} from "../HandleErrorResponse";
import {PremiumPricing} from "./PremiumPricing";
import {Subscription} from "./Subscription";
import {UpdatePaymentMethodTransaction} from "./UpdatePaymentMethodTransaction";

const paths = {
    playlistPreferences: '/api/payment/pricing',
    cancelSubscription: '/api/payment/subscription/cancel',
    reactivateSubscription: '/api/payment/subscription/reactivate',
    getSubscription: '/api/payment/subscription',
    getUpdatePaymentMethodTransaction: '/api/payment/subscription/update-payment-method-transaction'
}

export const getPremiumPricing = (): Promise<PremiumPricing> => {
    return fetch(paths.playlistPreferences, {
        method: 'POST',
    }).then(HandleErrorResponse)
        .then(res => res.json());
}

export const cancelSubscription = (): Promise<void> => {
    return fetch(paths.cancelSubscription, {
        method: 'POST',
    }).then(HandleErrorResponse)
        .then();
}

export const reactivateSubscription = (): Promise<void> => {
    return fetch(paths.reactivateSubscription, {
        method: 'POST',
    }).then(HandleErrorResponse)
        .then();
}

export const getSubscription = (): Promise<Subscription> => {
    return fetch(paths.getSubscription)
        .then(HandleErrorResponse)
        .then(res => res.json());
}

export const getUpdatePaymentMethodTransaction = (): Promise<UpdatePaymentMethodTransaction> => {
    return fetch(paths.getUpdatePaymentMethodTransaction)
        .then(HandleErrorResponse)
        .then(res => res.json());
}
import React, {useEffect, useState} from "react";
import {Statistics} from "../../api/admin/Statistics";
import {getStatistics} from "../../api/admin/StatisticsApi";
import NotFoundSegment from "../notfound/NotFoundSegment";
import UserData from "./UserData";
import Batch from "./Batch";
import UserStatisticsManagement from "./UserStatisticsManagement";
import MembershipLevel from "./MembershipLevel";


const Admin = () => {
    const [refreshCounter, setRefreshCounter] = useState<number>();
    const [statistics, setStatistics] = useState<Statistics>();

    useEffect(() => {
        getStatistics()
            .then((statistics: Statistics) => {
                setStatistics(statistics);
            });
    }, [refreshCounter]);

    if (statistics === undefined)
        return (
            <NotFoundSegment/>
        )

    return (
        <div>
            <div className="pageContentSegment">
                <h2>Statistics</h2>
                <UserData userData={statistics.userData}/>
            </div>
            <div className="pageContentSegment">
                <h2>Memberships</h2>
                <MembershipLevel membershipLevelChanged={refreshStatistics} />
            </div>
            <div className="pageContentSegment">
                <h2>Batch</h2>
                <Batch batchData={statistics.batchData}
                       jobStarted={refreshStatistics}/>
            </div>
            <div className="pageContentSegment">
                <h2>User statistics</h2>
                <UserStatisticsManagement
                    enabledUserIds={statistics.enabledStatisticsUserIds}
                    userStateChanged={refreshStatistics}/>
            </div>

        </div>
    )

    function refreshStatistics() {
        setRefreshCounter((prevNumber: number | undefined) => {
            if (prevNumber === undefined)
                return 1;

            return prevNumber + 1;
        });
    }
}

export default Admin;
export interface ApiError {
    error: string,
    errorCode: number
}

export enum ErrorCode {
    UnknownError = -1,
    Unauthorized = 6,
    PlaylistTypeDoesntExist = 9,
    PremiumDistributionNotPermittedYet = 10,
    DistributionNotPermittedYet = 11,
    ExceededNumberOfPlaylistPreferences = 16,
    DistributingOfEntireLibraryOngoing = 18,
    UserDeletedRecently = 21,
    DuplicatePlaylistName = 23,
    PlaylistNameReserved = 24,
    ProvidedTooMuchFeedback = 28,
    FeatureNotAvailableForCurrentMembershipLevel = 30,
    PlaylistDeletionOngoing = 34,
    ExceededNumberOfPlaylistPreferencesPremium = 35,
    AnalysisOngoing = 37,
    AnalysisStartedTooRecently = 38,
}
import './Footer.css'
import React from "react";

const Footer = () => {
    return (
        <div id="footer">
            <div className="footerSection"><a href="mailto:support@likedsongsmanager.com">Contact us</a> | <a href="/policies/privacy-policy">Privacy Policy</a> | <a
                href="/policies">Policies & Terms of Service</a> | <a href="https://twitter.com/likedsongsmgr">Twitter</a></div>
            <div className="footerSection">Liked Songs Manager is in no way affiliated with Spotify | Holdsoft Solutions CVR 44189631</div>
        </div>
    )
}
export default Footer;
import React from "react";
import './Wizard.css';

interface ValuePickerButtonProps {
    criterionNumber: number;
    value: string;
    addValue: (criterionNumber: number, value: string) => void;
}

export const ValuePickerButton = (props: ValuePickerButtonProps) => {
    return (
        <div onClick={() => props.addValue(props.criterionNumber, props.value)} className={"valuePickerButton unselectable"}>
            {props.value}
        </div>
    )
}
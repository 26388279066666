import {Bar, Line} from "react-chartjs-2";
import React, {useEffect, useState} from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import {UserData} from "../../api/admin/Statistics";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);

interface UserStatisticsProps {
    userData: UserData;
}

const UserStatistics = (props: UserStatisticsProps) => {
    let newUsersToday = Object.values(props.userData.numberOfUsersPerDay)[0] as number;
    return (
        <div>
            <h3>User statistics</h3>
            <p>Number of monitored users: {props.userData.numberOfMonitoredUsers}.</p>
            <p>Number of users last day: {props.userData.numberOfUsersLastDay}.</p>
            <p>Number of new users today: {newUsersToday}.</p>
            <PremiumInfluxChart userData={props.userData}/>
            <UserInfluxChart userData={props.userData} />
            <UsersPerDayBarChart userData={props.userData}/>
        </div>
    )
}

const UserInfluxChart  = (props: UserStatisticsProps) => {
    let labels: string[] = [];
    let dataValues: number[] = [];
    for (const [key, value] of Object.entries(props.userData.userInflux)) {
        let date = new Date(Date.parse(key));
        let dateKey = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
        labels.push(dateKey);
        dataValues.push(value);
    }

    const data = {
        labels,
        datasets: [
            {
                label: 'Amount',
                data: dataValues,
                backgroundColor: 'rgba(255, 99, 132, 0.5)'
            }
        ]
    }

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: 'Amount of users',
            },
        },
    };
    return (
        <div style={{height: '200px', width: "100%"}}>
            <Line data={data} options={options}/>
        </div>
    );
}

const PremiumInfluxChart  = (props: UserStatisticsProps) => {
    let labels: string[] = [];
    let dataValues: number[] = [];
    for (const [key, value] of Object.entries(props.userData.premiumInflux)) {
        let date = new Date(Date.parse(key));
        let dateKey = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
        labels.push(dateKey);
        dataValues.push(value);
    }
    labels.reverse();
    dataValues.reverse();

    const data = {
        labels,
        datasets: [
            {
                label: 'Amount',
                data: dataValues,
                backgroundColor: 'rgba(255, 99, 132, 0.5)'
            }
        ]
    }

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: 'Amount of premium',
            },
        },
    };
    return (
        <div style={{height: '200px', width: "100%"}}>
            <Line data={data} options={options}/>
        </div>
    );
}

const UsersPerDayBarChart = (props: UserStatisticsProps) => {
    let labels: string[] = [];
    let dataValues: number[] = [];
    for (const [key, value] of Object.entries(props.userData.numberOfUsersPerDay)) {
        let date = new Date(Date.parse(key));
        let dateKey = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
        labels.push(dateKey);
        dataValues.push(value as number);
    }
    labels.reverse();
    dataValues.reverse();


    const data = {
        labels,
        datasets: [
            {
                label: 'Number of users per day',
                data: dataValues,
                backgroundColor: 'rgba(255, 99, 132, 0.5)'
            }
        ]
    }

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: 'Number of users per day in the last month',
            },
        },
    };
    return (
        <div style={{height: '200px', width: "100%"}}>
            <Bar data={data} options={options}/>
        </div>
    );
}

export default UserStatistics;
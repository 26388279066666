import React from 'react';
import {PreferenceButton} from '../common/PreferenceButton';
import {SimplePreference} from './SimplePreference';
import {DeletePreferenceButton} from "../common/DeletePreferenceButton";
import './PreferenceManagementPlaylistModule.css';
import {ErrorCode} from "../../../../api/ApiError";
import {ErrorMessage} from "../../../common/ErrorMessage";

interface PreferenceManagementPlaylistModuleProps {
    errorCode: ErrorCode | undefined;
    deletablePreferences: SimplePreference[];
    preferences: SimplePreference[];
    addSelectedPreferences: (preference: string) => void;
    removeSelectedPreferences: (preference: string) => void;
    deletePreference: (preference: SimplePreference) => void;
    preferenceUnavailable?: (preference: string) => void;
    numberOfSelectedPreferences: number;
    mostRecentlySelected: string | undefined;
    group: string;
    tooManySelectedPreferencesText: string;
    roomForMorePreferences: boolean;
    analysisStarted: boolean;
    numberOfApplicableSongsForPreference: Map<string, number>;
}

const PreferenceGroupsApplicableForAnalysis = [
    "Mood",
    "Genre",
    "Decade",
    "Nationality"
];

const PreferenceManagementPlaylistModule = (props: PreferenceManagementPlaylistModuleProps) => {
    let deletablePreferencesForGroup = props.deletablePreferences
        .filter(preference => preference.group === props.group)
        .map(preference => preference.name);

    props.preferences.sort((a, b) => {
        return a.name.localeCompare(b.name, undefined, {numeric: true});
    });

    let groupApplicableForAnalysis = PreferenceGroupsApplicableForAnalysis.includes(props.group);

    let addPreferenceButtons = props.preferences
        .filter((preference) => !deletablePreferencesForGroup.includes(preference.name))
        .map((preference) => {
            let numberOfApplicableSongsForPreference = props.numberOfApplicableSongsForPreference.get(preference.name) !== undefined
                ? props.numberOfApplicableSongsForPreference.get(preference.name)
                : props.analysisStarted && groupApplicableForAnalysis
                    ? 0
                    : undefined;

            return <PreferenceButton
                mostRecentlyClicked={props.mostRecentlySelected === preference.name}
                roomForMorePreferences={props.roomForMorePreferences}
                active={preference.selected}
                addSelectedPreferences={props.addSelectedPreferences}
                removeSelectedPreferences={props.removeSelectedPreferences}
                preference={preference.name}
                key={preference.name}
                icon={getIcon()}
                tooManySelectedPreferencesText={props.tooManySelectedPreferencesText}
                available={preference.available}
                preferenceUnavailable={props.preferenceUnavailable}
                numberOfApplicableSongs={numberOfApplicableSongsForPreference}
            />
        })

    let deletePreferenceButtons = props.preferences
        .filter((preference) => deletablePreferencesForGroup.includes(preference.name))
        .map((preference) => {
            return <DeletePreferenceButton
                deletePreferences={props.deletePreference}
                preference={preference}
                key={preference.name}/>
        })

    function getIcon(): string | undefined {
        return undefined;
    }

    return (
        <div>
            {addPreferenceButtons.length > 0 &&
                <div>
                    <div className={"preferenceButtonsSection"}>
                        {addPreferenceButtons}
                    </div>
                </div>
            }
            {deletePreferenceButtons.length > 0 &&
                <div>
                    <div className={"preferenceButtonsSection"}>
                        {deletePreferenceButtons}
                    </div>
                </div>
            }
            <ErrorMessage errorCode={props.errorCode}/>
        </div>
    )
}

export default PreferenceManagementPlaylistModule;


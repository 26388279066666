import React, {useState} from "react";
import {ApiError, ErrorCode} from "../../api/ApiError";
import {createFeedback} from "../../api/feedback/FeedbackApi";
import {GoToLoginButton} from "../common/Buttons";
import {ErrorMessage} from "../common/ErrorMessage";
import './Feedback.css';
import PageSegmentLoading from "../common/PageSegmentLoading";
import {useAuthorizationContext} from '../../context/authorizationContext'
import {Link} from "react-router-dom";

const Feedback = () => {
    const {loggedIn} = useAuthorizationContext();
    return loggedIn ? <FeedbackLoggedIn/> : <FeedbackNotLoggedIn/>;
}

const FeedbackNotLoggedIn = () => {
    return (
        <div className="pageContentSegment">
            <h1>Feedback</h1>
            <p>You must be logged in to provide feedback.</p>
            <GoToLoginButton/>
        </div>
    );
}

const FeedbackLoggedIn = () => {
    const [feedback, setFeedback] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>();
    const [errorCode, setErrorCode] = useState<ErrorCode>();
    const [policyRead, setPolicyRead] = useState<boolean>(false);

    return (
        <div className="pageContentSegment">
            <h1>Feedback</h1>
            <p>Here you may provide specific feedback on existing products, for example songs being wrongly added to a playlist.</p>
            <div className="userInputContainer">
                <input placeholder="Feedback on your mind" onChange={(evt) => feedbackInputChanged(evt)} className="userInput" type="text" value={feedback}/>
            </div>
            <div className="feedbackTerms" onClick={termsClicked}>
                <input id="feedbackCheckbox" type="checkbox" defaultChecked={policyRead}/>
                <label htmlFor="feedbackCheckbox">Please read our <Link to={'/feedback/policy'}>Unsolicited idea submission policy</Link> before sending us your feedback.</label>
            </div>
            <div className="feedbackButton">
                <div className="button unselectable buttonMarginBottom buttonMarginTop">
                    <div onClick={() => sendFeedback()} className="buttonConfirmation">Send feedback</div>
                </div>
            </div>
            <ErrorMessage errorMessage={errorMessage} errorCode={errorCode}/>
        </div>
    )

    function termsClicked() {

        setPolicyRead((prevState) => !prevState);
    }

    function sendFeedback() {
        setErrorMessage(undefined);
        setErrorCode(undefined);
        if (feedback.length === 0) {
            setErrorMessage("You have not written anything.");
            return;
        }

        if (!policyRead) {
            setErrorMessage("Read submission policy and check the box");
            return;
        }

        createFeedback(feedback)
            .then(() => window.location.replace(`/feedback/success`))
            .catch((error: ApiError) => setErrorCode(error.errorCode));
    }

    function feedbackInputChanged(e: React.FormEvent<HTMLInputElement>) {
        let value = e.currentTarget.value;

        if (value.length > 200)
            return;

        if (value.length > 0 && value.match(("^[a-zA-Z0-9 .,?!-]+$")) === null)
            return;

        setFeedback(value);
    }
}

export default Feedback;

import React from "react";

export const PrivacyPolicy = () => {
    return (
        <div className="pageContentSegment">
            <h1>Privacy Policy</h1>
            <i>Last modified: 9th June 2024.</i>
            <p>The privacy of your data—and it is your data, not ours!—is a big deal to us. In this policy, we lay out: what data we collect and why; how your data is handled; and
                your rights with respect to your data. We promise we never sell your data: never have, never will.</p>
            <p>This policy applies to all products built and maintained by Holdsoft Solutions including Liked Songs Manager.</p>
            <p>This policy applies to our handling of information about site visitors, prospective customers, and customers and authorized users (in relation to their procurement
                of the services and management of their relationship with Holdsoft Solutions). We refer collectively to these categories of individuals as "you" throughout this
                policy.</p>
            <h2>What we collect and why</h2>
            <p>Our guiding principle is to collect only what we need. Here's what that means in practice:</p>
            <h3>Identity and access</h3>
            <p>When you sign in to Liked Songs Manager, you authorize Liked Songs Manager to be able to access to your user id, name and username, your profile picture, how many
                followers you have on Spotify from Spotify's API. However, of this data only the user id is accessed by Liked Songs Manager.
                You also authorize Liked Songs Manager to get access to what you have saved in your library on Spotify and
                your playlists as well as edit them. That's so Liked Songs Manager may add your liked songs to Spotify playlists created by Liked Songs Manager.</p>
            <p>In full, Liked Songs Manager stores the following information from Spotify on its servers:</p>
            <ul>
                <li>Your spotify user id, which is necessary for managing playlists by the server without user interaction.</li>
                <li>Access tokens, which are necessary for managing playlists and retrieving your liked songs by the server without user interaction.</li>
                <li>Your liked songs, when they were liked at, and how and when the application has distributed these liked songs into Spotify playlists created by Liked Songs
                    Manager.
                </li>
            </ul>
            <p>If you delete your account, we'll delete the content within 30 days.</p>
            <p>When you sign in to Liked Songs Manager, we also anonymously store the site that you referred you to Liked Songs Manager. This is in no way connected to your Liked
            Songs Manager account.</p>
            <h3>Billing information</h3>
            <p>If you sign up for a paid Liked Songs Manager subscription, you will be asked to provide your payment information and billing address. The billing address and
                payment information, including Credit card information, is submitted directly to our payment processor and doesn't hit Liked Songs Manager's servers. We store a
                record of the payment transaction for purposes of account history.</p>
            <h3>Product interactions</h3>
            <p>We store on our servers the content that you upload or receive or maintain in your Liked Songs Manager account. This is so you can use our products as
                intended. We keep this content as long as your account is active. If you delete your account, we'll delete the content within 30 days.</p>
            <p>We store the following product interaction data:</p>
            <ul>
                <li>Which playlists you have selected on Liked Songs Manager, and when you have selected them.</li>
                <li>The feedback you have provided.</li>
                <li>Your membership level details</li>
            </ul>
            <p>For paid subscriptions, if you delete your account, the membership level details data is kept for the full duration of the paid subscription and it is deleted at
                most 70
                days after the subcription has expired or terminated.</p>
            <p>All other product interaction data is deleted within 30 days if you delete your account.</p>
            <h3>General Geolocation data</h3>
            <p>Your IP address is used to find which country you live in, so that Liked Songs Manager may show the proper currency and price for its subscription products.
                It is also used in the Paddle checkout to find your country for the payment details.</p>
            <h3>Cookies</h3>
            <p>We use persistent functional first-party cookies to handle authorization and authentication. We also store some third-party cookies to handle sticky sessions when
                making your purchase with Paddle.</p>
            <p>We do not use any cookies to track you in any way.</p>
            <p>A cookie is a piece of text stored by your browser. It may help remember login information and site preferences. It might also collect information such as your
                browser type, operating system, web pages visited, duration of visit, content viewed, and other click-stream data. You can adjust cookie retention settings and
                accept or block individual cookies in your browser settings, although our apps won't work and other aspects of our service may not function properly if you turn
                cookies off.</p>
            <h3>Voluntary correspondence</h3>
            <p>When you email Holdsoft Solutions with a question or to ask for help, we keep that correspondence, including your email address, so that we have a history of past
                correspondence to reference if you reach out in the future.</p>
            <h2>When we access or disclose your information</h2>
            <p><b>To provide products or services you've requested.</b> We use some third-party processors and subprocessors to help run our applications and provide Liked Songs
                Manager services to you. This is our list of processors and sub-processors, where the data processing is located and what we use the vendor for:</p>
            <h4>DigitalOcean</h4>
            <ul>
                <li>Cloud services provider that handles all our hosting and runs Liked Songs Manager software.</li>
                <li>Processes the data that you put in Liked Songs Manager.</li>
                <li>The servers are located in the EU (the Netherlands).</li>
                <li>More information on DigitalOcean's Privacy Policy can be found here: <a
                    href="https://www.digitalocean.com/legal/privacy-policy/">https://www.digitalocean.com/legal/privacy-policy/</a>.
                </li>
            </ul>
            <h4>Paddle</h4>
            <ul>
                <li>Payment service provider that acts as our Merchant of Record based in the UK.</li>
                <li>We share your account id to be able to fulfil your premium subscription purchase.</li>
                <li>We share your IP address to be able to find the correct pricing information for your country and region.</li>
                <li>More information on Paddle Privacy Policy can be found here: <a
                    href="https://www.digitalocean.com/legal/privacy-policy/">https://www.paddle.com/legal/privacy</a>.
                </li>
            </ul>
            <p>No Holdsoft Solutions human looks at your content except for limited purposes, for example, if an error occurs that stops an automated
                process from working and requires manual intervention to fix. These are rare cases, and when they happen, we look for root cause solutions as much as possible to
                avoid them recurring. We may also access your data if required in order to respond to legal process (see "When required under applicable law" below).</p>
            <p><b>To troubleshoot an issue or squash a software bug.</b></p>
            <p><b>When required under applicable law</b>. Holdsoft Solutions is an Danish company located in the EU.</p>
            <ul>
                <li>Requests for user data. Our policy is to not respond to government requests for user data unless we are compelled by legal process or in limited circumstances
                    in the event of an emergency request.
                </li>
                <li>If we are audited by a tax authority, we may be required to disclose billing-related information. If that happens, we will disclose only the minimum needed.
                </li>
            </ul>
            <p>Finally, if Holdsoft Solutions is acquired by or merges with another company — we don't plan on that, but if it happens — we'll notify you on this site before any of your
                personal information is transferred or becomes subject to a different privacy policy.</p>
            <h3>Your rights with respect to your information</h3>
            <ul>
                <li><b>Right to Know.</b> You have the right to know what personal information is collected, used, shared or sold. We outline both the categories and specific bits
                    of
                    data we collect, as well as how they are used, in this privacy policy.
                </li>
                <li><b>Right of Access.</b> This includes your right to access the personal information we gather about you, and your right to obtain information about the sharing,
                    storage, security and processing of that information.
                </li>
                <li><b>Right to Correction.</b> You have the right to request correction of your personal information.</li>
                <li><b>Right to Erasure / "To Be Forgotten".</b> This is your right to request, subject to certain limitations under applicable law, that your personal information
                    be erased from our possession and, by extension, from all of our service providers. Fulfillment of some data deletion requests may prevent you from using
                    Holdsoft Solution's services because our applications may then no longer work. In such cases, a data deletion request may result in closing your account.
                </li>
                <li><b>Right to Object.</b> You have the right, in certain situations, to object to how or why your personal information is processed.</li>
                <li><b>Right to Portability.</b> You have the right to receive the personal information we have about you and the right to transmit it to another party.</li>
                <li><b>Right to Non-Discrimination.</b> We do not and will not charge you a different amount to use our products, offer you different discounts, or give you a lower
                    level of customer service because you have exercised your data privacy rights. However, the exercise of certain rights may, by virtue of your exercising those
                    rights, prevent you from using our Services.
                </li>
            </ul>
            <p>Some of these rights can be exercised by signing in and updating your account information.</p>
            <p>In some cases, we also need to take reasonable steps to verify your identity before responding to a request, which may include, at a minimum, depending on the
                sensitivity of the information you are requesting and the type of request you are making, verifying your name and email address. If we are unable to verify you,
                we may be unable to respond to your requests.</p>
            <p>If you have questions about exercising these rights or need assistance, please contact us at <a
                href="mailto:support@likedsongsmanager.com">support@likedsongsmanager.com</a>. If an authorized agent is corresponding on your behalf, we will need written consent with
                a signature from the account holder before proceeding.
            </p>
            <p>If you wish to file a complaint to local data authorities, you can contact <a href="https://www.datatilsynet.dk/borger/klage">The Danish Data Protection
                Agency.</a>
            </p>
            <h3>Accepting payments from you</h3>
            <p>We have chosen to outsource the responsibility of handling store checkout to an established third party service, namely
                Paddle. They handle all the heavy-lifting of accepting numerous payment sources as well as handling country-specific taxes, and more. If you wish to buy a
                premium subscription, it will be Paddle who accepts the payment from you. As such, they need to identify you using your personal data, for which they may ask
                for your separate consent in their checkout with their own privacy policy.</p>
            <h3>How we secure your data</h3>
            <p>All data is encrypted via SSL/TLS when transmitted from our servers to your browser. In addition, we go to great lengths to secure your data at rest.</p>
            <p>All information you provide to us is stored on secure servers. We use technical and organizational measures to safeguard your Personal Data.</p>
            <p>Our application databases are generally not encrypted at rest — the information you add to the applications is active in our databases and subject to the same
                protection and monitoring as the rest of our systems.</p>
            <p>You can read more about how our Cloud services provider DigitalOcean handles security <a href="https://www.digitalocean.com/security">here</a> and their Privacy Policy 
                <a href="https://www.digitalocean.com/legal/privacy-policy">here</a>.</p>
            <h3>Data retention</h3>
            <p>We keep your information for the time necessary for the purposes for which it is processed. The length of time for which we retain information depends on the
                purposes for which we collected and use it and your choices, after which time we may delete and/or aggregate it. We may also retain and use this information as
                necessary to comply with our legal obligations, resolve disputes, and enforce our agreements. </p>
            <p>When we have no ongoing legitimate business need to process your personal data, it is deleted.</p>
            <h3>What happens when you delete your account</h3>
            <p>If you choose to delete your account, your content will become immediately inaccessible. Through this policy, we have provided specific retention periods for certain
                types of information when deleting your account.</p>
            <p>We keep backups with data for security reasons. These are deleted after 30 days.</p>
            <p>Adapted from the <a href="https://github.com/basecamp/policies">Basecamp open-source policies</a> / <a href="https://creativecommons.org/licenses/by/4.0/">CC BY
                4.0</a></p>
        </div>
    )
}
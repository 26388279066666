import './DeletePreferenceButton.css';
import React from "react";
import {SimplePreference} from '../simple/SimplePreference';

interface DeletePreferenceButtonProps {
    preference: SimplePreference;
    deletePreferences: (preference: SimplePreference) => void;
}

export const DeletePreferenceButton = (props: DeletePreferenceButtonProps) => {
    function handleOnClick() {
        props.deletePreferences(props.preference);
    }

    return (
        <div>
            <div onClick={() => handleOnClick()} className={"deletePreferenceButton unselectable"}>
                <div className={"deletePreferenceContent"}>
                    <div className="deletePreferenceContentText">
                        {props.preference.name}
                    </div>
                </div>
            </div>
        </div>
    );
}
import React, {useEffect, useState} from "react";
import './Plans.css';
import {useAuthorizationContext} from "../../context/authorizationContext";
import {MembershipLevel, useUserContext} from "../../context/userContext";
import {getId} from "../../api/user/UserApi";
import {ErrorMessage} from "../common/ErrorMessage";
import {cancelSubscription, getPremiumPricing, getSubscription, getUpdatePaymentMethodTransaction, reactivateSubscription} from "../../api/payment/PaymentApi";
import {PremiumPricing} from "../../api/payment/PremiumPricing";
import {SmallestConfirmationButton, SmallestWarningButton} from "../common/Buttons";
import {Subscription} from "../../api/payment/Subscription";
import {SubscriptionStatus} from "./SubscriptionStatus";
import {formatDate, parseDate} from "../../util/Dates";
import {UpdatePaymentMethodTransaction} from "../../api/payment/UpdatePaymentMethodTransaction";
import {AvailableIcon, NotAvailableIcon} from "../common/Icons";
import Footer from "../../footer/Footer";
import ComplexPageSegmentLoading from "../common/ComplexPageLoading";
import {PaddleTimedOut} from "./PaddleTimedOut";

const Plans = () => {
    const {loggedIn} = useAuthorizationContext();
    const {membershipLevel} = useUserContext();
    const [premiumPriceAmount, setPremiumPriceAmount] = useState<number | undefined>(undefined);
    const [premiumPriceCurrency, setPremiumPriceCurrency] = useState<string | undefined>(undefined);
    const [nextBillingDate, setNextBillingDate] = useState<Date | undefined>(undefined);
    const [subscriptionStatus, setSubscriptionStatus] = useState<SubscriptionStatus | undefined>(undefined);
    const [currentTermEnds, setCurrentTermEnds] = useState<Date | undefined>(undefined);
    const [recurringFeeAmount, setRecurringFeeAmount] = useState<number | undefined>(undefined);
    const [recurringFeeCurrency, setRecurringFeeCurrency] = useState<string | undefined>(undefined);
    const [paddleTimedOut, setPaddleTimedOut] = useState<boolean>(false);

    useEffect(() => {
        // @ts-ignore
        Paddle.Initialize({
            token: 'live_0adb4cb26010f8b84208b9020d3'
        });
        // @ts-ignore
        Paddle.Setup({
            seller: 175627
        });
        getPremiumPricing()
            .then((result: PremiumPricing) => {
                setPremiumPriceAmount(result.amount);
                setPremiumPriceCurrency(result.currency);
            })
            .catch(() => setPaddleTimedOut(true));
    }, []);

    useEffect(() => {
        if (membershipLevel !== MembershipLevel.PremiumYearly)
            return;

        getSubscription()
            .then((result: Subscription) => {
                if (result.nextBillingDate !== undefined) {
                    setNextBillingDate(parseDate(result.nextBillingDate));
                }
                if (result.currentBillingPeriodEnds !== undefined) {
                    setCurrentTermEnds(parseDate(result.currentBillingPeriodEnds));
                }
                if (result.recurringFeeAmount !== undefined) {
                    setRecurringFeeAmount(result.recurringFeeAmount.amount);
                    setRecurringFeeCurrency(result.recurringFeeAmount.currency);
                }
                setSubscriptionStatus(SubscriptionStatus[result.status as keyof typeof SubscriptionStatus]);
            })
            .catch(() => setPaddleTimedOut(true));
    }, [membershipLevel]);
    
    if (paddleTimedOut) {
        return <PaddleTimedOut />
    }

    if (loggedIn === undefined || membershipLevel === undefined || premiumPriceAmount === undefined || premiumPriceCurrency === undefined) {
        return <ComplexPageSegmentLoading/>
    }

    if (membershipLevel === MembershipLevel.PremiumYearly && subscriptionStatus === undefined) {
        return <ComplexPageSegmentLoading/>
    }

    return (
        <React.Fragment>
            <div id="pageContainer">
                <div id="pageContent">
                    <div id="plansContainer">
                        <ActivePlan
                            membershipLevel={membershipLevel}
                            subscriptionStatus={subscriptionStatus}
                            nextBillingDate={nextBillingDate}
                            currentTermsEnds={currentTermEnds}
                            recurringFeeAmount={recurringFeeAmount}
                            recurringFeeCurrency={recurringFeeCurrency}
                        />
                        <UpgradePlan loggedIn={loggedIn} membershipLevel={membershipLevel} price={premiumPriceAmount.toString()} currency={premiumPriceCurrency}/>
                    </div>
                </div>
            </div>
            <Footer/>
        </React.Fragment>
    )
}

interface ActivePlanProps {
    membershipLevel: MembershipLevel;
    subscriptionStatus?: SubscriptionStatus;
    nextBillingDate?: Date;
    currentTermsEnds?: Date;
    recurringFeeAmount?: number;
    recurringFeeCurrency?: string;
}

const ActivePlan = (props: ActivePlanProps) => {
    const [errorMessage, setErrorMessage] = useState<React.ReactElement | undefined>(undefined);

    if (props.membershipLevel != MembershipLevel.PremiumYearly && props.membershipLevel != MembershipLevel.FreeBeforePremiumIntroduced) {
        return <React.Fragment/>
    }

    if (props.membershipLevel == MembershipLevel.PremiumYearly && props.subscriptionStatus === SubscriptionStatus.Cancelled) {
        return <React.Fragment/>
    }

    if (props.membershipLevel == MembershipLevel.FreeBeforePremiumIntroduced) {
        return (
            <div className="pageContentSegment">
                <h2>Your current plan</h2>
                <h3>Free user before the introduction of the premium plan</h3>
                <p>You still have access to the same features as prior to the introduction of premium features, but you will not have access to any of the new premium features
                    henceforth.</p>
                <p>If you wish to have access to the premium features, then you may upgrade to premium.</p>
                <p>We reserve the right to change and remove this plan at any point.</p>
            </div>
        )
    }

    return (
        <div className="pageContentSegment">
            <h2>Your current plan</h2>
            <h3>Premium Member ⭐</h3>
            <p>You have full access to all of the features of Liked Songs Manager.</p>
            <h3>Manage your Premium subscription</h3>
            <table>
                <tbody>
                <tr className={"bordered"}>
                    <td className="subscriptionDescriptor">Status</td>
                    <td>{statusPrettified(props.subscriptionStatus)}</td>
                </tr>
                <tr className={"bordered"}>
                    <td className="subscriptionDescriptor">Next billing date</td>
                    <td>{formatDate(props.nextBillingDate)}</td>
                </tr>
                <tr className={"bordered"}>
                    <td className="subscriptionDescriptor">Current term ends</td>
                    <td>{formatDate(props.currentTermsEnds)}</td>
                </tr>
                <tr className={"bordered"}>
                    <td className="subscriptionDescriptor">Recurring fee</td>
                    <td>{props.recurringFeeAmount} {props.recurringFeeCurrency}</td>
                </tr>
                </tbody>
            </table>
            <div id="subscriptionButtons">
                {props.subscriptionStatus === SubscriptionStatus.Active &&
                    <React.Fragment>
                        <SmallestConfirmationButton onClick={() => changePaymentDetails()} text={"Change payment details"}/>
                        <SmallestWarningButton onClick={() => doCancelSubscription()} text={"Cancel subscription"}/>
                    </React.Fragment>
                }
                {props.subscriptionStatus === SubscriptionStatus.BeingCancelled &&
                    <SmallestConfirmationButton onClick={() => doReactivateSubscription()} text={"Re-activate subscription"}/>}
            </div>
            {errorMessage !== undefined &&
                <div className="errorMessage">
                    {errorMessage}
                </div>}
        </div>
    )

    function statusPrettified(status?: SubscriptionStatus): string {
        if (status === SubscriptionStatus.Active) {
            return "Your subscription is active";
        }
        if (status === SubscriptionStatus.BeingCancelled) {
            return "Your subscription will be cancelled at the end of the current term";
        }

        return "";
    }

    function changePaymentDetails() {
        getUpdatePaymentMethodTransaction()
            .then((transaction: UpdatePaymentMethodTransaction) => {
                // @ts-ignore
                Paddle.Checkout.open({
                    settings: {
                        theme: "light",
                        successUrl: 'https://www.likedsongsmanager.com/plans/payment-details-updated/success'
                    },
                    transactionId: transaction.transactionId
                })
            })
            .then(() => window.scrollTo(0, 0))
            .catch(() => setErrorMessage(<p>There was an unexpected error trying to edit your payment details of your subscription, please <a
                href="mailto:support@likedsongsmanager.com">send us an e-mail</a> and we will be in touch.</p>))
    }

    function doCancelSubscription() {
        let confirmed = window.confirm("Are you certain you want to cancel your subscription?");
        if (!confirmed)
            return;

        cancelSubscription()
            .then(() => window.location.reload())
            .catch(() => setErrorMessage(<p>There was an unexpected error cancelling your subscription, please <a href="mailto:support@likedsongsmanager.com">send us an
                e-mail</a> and we will be in touch.</p>))
    }

    function doReactivateSubscription() {
        reactivateSubscription()
            .then(() => window.location.reload())
            .catch(() => setErrorMessage(<p>There was an unexpected error re-activating your subscription, please <a href="mailto:support@likedsongsmanager.com">send us an
                e-mail</a> and we will be in touch.</p>))
    }
}

interface UpgradePlanProps {
    loggedIn: boolean;
    membershipLevel: MembershipLevel;
    currency: string;
    price: string;
}

const UpgradePlan = (props: UpgradePlanProps) => {
    return <UpgradePlanAvailable premiumUnavailable={false}
                                 membershipLevel={props.membershipLevel}
                                 loggedIn={props.loggedIn}
                                 currency={props.currency}
                                 price={props.price}/>
}

interface UpgradePlanAvailableProps {
    price: string;
    currency: string;
    loggedIn: boolean;
    premiumUnavailable: boolean;
    membershipLevel: MembershipLevel;
}

const UpgradePlanAvailable = (props: UpgradePlanAvailableProps) => {
    return (
        <React.Fragment>
            <div className="pageContentSegment">
                <h1>Membership Plans</h1>
                <p>
                    Support the development and get even more out of Liked Songs Manager with a Premium subscription!
                </p>
                <div id="membershipLevelContainer">
                    <div className="membershipLevel">
                        <h2 className="membershipHeader">Free</h2>
                        <p className="membershipLevelExplainer">Try out Liked Songs Manager and its standard features for free.</p>
                        <p className="price">0 {props.currency}</p>
                        <div className="membershipLevelTeaser">
                            <ul>
                                <li className="teaserAvailability">
                                    <AvailableIcon/> Standard playlists
                                </li>
                                <li className="teaserAvailability">
                                    <AvailableIcon/> Up to 15 managed playlists
                                </li>
                                <li className="teaserAvailability notAvailableFeature">
                                    <NotAvailableIcon/> First 1000 liked songs considered for the playlists
                                </li>
                            </ul>
                        </div>
                        <div className="upgradeMembershipLevelButtonContainer">
                            {!props.loggedIn && <a className="plansConfirmButtonLink" href={'/plans/login'}>
                                <div className="unselectable plansConfirmButton free">Get started for free</div>
                            </a>
                            }
                        </div>
                    </div>
                    <div className="membershipLevel premiumMembershipLevel">
                        <h2 className="membershipHeader premiumHeader">Premium</h2>
                        <p className="membershipLevelExplainer">Get access to all of the premium features of Liked Songs Manager.</p>
                        <p className="price premium">
                            {props.price} {props.currency}
                        </p>
                        <p className="priceExplainer">per year billed annually</p>
                        <div className="membershipLevelTeaser">
                            <ul>
                                <li className="teaserAvailability">
                                    <AvailableIcon/> Standard playlists
                                </li>
                                <li className="teaserAvailability">
                                    <AvailableIcon/> Premium playlists
                                </li>
                                <li className="teaserAvailability">
                                    <AvailableIcon/> Combination playlists
                                </li>
                                <li className="teaserAvailability">
                                    <AvailableIcon/> All of your liked songs considered for the playlists
                                </li>
                                <li className="teaserAvailability">
                                    <AvailableIcon/> Up to 100 managed playlists
                                </li>
                                <li className="teaserAvailability">
                                    <AvailableIcon/> Cancel your subscription at any time
                                </li>
                            </ul>
                            <UpgradeButton premiumUnavailable={props.premiumUnavailable} loggedIn={props.loggedIn}
                                           isPremium={props.membershipLevel == MembershipLevel.PremiumYearly}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pageContentSegment">
                <h2>Compare the plans</h2>
                <div id="featureTableSection">
                    <FeatureTable/>
                </div>
            </div>
        </React.Fragment>
    )
}

interface UpgradeButtonProps {
    isPremium: boolean;
    loggedIn: boolean;
    premiumUnavailable: boolean;
}

const UpgradeButton = (props: UpgradeButtonProps) => {
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

    return (
        <div className="upgradeMembershipLevelButtonContainer">
            <div className="upgradeMembershipForm">
                <div className="plansConfirmButtonLink">
                    {!props.loggedIn &&
                        <a href={'/plans/login'}>
                            <button className="unselectable plansConfirmButton premium">Upgrade to Premium</button>
                        </a>}
                    {!props.isPremium && props.loggedIn &&
                        <button onClick={() => onUpgradeClick()} className="unselectable plansConfirmButton premium">Upgrade to Premium</button>}
                </div>
            </div>
            {props.premiumUnavailable &&
                <ErrorMessage errorMessage={"If no checkout popup appears successfully, it unfortunately means that premium is not available in your location."}/>}
            {errorMessage !== undefined && <ErrorMessage errorMessage={errorMessage}/>}
        </div>
    )

    function onUpgradeClick() {
        getId()
            .then(userId => {
                // @ts-ignore
                Paddle.Checkout.open({
                    settings: {
                        theme: "light",
                        successUrl: 'https://www.likedsongsmanager.com/plans/success'
                    },
                    items: [
                        {
                            priceId: 'pri_01h9ne102e48tvht48ht0rgkta',
                            quantity: 1
                        }
                    ],
                    customData: {
                        user_id: userId
                    }
                });
            }).then(() => window.scrollTo(0, 0));
    }
}

const FeatureTable = () => {
    return (
        <div>
            <table id="featureTable">
                <thead>
                <tr>
                    <th></th>
                    <th>Free</th>
                    <th className={"premiumHeader"}>Premium</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className={"feature"}>Mood playlists</td>
                    <td><AvailableIcon/></td>
                    <td><AvailableIcon/></td>
                </tr>
                <tr>
                    <td className={"feature"}>Genre playlists</td>
                    <td><AvailableIcon/></td>
                    <td><AvailableIcon/></td>
                </tr>
                <tr>
                    <td className={"feature"}>Subgenre playlists</td>
                    <td><AvailableIcon/></td>
                    <td><AvailableIcon/></td>
                </tr>
                <tr>
                    <td className={"feature"}>Decade playlists</td>
                    <td><AvailableIcon/></td>
                    <td><AvailableIcon/></td>
                </tr>
                <tr>
                    <td className={"feature"}>Nationality playlists</td>
                    <td><AvailableIcon/></td>
                    <td><AvailableIcon/></td>
                </tr>
                <tr>
                    <td className={"feature"}>Liked At playlists (Monthly, Yearly, Seasons)</td>
                    <td><NotAvailableIcon/></td>
                    <td><AvailableIcon/></td>
                </tr>
                <tr>
                    <td className={"feature"}>Randomized playlists</td>
                    <td><NotAvailableIcon/></td>
                    <td><AvailableIcon/></td>
                </tr>
                <tr>
                    <td className={"feature"}>Latest Liked Songs playlists</td>
                    <td><NotAvailableIcon/></td>
                    <td><AvailableIcon/></td>
                </tr>
                <tr>
                    <td className={"feature"}>Everything Playlist</td>
                    <td><NotAvailableIcon/></td>
                    <td><AvailableIcon/></td>
                </tr>
                <tr>
                    <td className={"feature"}>Partitioned Playlist</td>
                    <td><NotAvailableIcon/></td>
                    <td><AvailableIcon/></td>
                </tr>
                <tr>
                    <td className={"feature"}>Combination playlists</td>
                    <td><NotAvailableIcon/></td>
                    <td><AvailableIcon/></td>
                </tr>
                <tr>
                    <td className={"feature"}>Number of liked songs considered for the playlists</td>
                    <td>First 1000 liked songs</td>
                    <td>Unlimited</td>
                </tr>
                <tr>
                    <td className={"feature"}>Number of managed playlists</td>
                    <td>15</td>
                    <td>100</td>
                </tr>
                </tbody>
            </table>
        </div>
    );
}


export default Plans;
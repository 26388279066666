import './PreferenceManagementModule.css';
import {ReactComponent as Dice} from './dice.svg';
import {ReactComponent as Calendar} from './calendar.svg';
import {ReactComponent as Clock} from './clock.svg';
import {ReactComponent as Globe} from './globe.svg';
import {ReactComponent as Music} from './music.svg';
import {ReactComponent as Smile} from './smile.svg';
import {ReactComponent as Dots} from './dots.svg';
import {ReactComponent as History} from './history.svg';
import {ReactComponent as Star} from '../common/star.svg';
import React from "react";
import {PreferenceGroup} from "../simple/PreferenceGroup";


interface PreferenceManagementModuleProps {
    preferenceGroup: PreferenceGroup;
    explainText: string;
    subModule: React.ReactElement;
    allPreferencesAvailable: boolean;
    playlistsShown: boolean;
    playlistGroupHeaderClicked: (playlistGroup: string) => void;
}

let iconMap = new Map();
iconMap.set("dice", <Dice height={24} width={24}/>);
iconMap.set("calendar", <Calendar height={24} width={24}/>);
iconMap.set("clock", <Clock height={24} width={24}/>);
iconMap.set("globe", <Globe height={24} width={24}/>);
iconMap.set("music", <Music height={24} width={24}/>);
iconMap.set("smile", <Smile height={24} width={24}/>);
iconMap.set("dots", <Dots height={24} width={24}/>);
iconMap.set("history", <History height={24} width={24}/>);


const PreferenceManagementModule = (props: PreferenceManagementModuleProps) => {
    const chevronClasses = props.playlistsShown ? "chevron" : "chevron right";
    const preferenceManagementModuleClasses = props.playlistsShown ? "preferenceManagementModule expanded" : "preferenceManagementModule collapsed";
    return (
        <div className={"preferenceManagementModuleContainer"}>
            <div className={preferenceManagementModuleClasses}>
                <div className="unselectable preferenceManagementModuleHeader" onClick={() => props.playlistGroupHeaderClicked(props.preferenceGroup.group)}>
                    <div className="preferenceManagementModuleHeaderChevronContainer">
                        <div className="preferenceManagementModuleHeaderExplainer">
                            {props.preferenceGroup !== undefined &&
                                <div className="preferenceIcon">
                                    {iconMap.get(props.preferenceGroup.iconName)}
                                </div>
                            }
                            <div className="preferenceManagementModuleHeaderExplainerContainer">
                                <div className={"preferenceManagementModuleHeaderStarContainer"}>
                                    <div className={"preferenceManagementModuleHeaderExplainerText"}>{props.explainText}</div>
                                    {!props.allPreferencesAvailable && <div className={"premiumPreferencesHeaderStarDesktop"}>
                                        <Star width={24} height={24}/>
                                    </div>}
                                </div>
                                <div className={"preferenceManagementModuleHeaderExamplesStarContainer"}>
                                    <div className={"preferenceManagementModuleHeaderExplainerExamples"}>{props.preferenceGroup.examples}, ..</div>
                                </div>
                                {!props.allPreferencesAvailable && <div className={"premiumPreferencesHeaderStarMobile"}>
                                    <Star width={18} height={18}/>
                                </div>}
                            </div>
                        </div>
                        <div className={chevronClasses}/>
                    </div>
                </div>
            </div>
            {props.playlistsShown && <div className="preferenceManagementSubModule">
                {props.subModule}
            </div>}
        </div>
    )
}

export default PreferenceManagementModule;
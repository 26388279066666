import {LoginButton} from "../common/Buttons";
import React from "react";
import paths from "../../api/authorization/AuthorizationApi";

const PlansLogin = () => {
    return (
        <div className="pageContentSegment">
            <h1>Sign in</h1>
            <p>Please sign in to get started.</p>
            <LoginButton authorizeUrl={paths.authorizeFromPlans()}/>
        </div>
    )
}

export default PlansLogin;
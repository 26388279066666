import React, {useState} from "react";
import './Toggle.css';

interface ToggleProps {
    onClick: () => Promise<void>
    marginRight?: boolean;
    value: boolean;
}

export const Toggle = (props: ToggleProps) => {
    const [togglingValue, setTogglingValue] = useState<boolean>(false);

    let toggleClasses = "toggle";
    if (props.marginRight) {
        toggleClasses += " marginRight";
    }
    if (props.value) {
        toggleClasses += " toggled";
    }
    
    return (
        <div onClick={() => toggle()} className={toggleClasses}>
            <input onChange={() => {
            }} type="checkbox" checked={props.value}/>
            <div className="slider"/>
        </div>
    )

    function toggle() {
        if (togglingValue) {
            return;
        }

        setTogglingValue(true)
        props.onClick().finally(() => setTogglingValue(false));

    }
}
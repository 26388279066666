import {useRef, useEffect} from 'react'

function useDocumentTitle(title: string) {
    const defaultTitle = useRef(document.title);

    useEffect(() => {
        document.title = "Liked Songs Manager | " + title;
    }, [title]);

    useEffect(() => () => {
        document.title = defaultTitle.current;
    }, [])
}

export function useMetaDescription(metaDescription: string, prevailOnUnmount = false) {
    const defaultMetaDescription = document.querySelector('meta[name="description"]')?.getAttribute("content");

    useEffect(() => {
        document.querySelector('meta[name="description"]')?.setAttribute("content", metaDescription);
    }, [metaDescription]);

    useEffect(() => () => {
        if (!prevailOnUnmount) {
            document.querySelector('meta[name="description"]')?.setAttribute("content", defaultMetaDescription!)
        }
    }, [])
}

export default useDocumentTitle
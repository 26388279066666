import React from "react";
import './Groups.css';
import {useAuthorizationContext} from "../../../context/authorizationContext";
import {PlaylistRow, PlaylistTable} from "./Groups";
import {GoToLoginButton} from "../../common/Buttons";

export const Mood = () => {
    const {loggedIn} = useAuthorizationContext();

    return (
        <div>
            <div className="pageContentSegment">
                <h1>Mood playlists</h1>
                <p>Liked Songs Manager offers a variety of moods, whether you need to chill, get pumped, or get the party started.</p>
                <PlaylistTable>
                    <PlaylistRow name={"Beats"} availableFree={true}/>
                    <PlaylistRow name={"Chill"} availableFree={true}/>
                    <PlaylistRow name={"High Energy"} availableFree={true}/>
                    <PlaylistRow name={"Moody"} availableFree={true}/>
                    <PlaylistRow name={"Party"} availableFree={true}/>
                    <PlaylistRow name={"Upbeat"} availableFree={true}/>
                </PlaylistTable>
                {!loggedIn ? <GoToLoginButton/> : null}
            </div>
        </div>
    );
}


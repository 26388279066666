import './ErrorMessage.css';
import React from "react";
import {ErrorCode} from "../../api/ApiError";

interface ErrorMessageProps {
    errorCode?: ErrorCode | undefined;
    errorMessage?: string | undefined;
    smallMarginTop?: boolean;
}

export const ErrorMessage = (props: ErrorMessageProps) => {
    let errorMessage = getErrorMessage();
    return (
        <React.Fragment>
            {errorMessage}
        </React.Fragment>
    )

    function getErrorMessage() {
        let classes = "errorMessage";
        if (props.smallMarginTop) {
            classes += " smallMarginTop";
        } else {
            classes += " marginTop";
        }

        if (props.errorMessage !== undefined) {
            return (
                <div className={classes}>
                    <p>{props.errorMessage}</p>
                </div>
            )
        }

        if (!props.errorCode) {
            return (
                <React.Fragment/>
            )
        }

        if (props.errorCode === ErrorCode.DistributingOfEntireLibraryOngoing) {
            return (
                <div className={classes}>
                    <p>Your liked songs are currently being added to playlists in Spotify. Please wait until this is finished before retrying.</p>
                </div>
            );
        } else if (props.errorCode === ErrorCode.PremiumDistributionNotPermittedYet) {
            return (
                <div className={classes}>
                    <p>Unfortunately, this functionality is only available every 30 seconds.</p>
                    <p>Try again in a little while.</p>
                </div>
            );
        } else if (props.errorCode === ErrorCode.DistributionNotPermittedYet) {
            return (
                <div className={classes}>
                    <p>Unfortunately, this functionality is only available every 30 seconds.</p>
                    <p>Try again in a little while.</p>
                </div>
            );
        } else if (props.errorCode === ErrorCode.ExceededNumberOfPlaylistPreferences) {
            return (
                <div className={classes}>
                    <p>Unfortunately, at most 15 playlists can be created.</p>
                </div>
            );
        } else if (props.errorCode === ErrorCode.PlaylistTypeDoesntExist) {
            return (
                <div className={classes}>
                    <p>One of the chosen genres or decades is not known in the system.</p>
                </div>
            );
        } else if (props.errorCode === ErrorCode.PlaylistNameReserved) {
            return (
                <div className={classes}>
                    <p>The name of the playlist is reserved, please choose another one.</p>
                </div>
            );
        } else if (props.errorCode === ErrorCode.DuplicatePlaylistName) {
            return (
                <div className={classes}>
                    <p>You already have a playlist with the chosen name, please choose another one.</p>
                </div>
            );
        } else if (props.errorCode === ErrorCode.UserDeletedRecently) {
            return (
                <div className={classes}>
                    <p>You deleted your data and logged in again too recently. Wait a little while before attempting again.</p>
                </div>
            );
        } else if (props.errorCode === ErrorCode.ProvidedTooMuchFeedback) {
            return (
                <div className={classes}>
                    <p>We are very thankful for all your feedback, but unfortunately you are unable to provide anymore.</p>
                </div>
            );
        } else if (props.errorCode === ErrorCode.FeatureNotAvailableForCurrentMembershipLevel) {
            return (
                <div className={classes}>
                    <p>This feature is only available for premium users.</p>
                </div>
            );
        } else if (props.errorCode === ErrorCode.PlaylistDeletionOngoing) {
            return (
                <div className={classes}>
                    <p>Playlists are currently being deleted in Spotify. Please wait until this is finished before retrying.</p>
                </div>
            );
        } else if (props.errorCode === ErrorCode.ExceededNumberOfPlaylistPreferencesPremium) {
            return (
                <div className={classes}>
                    <p>Unfortunately, at most 100 playlists can be created.</p>
                </div>
            );
        } else if (props.errorCode === ErrorCode.AnalysisOngoing) {
            return (
                <div className={classes}>
                    <p>An analysis is currently going on of your songs. Please wait until this is finished.</p>
                </div>
            );
        } else if (props.errorCode === ErrorCode.AnalysisStartedTooRecently) {
            return (
                <div className={classes}>
                    <p>Unfortunately, this functionality is only available every 20 seconds. Try again in a little while.</p>
                </div>
            );
        } else {
            return (
                <div className={classes}>
                    <p>Unknown error.</p>
                </div>)
        }
    }
}
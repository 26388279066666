import React, {useState} from "react";
import {disableStatistics, enableStatistics} from "../../api/admin/StatisticsApi";
import {formatDate} from "../../util/Dates";
import {SmallerConfirmationButton, SmallestWarningButton, WarningButton} from "../common/Buttons";
import './UserStatisticsManagement.css';

interface UserStatisticsManagementProps {
    enabledUserIds: string[];
    userStateChanged: () => void;
}

const UserStatisticsManagement = (props: UserStatisticsManagementProps) => {
    const [userId, setUserId] = useState<string>("");
    
    let userRows = props.enabledUserIds.map((userId: string) => <UserRow userId={userId} disableStatistics={disableStatisticsClick} key={userId}/>)

    return (
        <div>
            <h3>User statistics management</h3>
            <h4>Enable</h4>
            <div>
                <input className="userIdStatisticsInput" placeholder="User Id" onChange={(evt) => userIdInputChanged(evt)} type="text" value={userId}/>
            </div>
            <SmallerConfirmationButton onClick={enableStatisticsClick} text={"Enable"}/>
            <h4>Disable</h4>
            <table>
                <thead>
                <tr>
                    <th>User Id</th>
                    <th>Disable</th>
                </tr>
                </thead>
                <tbody>
                {userRows}
                </tbody>
            </table>
        </div>
    )

    function enableStatisticsClick() {
        enableStatistics(userId)
            .then(props.userStateChanged)
    }
    
    function disableStatisticsClick(userId: string) {
        disableStatistics(userId)
            .then(props.userStateChanged)
    }

    function userIdInputChanged(e: React.FormEvent<HTMLInputElement>) {
        setUserId(e.currentTarget.value);
    }
}

interface UserRowProps {
    userId: string;
    disableStatistics: (userId: string) => void;
}

const UserRow = (props: UserRowProps) => {
    return (
        <tr>
            <td>{props.userId}</td>
            <td><SmallestWarningButton onClick={() => props.disableStatistics(props.userId)} center={true} text={"Disable"} /></td>
        </tr>
    )
}

export default UserStatisticsManagement;
import React, {useEffect, useState} from "react";
import {distributionProgress} from '../../../api/distributor/DistributeApi';
import {DistributionProgress} from "../../../api/distributor/DistributionProgress";
import {useStateWithCallbackLazy} from 'use-state-with-callback';

import './Progress.css';

interface ProgressProps {
    progressId: string | null | undefined;
    distributionCompletedCallback?: Function;
    cancelled?: boolean;
    cancellationInProgress?: boolean;
}

interface WorkProgress {
    totalWork: number;
    completedWork: number;
}

const Progress = (props: ProgressProps) => {
    const [finished, setFinished] = useState<boolean>();
    const [tracksProgress, setTracksProgress] = useState<WorkProgress | undefined>(undefined);
    const [randomizedPlaylistsProgress, setRandomizedPlaylistsProgress] = useState<WorkProgress | undefined>(undefined);
    const [tickCount, setTickCount] = useState<number>(0);
    const [numberOfFetchingErrors, setNumberOfFetchingErrors] = useState<number>(0);

    function increaseTick() {
        setTickCount((prevState: number) => prevState + 1);
    }

    useEffect(() => {
            if (props.cancelled === true) {
                setRandomizedPlaylistsProgress(undefined);
                setTracksProgress(undefined);
                return;
            }

            if (props.progressId === null || props.progressId === undefined) {
                return;
            }

            if (finished) {
                return;
            }

            distributionProgress(props.progressId)
                .then((distributionProgress: DistributionProgress) => {
                    let standardProgress = distributionProgress.progress.filter(progress =>
                        progress.distributionType === "Standard" ||
                        progress.distributionType === "Analysis");
                    if (standardProgress.length === 1) {
                        setTracksProgress({
                            totalWork: standardProgress[0].totalWork,
                            completedWork: standardProgress[0].completedWork
                        });
                    }

                    let randomizedProgress = distributionProgress.progress.filter(progress => progress.distributionType === "Randomized");
                    if (randomizedProgress.length === 1) {
                        setRandomizedPlaylistsProgress({
                            totalWork: randomizedProgress[0].totalWork,
                            completedWork: randomizedProgress[0].completedWork
                        });
                    }

                    if (distributionProgress.progress.length === 0 && tickCount >= 6) {
                        setFinished(true);
                        return;
                    }

                    setFinished(distributionProgress.finished && tracksProgress !== undefined);
                }).catch(res => {
                setNumberOfFetchingErrors(numberOfFetchingErrors + 1);
            });
        }, [tickCount]
    )

    useEffect(() => {
        if (props.distributionCompletedCallback !== undefined && finished === true) {
            props.distributionCompletedCallback();
        }
    }, [finished])

    useEffect(() => {
        const timer = setInterval(() => {
            if (finished === true) {
                clearInterval(timer);
                return;
            }
            increaseTick()
        }, 1500);
        return () => clearInterval(timer)
    });

    if (props.progressId === null) {
        return (
            <React.Fragment/>
        );
    }

    if (props.cancelled === true) {
        return (
            <React.Fragment>
                <div className="progressBarContainer">
                    <div className="progressBar progressWarning">
                        <div className="progressText">Cancelled</div>
                    </div>
                </div>
            </React.Fragment>)
    }

    if (props.cancellationInProgress === true) {
        return (
            <React.Fragment>
                <div className="progressBarContainer">
                    <div className="progressBar">
                        <div className="progressText">Cancelling</div>
                    </div>
                </div>
            </React.Fragment>)
    }

    if (numberOfFetchingErrors > 10) {
        return (
            <React.Fragment>
                <div className="progressBarContainer">
                    <div className="progressBar failed">
                        <div className="progressText">Loading failed</div>
                    </div>
                </div>
            </React.Fragment>)
    }

    let progressBarProps = GetProgressBarProps();
    if (progressBarProps !== undefined) {
        return <ProgressBar progressText={progressBarProps.progressText} completedWork={progressBarProps.completedWork} totalWork={progressBarProps.totalWork}/>;
    }

    return <LoadingProgressBar tickCount={tickCount}/>

    function GetProgressBarProps(): ProgressBarProps | undefined {
        if (tracksProgress !== undefined && tracksProgress.totalWork !== tracksProgress.completedWork) {
            return {
                totalWork: tracksProgress.totalWork,
                completedWork: tracksProgress.completedWork,
                progressText: "songs processed"
            }
        }
        if (randomizedPlaylistsProgress !== undefined && randomizedPlaylistsProgress.totalWork != randomizedPlaylistsProgress.completedWork) {
            return {
                totalWork: randomizedPlaylistsProgress.totalWork,
                completedWork: randomizedPlaylistsProgress.completedWork,
                progressText: "randomized playlists"
            }
        }

        // Både randomized og normal er færdige
        if (tracksProgress != undefined) {
            return {
                totalWork: tracksProgress.totalWork,
                completedWork: tracksProgress.completedWork,
                progressText: "songs processed"
            }
        }

        if (randomizedPlaylistsProgress != undefined) {
            return {
                totalWork: randomizedPlaylistsProgress.totalWork,
                completedWork: randomizedPlaylistsProgress.completedWork,
                progressText: "randomized playlists"
            }
        }
    }
}

interface LoadingProgressBarProps {
    tickCount: number;
}

const LoadingProgressBar = (props: LoadingProgressBarProps) => {
    let numberOfDots = props.tickCount % 2 + 2;
    let progressText = `Loading progress ${".".repeat(numberOfDots)}`
    return (
        <React.Fragment>
            <div className="progressBarContainer">
                <div className="progressBar">
                    <div className="progressText">{progressText}</div>
                </div>
            </div>
        </React.Fragment>
    )
}

interface ProgressBarProps {
    totalWork: number;
    completedWork: number;
    progressText: string;
}

const ProgressBar = (props: ProgressBarProps) => {
    let totalWork = props.totalWork;
    let completedWork = props.completedWork;

    // -1 indicates that the progress had failed
    let progressFailed = completedWork === -1;
    
    let width = completedWork / totalWork;
    let progressBarClasses = "progressBar";
    let currentProgressClasses = "currentProgress"
    if (width === 1) {
        currentProgressClasses += " finishedCurrentProgress"
    } else if (progressFailed) {
        progressBarClasses += " failed";
    } else {
        currentProgressClasses += " inProgressCurrentProgress"
    }

    let progressBarText = "";
    if (!progressFailed) {
        progressBarText = `${completedWork} / ${totalWork} ${props.progressText}`;
    } else {
        progressBarText = "Unfortunately, Spotify appears to have issues currently. Please try again later.";
    }

    return (
        <React.Fragment>
            <div className="progressBarContainer">
                <div className={progressBarClasses}>
                    <span style={{flex: width}} className={currentProgressClasses}></span>
                    <div className="progressText">{progressBarText}</div>
                </div>
            </div>
        </React.Fragment>
    );
}


export default Progress;
import React from "react";
import './Groups.css';
import {useAuthorizationContext} from "../../../context/authorizationContext";
import {PlaylistRow, PlaylistTable} from "./Groups";
import {GoToLoginButton} from "../../common/Buttons";

export const Decade = () => {
    const {loggedIn} = useAuthorizationContext();
    return (
        <div>
            <div className="group pageContentSegment">
                <h1>Decade playlists</h1>
                <p>Liked Songs Manager supports creating playlists from specific decades.</p>
                <PlaylistTable>
                    <PlaylistRow name={"1940s"} availableFree={true}/>
                    <PlaylistRow name={"1950s"} availableFree={true}/>
                    <PlaylistRow name={"1960s"} availableFree={true}/>
                    <PlaylistRow name={"1970s"} availableFree={true}/>
                    <PlaylistRow name={"1980s"} availableFree={true}/>
                    <PlaylistRow name={"1990s"} availableFree={true}/>
                    <PlaylistRow name={"2000s"} availableFree={true}/>
                    <PlaylistRow name={"2010s"} availableFree={true}/>
                    <PlaylistRow name={"2020s"} availableFree={true}/>
                </PlaylistTable>
                {!loggedIn ? <GoToLoginButton/> : null}
            </div>
        </div>
    );
}


const DataDeleted = () => {
    return (
        <div>
            <div className="pageContentSegment">
                <h2>Data deleted</h2>
                <p>Your data has been deleted. To use the application, you need to login again.</p>
                <p>If you login again, you will not be able to create playlists for the next two minutes due to performance considerations.</p>
            </div>
        </div>
    )
}

export default DataDeleted;
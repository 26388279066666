import React from "react";
import './Groups.css';
import {useAuthorizationContext} from "../../../context/authorizationContext";
import {GetAccessWithPremiumButton, PlaylistRow, PlaylistTable} from "./Groups";
import {MembershipLevel, useUserContext} from "../../../context/userContext";

export const LikedAt = () => {
    const {loggedIn} = useAuthorizationContext();
    const {membershipLevel} = useUserContext();
    
    const showPremiumButton = loggedIn === false || membershipLevel !== MembershipLevel.PremiumYearly;
        
    return (
        <div>
            <div className="group pageContentSegment">
                <h1>Liked At playlists</h1>
                <p>Liked Songs Manager lets you create playlists based on when you liked them.</p>
                <PlaylistTable>
                    <PlaylistRow name={"Liked in Month"} availableFree={false}/>
                    <PlaylistRow name={"Liked in Month - Starting From Current Month"} availableFree={false}/>
                    <PlaylistRow name={"Liked in Season - Northern Hemisphere"} availableFree={false}/>
                    <PlaylistRow name={"Liked in Season - Southern Hemisphere"} availableFree={false}/>
                    <PlaylistRow name={"Liked in Year"} availableFree={false}/>
                </PlaylistTable>
                {showPremiumButton ? <GetAccessWithPremiumButton/> : null}
            </div>
        </div>
    );
}


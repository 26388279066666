import React from "react";

export const Terms = () => {
    return (
        <div className="pageContentSegment">
            <h1>Terms of Service</h1>
            <i>Last modified: 22nd October 2023.</i>
            <p>From everyone at Holdsoft Solutions, thank you for using our services! We build them to help you do your best work. There are thousands of people using Liked Songs
                Manager. Because we don't know every one of our customers personally, we have to put in place some Terms of Service to help keep the ship afloat.
            </p>
            <p>When we say "Company", "we", "our", or "us" in this document, we are referring to Holdsoft Solutions registered in The Central Business Register (CVR)
                in Denmark
                under the CVR number 44189631 and having its registered office in Aarhus N (8200), Denmark.</p>
            <p>When we say "Services", we mean our websites, including likedsongsmanager.com and any product created and maintained by Holdsoft Solutions. That includes Liked
                Songs Manager and its free and paid subscriptions whether delivered within a web browser, desktop application, mobile application, or another format.</p>
            <p>When we say "You" or "your", we are referring to the people that own an account with Liked Songs Manager. Liked Songs Manager accounts are owned by
                individuals, not by organizations. When you sign in using the "Start om with Spotify" button and create a Liked Songs Manager account, you are the owner of that
                account and all the data in it. A new Liked Songs Manager account will only be created if one doesn't already exist for the Spotify Account that you sign in with.
            </p>
            <p>We may update these Terms of Service ("Terms") in the future at any time without notice. We therefore recommend that you refer to the Terms before any new
                subscription or renewal
                of the subscription. Whenever we make a significant change to our policies, we will refresh the date at the top of this page.</p>
            <p>When you use our Services, now or in the future, you are agreeing to the latest Terms. There may be times where we do not exercise or enforce a right or provision of
                the Terms; however, that does not mean we are waiving that right or provision. <b>These Terms do contain a limitation of our liability.</b></p>
            <p>If you violate any of the Terms, we may terminate your account. That's a broad statement and it means you need to place a lot of trust in us. We do our best to
                deserve that trust by being open about who we are and keeping an open door to <a href="mailto:support@likedsongsmanager.com">contact us</a>.
            </p>
            <p>Any professional use of the Services is prohibited. The Services are only available for private use and non-professional customers.</p>
            <p>You declare that you have the legal capacity to enter into this agreement, that you are of legal age and that you are not under tutorship or guardianship.</p>
            <h2>Account Terms</h2>
            <ol>
                <li>You are responsible for maintaining the security of your account. The Company cannot and will not be liable for any loss or damage from your failure to comply
                    with this security obligation.
                </li>
                <li>You are responsible for all content posted to and activity that occurs under your account, including content posted by and activity of any users in your
                    account.
                </li>
                <li>You must be a human. Accounts registered by "bots" or other automated methods are not permitted.</li>
            </ol>
            <h2>Payment and Refunds</h2>
            <ol>
                <li>If you are using a free version of one of our Services, it is really free: we do not ask you for your credit card and — just like for customers who pay for our
                    Services — we do not sell your data.
                </li>
                <li>In addition to the Free plan of Liked Songs Manager we offer a Premium subscription billed anually. The order process of the Premium
                    subscription is handled by our online reseller Paddle. Paddle is the Merchant of Record for all our orders who also handles all order-related inquiries and
                    returns. Your Premium subscription will be active after successful payment when Paddle notifies Liked Songs Manager hereof.
                    At the end of the annual period, the Premium subscription is automatically renewed for a term identical to the originally purchased
                    unless cancelled. If Paddle is unable to renew your Premium subscription for any reason, we will cancel your Premium subscription and you will be returned to
                    the Free
                    subscription. For more information about the Paddle order
                    process and your rights as a customer, please refer to <a
                        href={"https://www.paddle.com/legal/invoiced-consumer-terms"}>Paddle's
                        Terms & Conditions</a> and <a href={"https://www.paddle.com/legal/privacy"}>Privacy Policy</a>.
                </li>
                <li>The fees are set out in our <a href={"/plans"}>Plans page</a>.</li>
                <li>All prices are in the shown currency on likedsongsmanager.com. All fees are inclusive of VAT, but are exclusive of levies, or duties imposed by taxing
                    authorities. Where required, our reseller Paddle will collect VAT on behalf of the taxing authority and remit those taxes to taxing authorities.
                    Otherwise, you are responsible for payment of all taxes, levies, or duties.
                </li>
                <li>We process refunds according to our <a href={"/policies/refund-policy"}>Fair Refund policy</a>.</li>
                <li>For any other inquiries regarding payments of a paid subscription, please refer to <a href={"https://www.paddle.com/legal/invoiced-consumer-terms"}>Paddle's
                    Terms & Conditions</a>.
                </li>
            </ol>
            <h2>Cancellation and Termination</h2>
            <ol>
                <li>You are solely responsible for properly canceling your Premium subscription. You can find
                    instructions for how to cancel your Premium subscription in our <a href={"/policies/cancellation-policy"}>Cancellation policy</a>. An email or phone
                    request
                    to
                    cancel your Premium subscription is not automatically considered cancellation. If you need help canceling your Premium subscription, you can always <a
                        href="mailto:support@likedsongsmanager.com">contact our support team</a>.
                </li>
                <li>If you cancel the Premium subscription at least 72 hours before the end of your current paid up year, your cancellation will take effect immediately, and you
                    will not be charged
                    again. However, you will still keep your Premium advantages for the remainder of the subscription period. Moreover, no refund will be issued for the any time
                    you may have left. See our <a
                        href={"/policies/refund-policy"}>Fair
                        Refund policy</a> for more details.
                </li>
                <li>We have the right to suspend or terminate your account and refuse any and all current or future use of our Services for any reason at any time. Suspension means
                    you will not be able to access the account or any content in the account. Termination will furthermore result in the
                    deletion of your account or your access to your account, and the forfeiture and relinquishment of all content in your account. We also reserve the right to
                    refuse the use of the Services to anyone for any reason at any time. There are some things we staunchly stand against and this clause is how we exercise
                    that stance.
                </li>
                <li>We have the right to terminate your account and refund the fee for your current subscription term, if Spotify introduces a fee for the usage of their API.</li>
                <li>Verbal, physical, written or other abuse (including threats of abuse or retribution) of a Company employee or officer will result in immediate account
                    termination.
                </li>
            </ol>
            <h2>Modifications to the Service and Prices</h2>
            <ol>
                <li>Sometimes it becomes technically impossible to continue a feature or we redesign a part of our Services because we think it could be better or we decide to
                    close
                    new signups of a product. We reserve the right at any time to modify or discontinue, temporarily or permanently, any part of our Services with or without
                    notice.
                </li>
                <li>Sometimes we change the pricing structure for our products. When we do that, we tend to exempt existing customers from those changes. However, we may choose
                    to
                    change the prices for existing customers. If we do so, we will give at least 30 days notice. We may also
                    post a notice about changes on our websites or the affected Services themselves.
                </li>
            </ol>
            <h2>Uptime, Security, and Privacy</h2>
            <ol>
                <li>Your use of the Services is at your sole risk. We provide these Services on an "as is" and "as available" basis. We do not offer service-level agreements
                    for any of our Services, but do take uptime of our applications seriously.
                </li>
                <li>Liked Songs Manager is dependent on Spotify's application programming interface (API), as well as data served from Spotify in general. We
                    cannot
                    guarentee that Spotify's API and data will accessible on a permanent basis. Temporary and final interruption in access to Spotify's API and data will not give
                    rise to any refund of the subscription fees.
                </li>
                <li>We reserve the right to temporarily disable your account if your usage significantly exceeds the average usage of other customers of the Services. Of
                    course, we'll reach out to the account owner before taking any action except in rare cases where the level of use may negatively impact the performance of
                    the Service for other customers.
                </li>
                <li>We take many measures to protect and secure your data through backups and encryption. We enforce encryption for data transmission from the
                    public Internet. There are some edge cases where we may send your data through our network unencrypted.
                </li>
                <li>When you use our Services, you entrust us with your data. We take that trust to heart. You agree that Holdsoft Solutions may process your data as described
                    in our <a href={"/policies/privacy-policy"}>Privacy Policy</a> and for no other purpose. We as humans can access your data for the following
                    reasons:
                    <ul>
                        <li><b>To help you with support requests you make.</b> We'll ask for express consent before accessing your account.</li>
                        <li><b>On the rare occasions when an error occurs that stops an automated process partway through.</b> When we can fix the issue and restart automated
                            processing without looking at any personal data, we do. In rare cases, we have to look at a minimum amount of personal data to fix the issue. In these
                            rare cases, we aim to fix the root cause to prevent the errors from recurring.
                        </li>
                        <li><b>To safeguard Holdsoft Solutions.</b> We'll look at logs and metadata as part of our work to ensure the security of your data and the Services as a
                            whole.
                        </li>
                        <li><b>To the extent required by applicable law.</b> Our policy is to not respond to government requests for user data unless we are compelled by legal
                            process or in limited circumstances in the event of an emergency request. If Holdsoft Solutions is audited by a tax authority, we only share the bare
                            minimum billing information needed to complete the audit.
                        </li>
                    </ul>
                </li>
                <li>We use third party vendors and hosting partners to provide the necessary hardware, software, networking, storage, and related technology required to run the
                    Services. You can see a list of all subprocessors who handle personal data in the section "When we access or disclose your information" in our <a
                        href={"/policies/privacy-policy"}>Privacy Policy</a>.
                </li>
            </ol>
            <h2>Copyright and Content Ownership</h2>
            <ol>
                <li>All content posted on the Services must comply with Danish copyright law (Ophavsretsloven).</li>
                <li>We do not pre-screen content, but we reserve the right (but not the obligation) in our sole discretion to refuse or remove any content that is available via the
                    Service.
                </li>
                <li>The Company or its licensors own all right, title, and interest in and to the Services, including all intellectual property rights therein, and you obtain no
                    ownership rights in the Services as a result of your use. You may not duplicate, copy, or reuse any portion of the HTML, CSS, JavaScript, or visual design
                    elements without express written permission from the Company. You must request permission to use the Company's logos or any Service logos for promotional
                    purposes. Please <a href="mailto:support@likedsongsmanager.com">email us</a> requests to use logos. We reserve the right to rescind any permissions if you violate
                    these Terms.
                </li>
                <li>You agree not to reproduce, duplicate, copy, sell, resell, decompile, modify, disassemble, reverse-engineer or otherwise exploit any portion of the Services,
                    use of the
                    Services, or access to the Services without the express written permission of the Company.
                </li>
            </ol>
            <h2>Features and Bugs</h2>
            <p>We design our Services with care, based on our own experience and the experiences of customers who share their time and feedback. However, there is no such thing as
                a service that pleases everybody. We make no guarantees that our Services will meet your specific requirements or expectations.
            </p>
            <p>We also test all of our features extensively before shipping them. As with any software, our Services inevitably have some bugs. We track the bugs reported to us and
                work through priority ones, especially any related to security or privacy. Not all reported bugs will get fixed and we don't guarantee completely error-free
                Services.
            </p>
            <h2>Liability</h2>
            <p>We mention liability throughout these Terms but to put it all in one section:</p>
            <p><b><i>You expressly understand and agree that the Company shall not be liable, in law or in equity, to you or to any third party for any direct, indirect,
                incidental, lost profits, special, consequential, punitive or exemplary damages, including, but not limited to, damages for loss of profits, goodwill, use, data or
                other intangible losses (even if the Company has been advised of the possibility of such damages), resulting from: (i) the use or the inability to use the Services;
                (ii) the cost of procurement of substitute goods and services resulting from any goods, data, information or services purchased or obtained or messages received or
                transactions entered into through or from the Services; (iii) unauthorized access to or alteration of your transmissions or data; (iv) statements or conduct of any
                third party on the service; (v) or any other matter relating to these Terms or the Services, whether as a breach of contract, tort (including negligence whether
                active or passive), or any other theory of liability.
            </i></b></p>
            <p>In other words: choosing to use our Services does mean you are making a bet on us. If the bet does not work out, that's on you, not us. We do our darnedest to be as
                safe a bet as possible through careful management of the business; investments in security, infrastructure, and talent; and in general giving a damn. If you choose
                to use our Services, thank you for betting on us.
            </p>
            <h2>Severability</h2>
            <p>If a court of competent jurisdiction finds any clause or term of these Terms to be invalid, unenforceable, or illegal, then the remaining terms and provisions of
                these Terms shall be deemed to be severable therefrom and shall continue in full force.</p>
            <h2>Governing Law</h2>
            <p>The interpretation and enforcement of these Terms shall be governed by the laws of Denmark. You agree that any claim or dispute you may have against Holdsoft
                Solutions must be resolved by a court located in Denmark.
            </p>
            <h2>International</h2>
            <p>You agree to comply with all local rules of your country regarding online Conduct and acceptable Content. Please note that information published in the Services may
                refer to products, programs or services that are not available in your country.
            </p>
            <h2>Other</h2>
            <p>Spotify is a third party beneficiary of these Terms and our <a href={"/policies/privacy-policy"}>Privacy Policy</a> and are entitled to directly enforce
                these Terms.</p>
            <h2>Contact</h2>
            <p>If you have a question about any of these Terms, please <a href="mailto:support@likedsongsmanager.com">contact our Support team</a>.</p>
            <p>Holdsoft Solutions</p>
            <p>A.H. Winges Vej 3, st. tv.</p>
            <p>8200 Aarhus N</p>
            <p>Denmark</p>
            <p>Adapted from the <a href="https://github.com/basecamp/policies">Basecamp open-source policies</a> / <a href="https://creativecommons.org/licenses/by/4.0/">CC BY
                4.0</a></p>
        </div>
    )
}
import Urls from "../../util/Urls";
import {getReferrer} from "../../util/Referrer";

const paths = {
    authorize: () => {
        let referrer = getReferrer();
        if (referrer !== null) {
            return `${Urls.backend}/api/authorize?referrer=${encodeURIComponent(referrer)}`
        } else {
            return `${Urls.backend}/api/authorize`
        }
    },
    authorizeFromPlans: () => {
        let referrer = getReferrer();
        if (referrer !== null) {
            return `${Urls.backend}/api/authorize/plans?referrer=${encodeURIComponent(referrer)}`
        } else {
            return `${Urls.backend}/api/authorize/plans`
        }
    },
    isLoggedIn: '/api/authorize/logged-in',
    logout: '/api/authorize/logout'
}

export const isLoggedIn = (): Promise<boolean> => {
    return fetch(paths.isLoggedIn)
        .then(async response => {
            if (response.ok === false)
                return false;
            return true;
        });
}

export const logout = () => {
    return fetch(paths.logout, {
        method: 'POST'
    });
}

export default paths;
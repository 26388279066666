export const PaddleTimedOut = () => {
    return (
        <div id="pageContainer">
            <div id="pageContent">
                <div className="pageContentSegment">
                    <h1>Plans currently unavailable</h1>
                    <p>Unfortunately, our reseller Paddle.com is currently down.</p>
                    <p>Please try again later.</p>
                </div>
            </div>
        </div>
    )
}
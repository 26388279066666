import {changeMembershipLevelBySpotifyId, changeMembershipLevelByUserId, getUserDetailsBySpotifyId, getUserDetailsByUserId} from "../../api/admin/UserApi";
import {useParams, useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {UserDetailsResponse} from "../../api/admin/UserDetailsResponse";
import NotFoundSegment from "../notfound/NotFoundSegment";
import {formatDate, parseDate} from "../../util/Dates";
import {SmallerConfirmationButton} from "../common/Buttons";
import {ErrorMessage} from "../common/ErrorMessage";

const UserDetails = () => {
    const [searchParams] = useSearchParams();
    const [userDetails, setUserDetails] = useState<UserDetailsResponse>();
    const [membershipLevelUserId, setMembershipLevelUserId] = useState<string>("PremiumYearly");
    const [errorMessage, setErrorMessage] = useState<string>();
    const [successMessage, setSuccessMessage] = useState<string>();

    let userId = searchParams.get("userId");
    let spotifyId = searchParams.get("spotifyId");

    useEffect(() => {
        if (userId !== null) {
            getUserDetailsByUserId(userId!)
                .then((userDetails: UserDetailsResponse) => {
                    setUserDetails(userDetails);
                });
            return;
        }
        if (spotifyId !== null) {
            getUserDetailsBySpotifyId(spotifyId!)
                .then((userDetails: UserDetailsResponse) => {
                    setUserDetails(userDetails);
                });
            return;
        }
    }, []);

    if (userDetails === undefined) {
        return (
            <NotFoundSegment/>
        )
    }

    if (userId === undefined) {
        return (
            <NotFoundSegment/>
        )
    }

    let isPremium = userDetails.membershipEvents[0].membershipLevel === "PremiumYearly";
    let headerClasses = isPremium ? "premiumText" : "";
    return (
        <div>
            <div className="pageContentSegment">
                <h2 className={headerClasses}>User {userDetails.user.userId} ({userDetails.user.spotifyUserId}) {isPremium && <span>⭐</span>}</h2>
                <h3>Monitored</h3>
                <p>{userDetails.isMonitored ? "Yes" : "No"}</p>
                <h3>Playlists</h3>
                <p>Number of tracks: {userDetails.numberOfLikedTracks}</p>
                <table>
                    <thead>
                    <tr className={"bordered"}>
                        <th>Playlist name</th>
                        <th>Spotify id</th>
                        <th>Created at</th>
                        <th>Number of tracks</th>
                        <th>Number of tracks stored</th>
                        <th>Latest settled track liked at</th>
                        <th>Latest track track added at</th>
                    </tr>
                    </thead>
                    <tbody>
                    {userDetails.playlists.map(playlist => {
                        return (
                            <tr className={"bordered"} key={playlist.spotifyPlaylistId}>
                                <td>{playlist.playlistName}</td>
                                <td>{playlist.spotifyPlaylistId}</td>
                                <td>{formatDate(parseDate(playlist.createdAt))}</td>
                                <td>{playlist.numberOfTracks}</td>
                                <td>{playlist.numberOfTracksStored}</td>
                                <td>{formatDate(parseDate(playlist.latestSettledTrackLikedAt))}</td>
                                <td>{formatDate(parseDate(playlist.latestTrackAddedAt))}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                <h3>Preferences</h3>
                <h4>Simple</h4>
                <table>
                    <thead>
                    <tr className={"bordered"}>
                        <th>Playlist name</th>
                        <th>Preference date</th>
                    </tr>
                    </thead>
                    <tbody>
                    {userDetails.simplePreferences.map(simplePreference => {
                        return (
                            <tr className={"bordered"} key={simplePreference.playlistName}>
                                <td>{simplePreference.playlistName}</td>
                                <td>{formatDate(parseDate(simplePreference.preferenceDate))}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                <h4>Combinatorial</h4>
                <table>
                    <thead>
                    <tr className={"bordered"}>
                        <th>Playlist name</th>
                        <th>Preference date</th>
                        <th>Combination expression</th>
                    </tr>
                    </thead>
                    <tbody>
                    {userDetails.combinatorialPreferences.map(combinationPreference => {
                        return (
                            <tr className={"bordered"} key={combinationPreference.playlistName}>
                                <td>{combinationPreference.playlistName}</td>
                                <td>{combinationPreference.combinationExpression}</td>
                                <td>{formatDate(parseDate(combinationPreference.preferenceDate))}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                <h3>Payments</h3>
                <table>
                    <thead>
                    <tr className={"bordered"}>
                        <th>Change</th>
                        <th>Effective date</th>
                        <th>Registered date</th>
                        <th>Paddle notification id</th>
                        <th>Paddle customer id</th>
                        <th>Paddle subscription id</th>
                    </tr>
                    </thead>
                    <tbody>
                    {userDetails.payments.map(payment => {
                        return (
                            <tr className={"bordered"} key={payment.paddleNotificationId}>
                                <td>{payment.change}</td>
                                <td>{formatDate(parseDate(payment.effectiveDate))}</td>
                                <td>{formatDate(parseDate(payment.registeredDate))}</td>
                                <td>{payment.paddleNotificationId}</td>
                                <td>{payment.paddleCustomerId}</td>
                                <td>{payment.paddleSubscriptionId}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                <h3>Membership events</h3>
                <table>
                    <thead>
                    <tr className={"bordered"}>
                        <th>Membership level</th>
                        <th>Effective date</th>
                    </tr>
                    </thead>
                    <tbody>
                    {userDetails.membershipEvents.map(membershipEvent => {
                        return (
                            <tr className={"bordered"} key={membershipEvent.effectiveDate}>
                                <td>{membershipEvent.membershipLevel}</td>
                                <td>{formatDate(parseDate(membershipEvent.effectiveDate))}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                <h3>Activity</h3>
                <table>
                    <thead>
                    <tr className={"bordered"}>
                        <th>Kind</th>
                        <th>Time</th>
                    </tr>
                    </thead>
                    <tbody>
                    {userDetails.activity.reverse().map(activity => {
                        return (
                            <tr className={"bordered"} key={activity.kind + activity.time}>
                                <td>{activity.kind}</td>
                                <td>{formatDate(parseDate(activity.time))}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
            <div className="pageContentSegment">
                <h3>Change membership level</h3>
                <div>
                    <select className="userIdStatisticsInput" value={membershipLevelUserId} onChange={(event) => setMembershipLevelUserId(event.target.value)}>
                        <option>PremiumYearly</option>
                        <option>FreeBeforePremiumIntroduced</option>
                        <option>Free</option>
                    </select>
                    <SmallerConfirmationButton onClick={handleClickChangeMembershipLevelByUserId} text={"Change"}/>
                </div>

                <ErrorMessage errorMessage={errorMessage}/>
                {successMessage}
            </div>
        </div>
    )

    function handleClickChangeMembershipLevelByUserId() {
        setErrorMessage(undefined);
        setSuccessMessage(undefined);

        changeMembershipLevelByUserId(userDetails!.user.userId, membershipLevelUserId)
            .then(() => setSuccessMessage("Membership updated"))
            .catch(() => setErrorMessage("Membership change did not succeed"))
    }
}

export default UserDetails;
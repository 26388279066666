import './PageSegmentLoading.css';

const ComplexPageSegmentLoading = () => {
    return (
        <div id="pageContainer">
            <div id="pageContent">
                <div className="pageContentSegment loading">
                </div>
            </div>
        </div>
    )
}

export default ComplexPageSegmentLoading;
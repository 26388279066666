import {createContext, useContext} from "react"

export type UserData = {
    membershipLevel: MembershipLevel | undefined;
    setMembershipLevel: (value: MembershipLevel) => void;
}
export const UserContext = createContext<UserData>({
    membershipLevel: undefined,
    setMembershipLevel: (value: MembershipLevel) => {
    }
})

export enum MembershipLevel {
    Free = "Free",
    FreeBeforePremiumIntroduced = "FreeBeforePremiumIntroduced",
    PremiumYearly = "PremiumYearly",
}

export const useUserContext = () => useContext(UserContext)
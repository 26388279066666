import '../ApiError';
import {ApiError} from '../ApiError';
import {HandleErrorResponse} from '../HandleErrorResponse';
import {CreateCombinatorialPlaylistRequest} from './CreateCombinatorialPlaylistRequest';
import {DistributionProgress} from './DistributionProgress';
import {DistributionResponse} from './DistributionResponse';
import exp from "constants";
import {AnalysisResponse, AnalysisResultsResponse} from "./AnalysisResponse";

const paths = {
    redistribute: '/api/distributor/redistribute',
    distributeHistorically: '/api/distributor/distribute-historically',
    distributionProgress: (distributionProgressId: string) => `/api/distributor/${distributionProgressId}/progress`,
    combinatorial: (combinatorialPreferenceId: string) => `/api/distributor/combinatorial/${combinatorialPreferenceId}`,
    predefined: (preference: string) => `/api/distributor/predefined/${preference}`,
    createCombinatorial: `/api/distributor/combinatorial`,
    analyse: `/api/distributor/analysis`,
    analysisResults: (progressId: string, afterSegmentNumber: number | undefined) => {
        let path = `/api/distributor/analysis/${progressId}`
        if (afterSegmentNumber === undefined) {
            return path;
        }
        return `${path}?afterSegmentNumber=${afterSegmentNumber}`
    },
    cancelAnalysis: (progressId: string) => `/api/distributor/analysis/${progressId}/cancel`,
}

export const distributionProgress = (distributionProgressId: string): Promise<DistributionProgress> => {
    return fetch(paths.distributionProgress(distributionProgressId))
        .then(HandleErrorResponse)
        .then(res => res.json())
}

export const distributePlaylistsHistorically = (preferences: string[]): Promise<DistributionResponse> => {
    let request = {
        playlistPreferences: preferences
    };

    return fetch(paths.distributeHistorically, {
        method: 'POST',
        body: JSON.stringify(request),
        headers: {
            'Content-type': 'application/json; charset=UTF-8'
        },
    }).then(HandleErrorResponse)
        .then(res => res.json());
}

export const analyse = (): Promise<string> => {
    return fetch(paths.analyse, {
        method: 'POST',
        headers: {
            'Content-type': 'application/json; charset=UTF-8'
        },
    }).then(HandleErrorResponse)
        .then(res => res.json())
        .then((response: AnalysisResponse) => response.progressId);
}

export const deleteCombinatorialPlaylist = (combinatorialPreferenceId: string): Promise<void> => {
    return fetch(paths.combinatorial(combinatorialPreferenceId), {
        method: 'DELETE',
        headers: {
            'Content-type': 'application/json; charset=UTF-8'
        },
    }).then(HandleErrorResponse)
        .then();
}

export const createCombinatorialPlaylist = (request: CreateCombinatorialPlaylistRequest): Promise<string> => {
    return fetch(paths.createCombinatorial, {
        method: 'POST',
        body: JSON.stringify(request),
        headers: {
            'Content-type': 'application/json; charset=UTF-8'
        },
    }).then(HandleErrorResponse)
        .then(res => res.json())
        .then((response: DistributionResponse) => response.distributionProgressId);
}

export const deletePlaylist = (preference: string): Promise<void> => {
    return fetch(paths.predefined(preference), {
        method: 'DELETE',
        headers: {
            'Content-type': 'application/json; charset=UTF-8'
        },
    }).then(HandleErrorResponse)
        .then();
}

export const analysisResults = (progressId: string, afterSegmentNumber: number | undefined): Promise<AnalysisResultsResponse> => {
    return fetch(paths.analysisResults(progressId, afterSegmentNumber))
        .then(HandleErrorResponse)
        .then(res => res.json());
}

export const cancelAnalysis = (progressId: string): Promise<void> => {
    return fetch(paths.cancelAnalysis(progressId), {
        method: 'POST',
    }).then(HandleErrorResponse)
        .then()
}
import {createContext, useContext} from "react"

export type AuthorizationData = {
    loggedIn: boolean | undefined;
    setLoggedIn: (value: boolean) => void;
}
export const AuthorizationContext = createContext<AuthorizationData>({
    loggedIn: undefined,
    setLoggedIn: (value: boolean) => {
    }
})
export const useAuthorizationContext = () => useContext(AuthorizationContext)
import React from "react";
import {Link} from "react-router-dom";

export const FeedbackPolicy = () => {
    return (
        <div className="pageContentSegment">
            <h2>Unsolicited idea submission policy</h2>
            <p>Holdsoft Solutions has a policy that does not allow Holdsoft Solutions and its employees and contractors to accept, review or consider any unsolicited ideas,
                suggestions, proposals, materials, or the like including but not limited to products, services, product enhancements, product names, or content. In addition,
                Holdsoft Solutions cannot agree to provide any compensation for any such unsolicited submissions.</p>
            <p>The purpose of this policy is to avoid future misunderstandings or disputes about creative ideas when Holdsoft Solutions's products, services, or features might
                seem similar to submissions provided to Holdsoft Solutions, or if new Liked Songs Manager features developed independently by Holdsoft Solutions seem similar to
                those that were also suggested by a customer.</p>
            <p>If, despite our policy, you submit your submission to Holdsoft Solutions, then you agree that the following terms will apply to your submissions regardless of what
                your correspondenace says:</p>
            <h3>Terms</h3>
            <ol>
                <li>Your submission and its contents along with related intellectual property rights automatically become the property of Holdsoft Solutions, without any
                    compensation of any kind owed to you.
                </li>
                <li>Holdsoft Solutions may use or redistribute your submissions and their contents for any purpose and in any way.</li>
                <li>Holdsoft Solutions has no obligation to acknowledge or review your submission, or otherwise respond in any way to you regarding your submission.</li>
                <li>Holdsoft Solutions has no obligation to keep your submission confidential.</li>
            </ol>
            <p>If you do not agree to these terms, please do not submit an unsolicited idea to us.</p>
            <h3>Customer feedback</h3>
            <p>
                Holdsoft Solutions welcomes your comments, questions, and feedback regarding Holdsoft Solutions's existing products and services. To provide us feedback, please
                use the form found at <Link to={'/feedback'}>Feedback</Link>. Please provide only specific feedback Holdsoft Solutions's existing products and services. By
                providing feedback, you agree that any feedback you provide at this site is non-confidential and becomes, upon submission, the sole and exclusive property of
                Holdsoft Solutions. You further agree that Holdsoft Solutions is free to use and redistribute such information on an unrestricted basis, without any compensation
                to you or any other person or party.
            </p>
        </div>
    )
}



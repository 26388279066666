import {ErrorCode} from "../../../../api/ApiError";
import {SimplePreference} from "./SimplePreference";
import {DeletePreferenceButton} from "../common/DeletePreferenceButton";
import {ErrorMessage} from "../../../common/ErrorMessage";
import React, {CSSProperties, useState} from "react";
import './SubgenresManagementPlaylistModule.css';
import {PreferenceButton} from "../common/PreferenceButton";
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import List from 'react-virtualized/dist/commonjs/List';
import {SubgenreSearch} from "../common/SubgenreSearch";
import {AllSpotifyGenres} from "../../SpotifyGenres";

interface SubgenresManagementPlaylistModuleProps {
    errorCode: ErrorCode | undefined;
    preferences: SimplePreference[];
    deletablePreferences: SimplePreference[];
    addSelectedPreferences: (preference: string) => void;
    removeSelectedPreferences: (preference: string) => void;
    deletePreference: (preference: SimplePreference) => void;
    preferenceUnavailable?: (preference: string) => void;
    numberOfSelectedPreferences: number;
    mostRecentlySelected: string | undefined;
    group: string;
    subgenresShown: boolean;
    tooManySelectedPreferencesText: string;
    roomForMorePreferences: boolean;
    analysisStarted: boolean;
    numberOfApplicableSongsForPreference: Map<string, number>;
}

const SubgenresManagementPlaylistModuleProps = (props: SubgenresManagementPlaylistModuleProps) => {
    if (!props.subgenresShown)
        return <React.Fragment/>;

    let deletablePreferences = props.deletablePreferences
        .filter(preference => preference.group === props.group)
        .map(preference => preference.name);

    
    let selectedPreferences = props.preferences.filter(preference => preference.selected);

    let selectedPreferencesButtons = selectedPreferences.map((preference) => {
        let numberOfApplicableSongsForPreference = props.numberOfApplicableSongsForPreference.get(preference.name) !== undefined
            ? props.numberOfApplicableSongsForPreference.get(preference.name)
            : props.analysisStarted ? 0 : undefined;
        
        return <PreferenceButton
            mostRecentlyClicked={props.mostRecentlySelected === preference.name}
            roomForMorePreferences={props.roomForMorePreferences}
            active={preference.selected}
            addSelectedPreferences={props.addSelectedPreferences}
            removeSelectedPreferences={props.removeSelectedPreferences}
            preference={preference.name}
            key={preference.name}
            icon={undefined}
            tooManySelectedPreferencesText={props.tooManySelectedPreferencesText}
            available={preference.available}
            preferenceUnavailable={props.preferenceUnavailable}
            numberOfApplicableSongs={numberOfApplicableSongsForPreference} />
    });
    
    return (
        <div className={"subgenres"}>
            <SubgenreSearch addSelectedPreferences={props.addSelectedPreferences}
                            allSubgenres={AllSpotifyGenres}
                            selectedPreferencesNames={selectedPreferences.map(preference => preference.name)}
                            selectedPreferencesButtons={selectedPreferencesButtons}
                            deletablePreferences={deletablePreferences}
                            mostRecentlySelected={props.mostRecentlySelected}
                            roomForMorePreferences={props.roomForMorePreferences}
                            tooManySelectedPreferencesText={props.tooManySelectedPreferencesText}
                            preferenceUnavailable={props.preferenceUnavailable}
                            height={350}
                            analysisStarted={props.analysisStarted}
                            numberOfApplicableSongsForPreference={props.numberOfApplicableSongsForPreference}
            />
            <DeleteButtons preferences={props.preferences} deletePreference={props.deletePreference} deletablePreferences={deletablePreferences}/>
            <ErrorMessage errorCode={props.errorCode}/>
        </div>
    )
}

interface DeleteButtonsProps {
    preferences: SimplePreference[];
    deletePreference: (preference: SimplePreference) => void;
    deletablePreferences: string[];
}

const DeleteButtons = (props: DeleteButtonsProps) => {
    let deletePreferenceButtons = props.preferences
        .filter((preference) => props.deletablePreferences.includes(preference.name))
        .map((preference) => {
            return <DeletePreferenceButton
                deletePreferences={props.deletePreference}
                preference={preference}
                key={preference.name}/>
        })

    if (deletePreferenceButtons.length === 0)
        return <React.Fragment/>

    return (
        <div>
            <div className={"preferenceButtonsSectionHeader subgenresManaged"}>Managed</div>
            <div className={"preferenceButtonsSection"}>
                {deletePreferenceButtons}
            </div>
        </div>
    )
}

export default SubgenresManagementPlaylistModuleProps;


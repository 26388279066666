import React from "react";
import './Groups.css';
import {useAuthorizationContext} from "../../../context/authorizationContext";
import {GetAccessWithPremiumButton, PlaylistRow, PlaylistTable} from "./Groups";
import {GoToLoginButton} from "../../common/Buttons";
import {MembershipLevel, useUserContext} from "../../../context/userContext";

export const Miscellaneous = () => {
    const {loggedIn} = useAuthorizationContext();
    const {membershipLevel} = useUserContext();
    const showPremiumButton = loggedIn === false || membershipLevel !== MembershipLevel.PremiumYearly;
    
    return (
        <div>
            <div className="group pageContentSegment">
                <h1>Miscellaneous playlists</h1>
                <p>There are a few playlists on Liked Songs Manager that do not quite fit any of the other categories, even though we hold them very dearly.</p>
                <PlaylistTable>
                    <PlaylistRow name={"Everything"} availableFree={false}/>
                    <PlaylistRow name={"Partitions of 50 Songs"} availableFree={false}/>
                    <PlaylistRow name={"Partitions of 100 Songs"} availableFree={false}/>
                    <PlaylistRow name={"Partitions of 200 Songs"} availableFree={false}/>
                </PlaylistTable>
                {showPremiumButton ? <GetAccessWithPremiumButton/> : null}
            </div>
        </div>
    );
}


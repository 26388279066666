import React from "react";
import './Groups.css';
import {useAuthorizationContext} from "../../../context/authorizationContext";
import {PlaylistRow, PlaylistTable} from "./Groups";
import {GoToLoginButton} from "../../common/Buttons";

export const Nationality = () => {
    const {loggedIn} = useAuthorizationContext();
    return (
        <div>
            <div className="group pageContentSegment">
                <h1>Nationality playlists</h1>
                <p>Liked Songs Manager supports creating playlists from a wide variety of nationalities.</p>
                <PlaylistTable>
                    <PlaylistRow name={"Argentine"} availableFree={true}/>
                    <PlaylistRow name={"Australian"} availableFree={true}/>
                    <PlaylistRow name={"Austrian"} availableFree={true}/>
                    <PlaylistRow name={"Belgian"} availableFree={true}/>
                    <PlaylistRow name={"Brazilian"} availableFree={true}/>
                    <PlaylistRow name={"British"} availableFree={true}/>
                    <PlaylistRow name={"Canadian"} availableFree={true}/>
                    <PlaylistRow name={"Chilean"} availableFree={true}/>
                    <PlaylistRow name={"Chinese"} availableFree={true}/>
                    <PlaylistRow name={"Colombian"} availableFree={true}/>
                    <PlaylistRow name={"Czech"} availableFree={true}/>
                    <PlaylistRow name={"Danish"} availableFree={true}/>
                    <PlaylistRow name={"Dutch"} availableFree={true}/>
                    <PlaylistRow name={"Finnish"} availableFree={true}/>
                    <PlaylistRow name={"French"} availableFree={true}/>
                    <PlaylistRow name={"German"} availableFree={true}/>
                    <PlaylistRow name={"Greek"} availableFree={true}/>
                    <PlaylistRow name={"Hungarian"} availableFree={true}/>
                    <PlaylistRow name={"Icelandic"} availableFree={true}/>
                    <PlaylistRow name={"Indian"} availableFree={true}/>
                    <PlaylistRow name={"Indonesian"} availableFree={true}/>
                    <PlaylistRow name={"Irish"} availableFree={true}/>
                    <PlaylistRow name={"Israeli"} availableFree={true}/>
                    <PlaylistRow name={"Italian"} availableFree={true}/>
                    <PlaylistRow name={"Japanese"} availableFree={true}/>
                    <PlaylistRow name={"Kiwi"} availableFree={true}/>
                    <PlaylistRow name={"Korean"} availableFree={true}/>
                    <PlaylistRow name={"Mexican"} availableFree={true}/>
                    <PlaylistRow name={"Norwegian"} availableFree={true}/>
                    <PlaylistRow name={"Polish"} availableFree={true}/>
                    <PlaylistRow name={"Swedish"} availableFree={true}/>
                    <PlaylistRow name={"Taiwan"} availableFree={true}/>
                    <PlaylistRow name={"Thai"} availableFree={true}/>
                    <PlaylistRow name={"Turkish"} availableFree={true}/>
                    <PlaylistRow name={"Ukrainian"} availableFree={true}/>
                </PlaylistTable>
                {!loggedIn ? <GoToLoginButton/> : null}
            </div>
        </div>
    );
}


import React, {useEffect, useState} from "react";
import PreferenceManagementModule from "../common/PreferenceManagementModule";
import PreferenceManagementConfirmButtonsModule from './PreferenceManagementConfirmButtonsModule';
import PreferenceManagementPlaylistModule from './PreferenceManagementPlaylistModule';
import {analyse, cancelAnalysis, deletePlaylist, distributePlaylistsHistorically} from '../../../../api/distributor/DistributeApi';
import {SimplePreference} from './SimplePreference';
import {ApiError, ErrorCode} from '../../../../api/ApiError';
import {ErrorMessage} from "../../../common/ErrorMessage";
import {Link} from "react-router-dom";
import {PreferenceGroup} from "../../../../api/playlistpreferences/PlaylistPreferences";
import './SimplePreferenceManagement.css';
import {DistributionResponse} from "../../../../api/distributor/DistributionResponse";
import PremiumPopup from "./PremiumPopup";
import {DeleteModalNotShownKey, DeletePreferenceModal} from "../common/DeletePreferenceModal";
import SubgenresManagementPlaylistModule from "./SubgenresManagementPlaylistModule";
import {AnalysisSection} from "./AnalysisSection";
import {SongsForPreference} from "../../../../api/distributor/AnalysisResponse";

interface SimplePreferencesManagementProps {
    userHasUnlimitedPlaylists: boolean;
    userIsNoneFree: boolean;
    simplePreferences: SimplePreference[];
    preferenceGroups: PreferenceGroup[];
    preferenceDeleted: (preference: SimplePreference) => void;
}

const SimplePreferenceManagement = (props: SimplePreferencesManagementProps) => {
    const [expandedPlaylistGroups, setExpandedPlaylistGroups] = useState<Map<string, boolean>>(() => {
        let initialState = new Map<string, boolean>();
        let preferencesByGroup = props.simplePreferences.reduce((result: any, preference) => {
            result[preference.group] = result[preference.group] || [];
            result[preference.group].push(preference);
            return result;
        }, []);

        let hasAnyPreference = props.simplePreferences.some((preference: SimplePreference) => preference.userHasPreference);
        for (let preferenceGroup of props.preferenceGroups) {
            let preferences = preferencesByGroup[preferenceGroup.group];
            let hasAtLeastOnePreference = preferences.some((preference: SimplePreference) => preference.userHasPreference);
            let isMoodGroup = preferenceGroup.group === 'Mood';

            initialState.set(preferenceGroup.group, hasAtLeastOnePreference || isMoodGroup && !hasAnyPreference);
        }
        return initialState;
    });

    let deletablePreferences = props.simplePreferences.filter(preference => preference.userHasPreference);
    const [errorCode, setErrorCode] = useState<ErrorCode>();
    const [playlistGroupErrorCode, setPlaylistGroupErrorCode] = useState<Map<string, ErrorCode | undefined>>(() => {
        let initialState = new Map<string, ErrorCode | undefined>();
        for (let preference of props.simplePreferences) {
            initialState.set(preference.group, undefined);
        }

        return initialState;
    });

    const [deleteModalShown, setDeleteModalShown] = useState<boolean>(false);
    const [playlistBeingDeleted, setPreferenceBeingDeleted] = useState<SimplePreference>();
    const [showPremiumPopup, setShowPremiumPopup] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [preferences, setPreferences] = useState<SimplePreference[]>(props.simplePreferences);
    const [mostRecentlySelected, setMostRecentlySelected] = useState<string>();
    const [analysisStarted, setAnalysisStarted] = useState<boolean>(false);
    const [analysisProgressId, setAnalysisProgressId] = useState<string>();
    const [analysisFinished, setAnalysisFinished] = useState<boolean>(false);
    const [analysisResult, setAnalysisResult] = useState<Map<string, number>>(new Map<string, number>);
    const [createPlaylistsInProgressOfStarting, setCreatePlaylistsInProgressOfStarting] = useState<boolean>(false);
    const [waitAndHoldMostRecentlySelected, setWaitAndHoldMostRecentlySelected] = useState<string[]>([]);
    useEffect(() => {
        setMostRecentlySelected(waitAndHoldMostRecentlySelected[0])
    }, [waitAndHoldMostRecentlySelected]);

    if (preferences === undefined || expandedPlaylistGroups === undefined || preferences.length === 0)
        return (<React.Fragment/>)

    let preferencesByGroup = preferences.reduce((result: any, preference) => {
        result[preference.group] = result[preference.group] || [];
        result[preference.group].push(preference);
        return result;
    }, []);

    props.preferenceGroups.sort((a, b) => a.order - b.order);

    let numberOfSelectedPreferences = preferences.filter((preference) => preference.selected).length + deletablePreferences.length;
    let tooManySelectedPreferencesText = props.userHasUnlimitedPlaylists
        ? "Unfortunately at most 100 playlists can be selected"
        : "Become a premium member to select more than 15 playlists!";

    let hasUnavailablePlaylists = preferences.some(preference => preference.userHasPreference && !preference.available);

    let roomForMorePreferences = numberOfSelectedPreferences < playlistLimit();

    let preferencePlaylistModules = [];
    for (let preferenceGroup of props.preferenceGroups) {
        let preferencesInGroup: SimplePreference[] = preferencesByGroup[preferenceGroup.group]
        let explainText = `${preferenceGroup.group}`;
        let allPreferencesAvailable = !preferencesInGroup.some((preference: SimplePreference) => !preference.available);
        let errorCode = playlistGroupErrorCode.get(preferenceGroup.group);

        let subModule = preferenceGroup.group == 'Subgenre' ?
            <SubgenresManagementPlaylistModule
                errorCode={errorCode}
                deletablePreferences={deletablePreferences}
                mostRecentlySelected={mostRecentlySelected}
                numberOfSelectedPreferences={numberOfSelectedPreferences}
                addSelectedPreferences={addPreference}
                removeSelectedPreferences={removePreference}
                deletePreference={deletePreference}
                key={explainText + " playlist-module"}
                preferences={preferencesInGroup}
                group={preferenceGroup.group}
                tooManySelectedPreferencesText={tooManySelectedPreferencesText}
                roomForMorePreferences={roomForMorePreferences}
                subgenresShown={expandedPlaylistGroups.get(preferenceGroup.group)!}
                preferenceUnavailable={preferenceUnavailable}
                numberOfApplicableSongsForPreference={analysisResult}
                analysisStarted={analysisStarted}
            />
            :
            <PreferenceManagementPlaylistModule
                errorCode={errorCode}
                deletablePreferences={deletablePreferences}
                mostRecentlySelected={mostRecentlySelected}
                numberOfSelectedPreferences={numberOfSelectedPreferences}
                addSelectedPreferences={addPreference}
                removeSelectedPreferences={removePreference}
                deletePreference={deletePreference}
                key={explainText + " playlist-module"}
                tooManySelectedPreferencesText={tooManySelectedPreferencesText}
                roomForMorePreferences={roomForMorePreferences}
                preferences={preferencesInGroup}
                group={preferenceGroup.group}
                preferenceUnavailable={preferenceUnavailable}
                numberOfApplicableSongsForPreference={analysisResult}
                analysisStarted={analysisStarted}
            />;

        let module = <PreferenceManagementModule key={explainText + " module"}
                                                 explainText={explainText}
                                                 allPreferencesAvailable={allPreferencesAvailable}
                                                 preferenceGroup={preferenceGroup}
                                                 playlistGroupHeaderClicked={playlistGroupHeaderClicked}
                                                 playlistsShown={expandedPlaylistGroups.get(preferenceGroup.group)!}
                                                 subModule={subModule}
        />
        preferencePlaylistModules.push(module)
    }

    return (
        <>
            <div className="pageContentSegment">
                <h2>Predefined playlists</h2>
                <HelpText userHasUnlimitedPlaylists={props.userHasUnlimitedPlaylists} userIsNoneFree={props.userIsNoneFree} userHasUnavailablePlaylists={hasUnavailablePlaylists}/>
                <AnalysisSection analysisInProgressCallback={analysisInProgressCallback}
                                 analysisInProgress={analysisStarted}
                                 resultCallback={analysisResultCallback}
                                 analysisFinishedCallback={analysisFinishedCallback}/>
                {preferencePlaylistModules}
                <PreferenceManagementConfirmButtonsModule distribute={distributeHistorically}/>
                <ErrorMessage errorCode={errorCode} errorMessage={errorMessage}/>
                <PremiumPopup hide={hidePremiumPopup} shown={showPremiumPopup}/>
                <DeletePreferenceModal shown={deleteModalShown} confirmDeletePreference={confirmDeletePreference} cancelDeletePreference={cancelDeletePreference}/>
            </div>
        </>
    )

    function deletePreference(preference: SimplePreference) {
        let deleteModalNotShown = localStorage.getItem(DeleteModalNotShownKey);
        if (deleteModalNotShown == "true") {
            doDeletePreference(preference);
            return;
        }

        setDeleteModalShown(true);
        setPreferenceBeingDeleted(preference);
    }

    function confirmDeletePreference() {
        setDeleteModalShown(false);
        if (playlistBeingDeleted === undefined)
            return;

        doDeletePreference(playlistBeingDeleted);
    }

    function playlistLimit(): number {
        if (props.userHasUnlimitedPlaylists)
            return 100;

        return 15;
    }


    function doDeletePreference(preference: SimplePreference) {
        setPreferenceBeingDeleted(undefined);
        setPlaylistGroupErrorCode(prevState => new Map<string, ErrorCode | undefined>(prevState.set(preference.group, undefined)));
        deletePlaylist(preference.name)
            .then(() => props.preferenceDeleted(preference))
            .catch((error: ApiError) => {
                    if (error.errorCode === undefined) {
                        setPlaylistGroupErrorCode(prevState => new Map<string, ErrorCode | undefined>(prevState.set(preference.group, ErrorCode.UnknownError)));
                        return;
                    }
                    setPlaylistGroupErrorCode(prevState => new Map<string, ErrorCode | undefined>(prevState.set(preference.group, error.errorCode)));
                }
            );
    }

    function cancelDeletePreference() {
        setDeleteModalShown(false);
        setPreferenceBeingDeleted(undefined);
    }

    function preferenceUnavailable(preference: string) {
        setShowPremiumPopup(true);

        if (mostRecentlySelected !== preference) {
            setMostRecentlySelected(preference);
        } else {
            setWaitAndHoldMostRecentlySelected([preference]);
            setMostRecentlySelected(undefined);
        }
    }

    function addPreference(name: string) {
        if (preferences === undefined)
            return;

        let updatedPreferences = preferences.map((val) => val.name !== name ? val : {
            name: val.name,
            group: val.group,
            available: val.available,
            userHasPreference: val.userHasPreference,
            numberOfSongsApplicable: val.numberOfSongsApplicable,
            selected: true
        });


        let numberOfPreferences = updatedPreferences.filter((preference) => preference.selected);
        if (numberOfPreferences.length + deletablePreferences.length > playlistLimit()) {
            if (mostRecentlySelected !== name) {
                setMostRecentlySelected(name);
            } else {
                setWaitAndHoldMostRecentlySelected([name]);
                setMostRecentlySelected(undefined);
            }
            return;
        }

        setPreferences(updatedPreferences);
    }

    function removePreference(preference: string) {
        if (preferences === undefined)
            return;

        setMostRecentlySelected(undefined);

        let updatedPreferences = preferences.map((val) => val.name !== preference ? val : {
            name: val.name,
            group: val.group,
            available: val.available,
            userHasPreference: val.userHasPreference,
            numberOfSongsApplicable: val.numberOfSongsApplicable,
            selected: false
        });
        setPreferences(updatedPreferences);
    }

    function distributeHistorically() {
        if (preferences === undefined) {
            return;
        }

        if (createPlaylistsInProgressOfStarting) {
            return;
        }

        let selectedPreferences = preferences
            .filter((preference) => preference.selected)
            .map((preference) => preference.name);


        if (selectedPreferences.length === 0) {
            setErrorMessage("You must choose at least one playlist.")
            return;
        }

        let createdPlaylists = getCreatedPlaylists(selectedPreferences);
        let removedPlaylists = getRemovedPlaylists(selectedPreferences);

        if (removedPlaylists.length === 0 && createdPlaylists.length === 0) {
            setErrorMessage("You have not made any changes.");
            return;
        }

        setErrorCode(undefined);
        setErrorMessage(undefined);
        setCreatePlaylistsInProgressOfStarting(true);

        if (!analysisFinished && analysisProgressId !== undefined) {
            cancelAnalysis(analysisProgressId)
                .catch((error: ApiError) => setErrorCode(error.errorCode))
                .then(() => doDistribute(selectedPreferences, createdPlaylists, removedPlaylists))
                .finally(() => setCreatePlaylistsInProgressOfStarting(false));
        } else {
            doDistribute(selectedPreferences, createdPlaylists, removedPlaylists)
        }
    }

    function doDistribute(selectedPreferences: string[], createdPlaylists: string[], removedPlaylists: string[]) {
        distributePlaylistsHistorically(selectedPreferences)
            .then((distributionResponse: DistributionResponse) => {
                sessionStorage.setItem(`${distributionResponse.distributionProgressId}-created`, JSON.stringify(createdPlaylists));
                sessionStorage.setItem(`${distributionResponse.distributionProgressId}-removed`, JSON.stringify(removedPlaylists));
                sessionStorage.setItem(`${distributionResponse.distributionProgressId}-numberOfLikedSongs`, distributionResponse.totalNumberOfLikedSongs.toString());
                return distributionResponse.distributionProgressId;
            })
            .then((distributionProgressId: string) => window.location.replace(`/playlists/distribution-success?distributionProgressId=${distributionProgressId}`))
            .catch((error: ApiError) => setErrorCode(error.errorCode))
            .finally(() => setCreatePlaylistsInProgressOfStarting(false));
    }

    function getCreatedPlaylists(selectedPreferences: string[]): string[] {
        let existingPlaylists = getExistingPlaylists();
        let createdPlaylists = [];
        for (let selectedPreference of selectedPreferences) {
            if (!existingPlaylists.some(existingPlaylists => existingPlaylists === selectedPreference)) {
                createdPlaylists.push(selectedPreference);
            }
        }

        return createdPlaylists;
    }

    function getRemovedPlaylists(selectedPreferences: string[]): string[] {
        let existingPlaylists = getExistingPlaylists();

        let removedPlaylists = [];
        for (let existingPlaylist of existingPlaylists) {
            if (!selectedPreferences.some(selectedPreference => selectedPreference === existingPlaylist)) {
                removedPlaylists.push(existingPlaylist);
            }
        }

        return removedPlaylists;
    }

    function getExistingPlaylists(): string[] {
        return props.simplePreferences
            .filter((preference) => preference.selected)
            .map((preference) => preference.name);
    }

    function hidePremiumPopup() {
        setShowPremiumPopup(false);
    }

    function analysisInProgressCallback(progressId: string) {
        setAnalysisStarted(true);
        setAnalysisProgressId(progressId);
    }

    function analysisFinishedCallback() {
        setAnalysisFinished(true);
    }

    function analysisResultCallback(songsForPreferences: SongsForPreference[]) {
        setAnalysisResult((prevState) => {
            let newMap = new Map<string, number>(prevState);
            for (let songsForPreference of songsForPreferences) {
                let existingValue = newMap.get(songsForPreference.preferenceName);
                if (existingValue !== undefined) {
                    newMap.set(songsForPreference.preferenceName, existingValue + songsForPreference.numberOfSongsApplicable);
                } else {
                    newMap.set(songsForPreference.preferenceName, songsForPreference.numberOfSongsApplicable);
                }
            }

            return newMap;
        });
    }

    function playlistGroupHeaderClicked(group: string) {
        setExpandedPlaylistGroups((prevState: Map<string, boolean>) => {
            let wasExpanded = prevState.get(group)!;
            let newState = new Map(prevState);
            newState.set(group, !wasExpanded);

            return newState;
        });

    }
}

interface HelpTextProps {
    userHasUnlimitedPlaylists: boolean;
    userIsNoneFree: boolean;
    userHasUnavailablePlaylists: boolean;
}

const HelpText = (props: HelpTextProps) => {
    if (props.userHasUnlimitedPlaylists) {
        return (
            <div className={"helpText"}>
                <p>Choose between all of the playlists which will be kept up to date.</p>
            </div>
        )
    }

    if (props.userIsNoneFree) {
        return (
            <div className={"helpText"}>
                <p>Choose up to 15 playlists to be created and kept up to date.</p>
            </div>
        )
    }

    return (
        <div className={"helpText"}>
            <p>As a free user, you may have up to 15 standard playlists to be created and kept up to date. The first 1000 of your liked songs are considered for the
                playlists.</p>
            {props.userHasUnavailablePlaylists &&
                <p>Your premium playlists are no longer maintained, and can be deleted from here. If you delete them, you will have to become premium to create them again.</p>}
            <Link to={'/plans'}><p className={"premiumText"}>Become premium ⭐ to remove the track limitation, get increased number of managed playlists, and more.</p></Link>
        </div>)
}


export default SimplePreferenceManagement;
import {HandleErrorResponse} from "../HandleErrorResponse";
import {Statistics} from "./Statistics";

const paths = {
    statistics: '/api/admin/statistics',
    enableStatistics: (userId: string) => `/api/admin/statistics/user/${userId}/enable`,
    disableStatistics: (userId: string) => `/api/admin/statistics/user/${userId}/disable`,
}

export const getStatistics = (): Promise<Statistics> => {
    return fetch(paths.statistics)
        .then(HandleErrorResponse)
        .then(res => res.json())
}

export const enableStatistics = (userId: string): Promise<void> => {
    return fetch(paths.enableStatistics(userId), {
        method: 'POST',
    }).then(HandleErrorResponse)
        .then();
}

export const disableStatistics = (userId: string): Promise<void> => {
    return fetch(paths.disableStatistics(userId), {
        method: 'POST',
    }).then(HandleErrorResponse)
        .then();
}
import React from "react";
import './Groups.css';
import {useAuthorizationContext} from "../../../context/authorizationContext";
import {GetAccessWithPremiumButton, PlaylistRow, PlaylistTable} from "./Groups";
import {GoToLoginButton} from "../../common/Buttons";
import {MembershipLevel, useUserContext} from "../../../context/userContext";

export const LatestLikedSongs = () => {
    const {loggedIn} = useAuthorizationContext();
    const {membershipLevel} = useUserContext();
    const showPremiumButton = loggedIn === false || membershipLevel !== MembershipLevel.PremiumYearly;
    
    return (
        <div>
            <div className="group pageContentSegment">
                <h1>Latest Liked Songs playlists</h1>
                <p>Liked Songs Manager lets you create playlists that consist of the latest songs that you have liked on Spotify. When a new song is liked and there is no more capacity on the playlist, the oldest is removed.</p>
                <PlaylistTable>
                    <PlaylistRow name={"Your 50 Latest Liked Songs"} availableFree={false}/>
                    <PlaylistRow name={"Your 100 Latest Liked Songs"} availableFree={false}/>
                    <PlaylistRow name={"Your 200 Latest Liked Songs"} availableFree={false}/>
                    <PlaylistRow name={"Your 500 Latest Liked Songs"} availableFree={false}/>
                </PlaylistTable>
                {showPremiumButton ? <GetAccessWithPremiumButton/> : null}
            </div>
        </div>
    );
}


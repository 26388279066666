import React, {CSSProperties, useState} from "react";
import {PreferenceButton} from "./PreferenceButton";
import AutoSizer from "react-virtualized/dist/commonjs/AutoSizer";
import List from "react-virtualized/dist/commonjs/List";
import {Link} from "react-router-dom";
import './SubgenreSearch.css';
import {Toggle} from "../../../common/Toggle";

interface SubgenreSearchProps {
    allSubgenres: string[];
    selectedPreferencesNames: string[];
    deletablePreferences?: string[];
    selectedPreferencesButtons: JSX.Element[];
    addSelectedPreferences: (preference: string) => void;
    mostRecentlySelected: string | undefined;
    roomForMorePreferences: boolean;
    tooManySelectedPreferencesText: string;
    preferenceUnavailable?: (preference: string) => void;
    height: number;
    numberOfApplicableSongsForPreference?: Map<string, number>;
    analysisStarted: boolean;
    removeFilterTopMargin?: boolean;
}

export const SubgenreSearch = (props: SubgenreSearchProps) => {
    const [filterString, setFilterString] = useState<string>("");
    const [onlyShowSubgenresWithApplicableSongs, setOnlyShowSubgenresWithApplicableSongs] = useState<boolean>(false);

    let searchableSubgenres = Array.from(props.allSubgenres
        .filter((subgenre) =>
            (props.deletablePreferences == undefined || !props.deletablePreferences.includes(subgenre)) &&
            !props.selectedPreferencesNames.includes(subgenre)));

    if (filterString.length >= 1) {
        searchableSubgenres = searchableSubgenres.filter(subgenre => subgenre.toLowerCase().indexOf(filterString.toLowerCase()) != -1);
    }

    if (onlyShowSubgenresWithApplicableSongs && props.numberOfApplicableSongsForPreference !== undefined) {
        searchableSubgenres = searchableSubgenres.filter(subgenre => {
            let numberOfSongs = props.numberOfApplicableSongsForPreference!.get(subgenre);
            if (numberOfSongs === undefined) {
                return false;
            }

            return numberOfSongs > 0;
        });
    }

    const renderRow = ({index, key, style}: { index: number, key: string, style: CSSProperties }) => {
        let subgenre = searchableSubgenres[index];
        let numberOfApplicableSongsForPreference = props.numberOfApplicableSongsForPreference !== undefined && props.numberOfApplicableSongsForPreference.get(subgenre) !== undefined
            ? props.numberOfApplicableSongsForPreference.get(subgenre)
            : props.analysisStarted ? 0 : undefined;
        return (
            <div key={key} style={style}>
                <PreferenceButton
                    mostRecentlyClicked={props.mostRecentlySelected === subgenre}
                    roomForMorePreferences={props.roomForMorePreferences}
                    active={false}
                    addSelectedPreferences={props.addSelectedPreferences}
                    removeSelectedPreferences={() => {
                    }}
                    preference={subgenre}
                    icon={undefined}
                    tooManySelectedPreferencesText={props.tooManySelectedPreferencesText}
                    available={true}
                    preferenceUnavailable={props.preferenceUnavailable}
                    numberOfApplicableSongs={numberOfApplicableSongsForPreference}
                />
            </div>
        )
    }

    
    let filterClasses = "userInputContainer";
    if (props.removeFilterTopMargin) {
        filterClasses += " removeTopMargin"
    }
    return (
        <React.Fragment>
            <div className={"preferenceButtonsSection subgenresAvailable"}>
                {props.selectedPreferencesButtons}
            </div>
            {props.analysisStarted &&
                <div className={"hidePlaylistsWithoutApplicableSongs"}>
                    <Toggle onClick={() => {
                        return new Promise<void>((resolve, reject) => {
                            {
                                setOnlyShowSubgenresWithApplicableSongs(prevState => !prevState)
                                resolve()
                            }
                        })
                    }}
                            value={onlyShowSubgenresWithApplicableSongs}
                            marginRight={true}
                    />
                    <div>Hide subgenres without songs</div>
                </div>}
            <div className={filterClasses}>
                <input placeholder="Filter subgenres" onChange={(evt) => subgenreInputChanged(evt)} className="userInput" type="text" value={filterString}/>
            </div>
            <div style={{height: `${props.height}px`}} className={"subgenreList"}>
                <AutoSizer>
                    {({height, width}) => (
                        <List rowCount={searchableSubgenres.length} height={height} rowHeight={50} rowRenderer={renderRow} width={width}/>
                    )}
                </AutoSizer>
            </div>
        </React.Fragment>
    )

    function subgenreInputChanged(e: React.FormEvent<HTMLInputElement>) {
        setFilterString(e.currentTarget.value);
    }
}
import './PlaylistAnimation.css';
import React, {useEffect, useState} from "react";
import {ArcherContainer, ArcherElement} from "react-archer";
import {RelationType} from "react-archer/src/types";
import {ReactComponent as Heart} from './heart.svg';

export const PlaylistAnimation = () => {
    const [likedSong, setLikedSong] = useState<number>(-1);
    const [breakBetweenRuns, setBreakBetweenRuns] = useState<boolean>(false);
    const likedSongs = [
        "FirstSong",
        "SecondSong",
        "ThirdSong",
        "FourthSong",
        "FifthSong",
    ];
    const targetPlaylists: Map<string, string[]> = new Map();
    targetPlaylists.set("FirstSong", ["Upbeat"]);
    targetPlaylists.set("SecondSong", ["Upbeat", "1980s"]);
    targetPlaylists.set("ThirdSong", ["Chill", "Australian", "Indie"]);
    targetPlaylists.set("FourthSong", ["Chill", "Indie"]);
    targetPlaylists.set("FifthSong", ["Rock", "1980s", "Indie"]);

    useEffect(() => {
        if (!breakBetweenRuns) {
            return;
        }
        
        const timer = setTimeout(() => {
            setLikedSong(0);
            setBreakBetweenRuns(false);
        }, 2500);
        return () => clearTimeout(timer)
    }, [breakBetweenRuns]);
    
    useEffect(() => {
        const initialLikedSong = -1;
        if (likedSong === initialLikedSong) {
            const timer = setTimeout(() => {
                setLikedSong(0);
            }, 1350);
            return () => clearTimeout(timer)
        }

        if (likedSongs.length === likedSong + 1) {
            const timer = setTimeout(() => {
                setBreakBetweenRuns(true);
            }, 2500);
            return () => clearTimeout(timer)
        }

        const timer = setTimeout(() => {
            setLikedSong(likedSong + 1);
        }, 2500);
        return () => clearTimeout(timer)
    }, [likedSong]);

    let currentlyLikedSongId = likedSongs[likedSong];
    let currentlyTargetedPlaylists = targetPlaylists.get(currentlyLikedSongId);

    return (
        <ArcherContainer className={"archerContainer"}>
            <div className="playlistAnimation">
                <div className="playlistAnimationLikedSongs">
                    <div className="playlistAnimationLikedSongsListContainer">
                        <div className="playlistAnimationLikedSongsList">
                            <LikedSong currentlyLikedSongId={currentlyLikedSongId} id={"FirstSong"} targetPlaylists={targetPlaylists} title={"Don't Start Now"}
                                       artist={"Dua Lipa"}/>
                            <LikedSong currentlyLikedSongId={currentlyLikedSongId} id={"SecondSong"} targetPlaylists={targetPlaylists} title={"Super Trouper"} artist={"ABBA"}/>
                            <LikedSong currentlyLikedSongId={currentlyLikedSongId} id={"ThirdSong"} targetPlaylists={targetPlaylists} title={"Thefear"} artist={"Parcels"}/>
                            <LikedSong currentlyLikedSongId={currentlyLikedSongId} id={"FourthSong"} targetPlaylists={targetPlaylists} title={"Strong"} artist={"London Grammar"}/>
                            <LikedSong currentlyLikedSongId={currentlyLikedSongId} id={"FifthSong"} targetPlaylists={targetPlaylists} title={"Love Will Tear Us Apart"}
                                       artist={"Joy Division"}/>
                        </div>
                    </div>
                </div>
                <div className={"songsToPlaylistGap"}>
                </div>
                <div className={"managedPlaylists"}>
                    <ManagedPlaylist currentlyTargetedPlaylists={currentlyTargetedPlaylists} playlist={"Upbeat"} breakBetweenRuns={breakBetweenRuns}/>
                    <ManagedPlaylist currentlyTargetedPlaylists={currentlyTargetedPlaylists} playlist={"1980s"} breakBetweenRuns={breakBetweenRuns}/>
                    <ManagedPlaylist currentlyTargetedPlaylists={currentlyTargetedPlaylists} playlist={"Australian"} breakBetweenRuns={breakBetweenRuns}/>
                    <ManagedPlaylist currentlyTargetedPlaylists={currentlyTargetedPlaylists} playlist={"Rock"} breakBetweenRuns={breakBetweenRuns}/>
                    <ManagedPlaylist currentlyTargetedPlaylists={currentlyTargetedPlaylists} playlist={"Chill"} breakBetweenRuns={breakBetweenRuns}/>
                    <ManagedPlaylist currentlyTargetedPlaylists={currentlyTargetedPlaylists} playlist={"Indie"} breakBetweenRuns={breakBetweenRuns}/>
                </div>
            </div>
        </ArcherContainer>
    )
}

interface LikedSongProps {
    id: string;
    title: string;
    artist: string;
    targetPlaylists: Map<string, string[]>
    currentlyLikedSongId: string;
}

const LikedSong = (props: LikedSongProps) => {
    let currentlyLikedSong = props.currentlyLikedSongId === props.id;
    let relations: RelationType[] = [];
    let targetPlaylistsForSong = props.targetPlaylists.get(props.id);
    if (currentlyLikedSong && targetPlaylistsForSong !== undefined) {
        for (let targetPlaylist of targetPlaylistsForSong) {
            relations.push({
                className: "animatedArrow",
                targetId: targetPlaylist,
                targetAnchor: 'left',
                sourceAnchor: 'right',
                style: {
                    strokeWidth: 13,
                    strokeColor: "#4E5874",
                    endMarker: false,
                    noCurves: true,
                    lineStyle: "straight",
                },
            })
        }
    }

    let likedMarkingClasses = "likedMarking"
    if (currentlyLikedSong) {
        likedMarkingClasses += " current"
    }

    return (
        <div className="likedSongArrowBuffer">
            <ArcherElement id={props.id} relations={relations}>
                <div className="likedSongContainer">
                    <div className="likedSong">
                        <div className={"likedSongTitle"}>{props.title}</div>
                        <div className={"likedSongArtist"}>{props.artist}</div>
                    </div>
                    <div className={likedMarkingClasses}><Heart height={18} width={18}/></div>
                </div>
            </ArcherElement>
        </div>
    )
}

interface ManagedPlaylistProps {
    breakBetweenRuns: boolean;
    playlist: string;
    currentlyTargetedPlaylists: string[] | undefined;
}

const ManagedPlaylist = (props: ManagedPlaylistProps) => {
    let managedPlaylistClasses = "managedPlaylist";
    let managedPlaylistArrowBufferClasses = "managedPlaylistArrowBuffer";
    if (props.currentlyTargetedPlaylists !== undefined && props.currentlyTargetedPlaylists.indexOf(props.playlist) !== -1) {
        managedPlaylistClasses += " targeted";
        managedPlaylistArrowBufferClasses += " targeted";
    }
    if (props.breakBetweenRuns) {
        managedPlaylistClasses += " breakBetweenRuns"
        managedPlaylistArrowBufferClasses += " breakBetweenRuns";
    }

    return (
        <div className={managedPlaylistArrowBufferClasses}>
            <ArcherElement id={props.playlist}>
                <div className={managedPlaylistClasses}>{props.playlist}</div>
            </ArcherElement>
        </div>

    )
}

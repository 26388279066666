import {useAuthorizationContext} from "../../context/authorizationContext";
import {MembershipLevel, useUserContext} from "../../context/userContext";
import PageSegmentLoading from "../common/PageSegmentLoading";
import React, {useEffect, useState} from "react";
import {getMembershipLevel} from "../../api/user/UserApi";
import {ConfirmationButton} from "../common/Buttons";

const MembershipUpgradeSuccess = () => {
    const {loggedIn} = useAuthorizationContext();
    const {membershipLevel, setMembershipLevel} = useUserContext();

    const [isPremium, setIsPremium] = useState<boolean>(false);
    const [tickCount, setTickCount] = useState<number>(0);
    useEffect(() => {
        const timer = setInterval(() => {
            if (tickCount >= 10 || isPremium) {
                clearInterval(timer);
                return;
            }
            setTickCount((prevState: number) => prevState + 1);
        }, 1000);
        return () => clearInterval(timer)
    });

    useEffect(() => {
        getMembershipLevel()
            .then((result: string) => {
                let membershipLevel = MembershipLevel[result as keyof typeof MembershipLevel]
                if (membershipLevel === MembershipLevel.PremiumYearly) {
                    setIsPremium(true);
                    setMembershipLevel(MembershipLevel.PremiumYearly);
                }
            }).catch();
    }, [tickCount])

    if (loggedIn === undefined || !isPremium)
        return <PageSegmentLoading/>

    return (
        <div className="pageContentSegment">
            <h2>Premium upgrade successful</h2>
            <p>A big thank you for choosing to become a premium member ⭐.</p>
            <p>You now have access to all premium features of Liked Songs Manager.</p>
            <a href="/playlists">
                <ConfirmationButton text={"Back to playlists"}
                                    marginTop={true}
                                    marginBottom={true}
                                    center={true}
                />
            </a>
        </div>
    )
}
export default MembershipUpgradeSuccess;
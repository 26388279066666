import React, {useEffect, useRef} from "react";
import {ReactComponent as ClosePopup} from "../../common/x.svg";
import './Selector.css';

interface SelectorProps {
    header: string;
    criterionNumber: number;
    buttons: JSX.Element[];
    icon: JSX.Element;
    hide: () => void;
    addButtonRef: any;
}

export const Selector = (props: SelectorProps) => {
    return (
        <div>
            <SelectorDesktop header={props.header}
                             criterionNumber={props.criterionNumber}
                             buttons={props.buttons}
                             icon={props.icon}
                             hide={props.hide}
                             addButtonRef={props.addButtonRef}/>
            <SelectorMobile header={props.header}
                            criterionNumber={props.criterionNumber}
                            buttons={props.buttons}
                            icon={props.icon}
                            hide={props.hide}
                            addButtonRef={props.addButtonRef}/>
        </div>
    )
}

const SelectorDesktop = (props: SelectorProps) => {
    const ref: any = useRef(null);
    useEffect(() => {
        function handleClickOutside(event: any) {
            if (window.innerWidth > 1250) {
                if (ref.current &&
                    !ref.current.contains(event.target) &&
                    !props.addButtonRef.current.contains(event.target)) {
                    props.hide();
                }
            }
        }

        document.addEventListener("mouseup", handleClickOutside);
        return () => {
            document.removeEventListener("mouseup", handleClickOutside);
        };
    }, []);
    
    return (
        <div className="combinationSelectorContainer desktop">
            <div ref={ref} className="combinationSelector">
                <div className="combinationSelectorHeader">
                    <div className="preferenceIcon">
                        {props.icon}
                    </div>
                    <div className="combinationSelectorHeaderText">{props.header}</div>
                    <div className={"closeSelector"}>
                        <ClosePopup height={24} width={24} className={"close"} onClick={() => props.hide()}/>
                    </div>
                </div>
                <div className="combinationSelectorButtons">
                    {props.buttons}
                </div>
            </div>
        </div>
    )
}

const SelectorMobile = (props: SelectorProps) => {
    return (
        <div>
            <div className="combinationSelectorContainer mobile">
                <div className="combinationSelector">
                    <div className="combinationSelectorHeader">
                        <div className="preferenceIcon">
                            {props.icon}
                        </div>
                        <div className="combinationSelectorHeaderText">{props.header}</div>
                    </div>
                    <div className="combinationSelectorButtons">
                        {props.buttons}
                    </div>
                </div>
            </div>
        </div>
    )
}
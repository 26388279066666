import './PreferenceButton.css';
import React from "react";
import {ReactComponent as Star} from './star.svg';

interface PreferenceButtonProps {
    preference: string;
    active: boolean;
    addSelectedPreferences: (preference: string) => void;
    removeSelectedPreferences: (preference: string) => void;
    preferenceUnavailable?: (preference: string) => void;
    icon?: string
    roomForMorePreferences: boolean;
    mostRecentlyClicked: boolean;
    tooManySelectedPreferencesText: string;
    available: boolean;
    numberOfApplicableSongs?: number | undefined;
}

export const PreferenceButton = (props: PreferenceButtonProps) => {
    function handleOnClick() {
        if (!props.available) {
            if (props.preferenceUnavailable === undefined) {
                return;
            }
            props.preferenceUnavailable(props.preference);
            return;
        }

        if (!props.active) {
            props.addSelectedPreferences(props.preference);
        } else {
            props.removeSelectedPreferences(props.preference);
        }
    }

    let buttonClasses = GetButtonClasses(props.active, props.available);
    return (
        <div>
            <div onClick={() => handleOnClick()} className={buttonClasses}>
                <div className={"preferenceContent"}>
                    {!props.available && <div className="preferenceContentIcon">
                        <Star className={"preferenceButtonIcon"} width={12} height={12}/>
                    </div>}
                    {props.icon &&
                        <div className="preferenceContentIcon">
                            <img alt="Premium Playlist" className="preferenceButtonIcon" src={props.icon}/>
                        </div>
                    }
                    <div className="preferenceContentText">
                        <span>{props.preference}</span> <span className={"numberOfApplicableSongs"}>{props.numberOfApplicableSongs}</span>
                    </div>
                </div>
            </div>
            {props.mostRecentlyClicked && props.available && !props.roomForMorePreferences && <PreferenceButtonError text={props.tooManySelectedPreferencesText}/>}
        </div>
    );
}

const GetButtonClasses = (active: boolean, available: boolean): string => {
    let buttonClasses = "preferenceButton unselectable ";
    if (active) {
        buttonClasses += "selected ";
    }

    if (!available) {
        buttonClasses += "unavailable ";
    }
    if (available && !active) {
        buttonClasses += "deselected ";
    }

    return buttonClasses
}

interface PreferenceButtonErrorProps {
    text: string;
}

const PreferenceButtonError = (props: PreferenceButtonErrorProps) => {
    return (
        <div className="preferenceButtonError">{props.text}</div>
    )
}
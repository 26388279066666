import './DeletePreferenceModal.css';
import React, {useEffect, useRef, useState} from "react";
import {Overlay} from "../../../common/Overlay";
import {ReactComponent as CloseModal} from "./x.svg";
import {SmallestWarningButton} from "../../../common/Buttons";

interface DeletePreferenceModalProps {
    shown: boolean;
    confirmDeletePreference: () => void;
    cancelDeletePreference: () => void;
}

export const DeleteModalNotShownKey = "DeleteModalNotShown"; 

export const DeletePreferenceModal = (props: DeletePreferenceModalProps) => {
    const ref: any = useRef(null);
    const [dontShowModalAgain, setDontShowModalAgain] = useState<boolean>(false);
    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                cancelDelete();
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    if (!props.shown)
        return <React.Fragment/>

    function cancelDelete() {
        setDontShowModalAgain(false);
        props.cancelDeletePreference();
    }
    
    function confirmDelete() {
        if (dontShowModalAgain) {
            localStorage.setItem(DeleteModalNotShownKey, "true");
        }
        props.confirmDeletePreference();
    }
    
    return (
        <div>
            <Overlay/>
            <div ref={ref} className="deletePreferenceModal">
                <div className={"deletePreferenceModalHeader"}>
                    <p>Are you sure you want to delete the playlist?</p>
                    <div className={"closeModal"}>
                        <CloseModal className={"closeModalButton"} onClick={() => cancelDelete()}/>
                    </div>
                </div>
                <SmallestWarningButton 
                    center={true}
                    marginTop={true} 
                    marginBottom={true}
                    onClick={confirmDelete}
                    text={"Delete"}/>
                <div onClick={() => setDontShowModalAgain(!dontShowModalAgain)} className={"dontShowModalAgain"}>
                    <input onChange={() => setDontShowModalAgain(!dontShowModalAgain)} type="checkbox" checked={dontShowModalAgain}/>
                    <div>Don't show this again.</div>
                </div>
            </div>
        </div>
    )
}
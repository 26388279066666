import React from "react";
import './Groups.css';
import {useAuthorizationContext} from "../../../context/authorizationContext";
import {GetAccessWithPremiumButton, PlaylistRow, PlaylistTable} from "./Groups";
import {GoToLoginButton} from "../../common/Buttons";
import {MembershipLevel, useUserContext} from "../../../context/userContext";

export const Randomized = () => {
    const {loggedIn} = useAuthorizationContext();
    const {membershipLevel} = useUserContext();
    const showPremiumButton = loggedIn === false || membershipLevel !== MembershipLevel.PremiumYearly;
    
    return (
        <div>
            <div className="group pageContentSegment">
                <h1>Randomized playlists</h1>
                <p>Liked Songs Manager lets you create randomized playlists updated daily and weekly.</p>
                <PlaylistTable>
                    <PlaylistRow name={"200 Random Songs - Updated Weekly"} availableFree={false}/>
                    <PlaylistRow name={"50 Random Songs - Updated Daily"} availableFree={false}/>
                </PlaylistTable>
                {showPremiumButton ? <GetAccessWithPremiumButton/> : null}
            </div>
        </div>
    );
}


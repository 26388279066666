export interface Criterion {
    criterionNumber: number;
    genres: string[];
    decade?: number;
    mood?: string;
    nationality?: string;
}

export function isCriterionEmpty(criterion: Criterion): boolean {
    return criterion.genres.length === 0 && criterion.decade === undefined && criterion.mood === undefined && criterion.nationality === undefined;
}
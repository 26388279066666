import React from "react";

const NotFoundSegment = () => {
    return (
        <div className="pageContentSegment">
            <h1>Not found</h1>
            <p>We've been looking everywhere, but we're not able to find the page that you're looking for 😔.</p>
            <p><a href={"/playlists"}>Go back to Playlists</a>.</p>
        </div>
    )
}

export default NotFoundSegment;
import './PreferenceManagementConfirmButtonsModule.css'
import React from "react";
import {ConfirmationButton, WarningButton} from '../../../common/Buttons';

interface PreferenceManagementConfirmButtonsModuleProps {
    distribute: () => void;
}

const PreferenceManagementConfirmButtonsModule = (props: PreferenceManagementConfirmButtonsModuleProps) => {
    return (
        <div className="preferenceManagementConfirmButtons">
            <ConfirmationButton
                onClick={props.distribute}
                text={"Create selected playlists"}/>
        </div>
    )
}

export default PreferenceManagementConfirmButtonsModule;
import React from "react";

const NoCapacity = () => {
    return (
        <React.Fragment>
            <div className="pageContentSegment">
                <h2>No capacity</h2>
                <p>We are really sorry, but we only allow a certain number of users to join every day. 😔</p>
                <p>Come back at a later time and check again.</p>
            </div>
        </React.Fragment>
    )
};

export default NoCapacity;
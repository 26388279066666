import {ReactComponent as AvailableSvgIcon} from "./check.svg";
import {ReactComponent as NotAvailableSvgIcon} from "./x.svg";
import React from "react";

export const AvailableIcon = () => {
    return (
        <div className="availabilityIcon">
            <AvailableSvgIcon/>
        </div>
    )
}

export const NotAvailableIcon = () => {
    return (
        <div className="availabilityIcon">
            <NotAvailableSvgIcon/>
        </div>
    )
}
import React, {useEffect, useState} from "react";
import {LandingPage} from "./LandingPage";
import {getPlaylistPreferences} from '../../api/playlistpreferences/PlaylistPreferencesApi';
import './Playlists.css';
import {AvailableSimplePlaylistPreference, CombinatorialPlaylistPreference, PlaylistPreferences,} from "../../api/playlistpreferences/PlaylistPreferences";
import {SimplePreference} from "./management/simple/SimplePreference";
import {CombinatorialPreference} from "./management/combinatorial/CombinatorialPreference";
import PlaylistManagement from "./management/PlaylistManagement";
import {useAuthorizationContext} from '../../context/authorizationContext'
import {MembershipLevel, useUserContext} from "../../context/userContext";
import {PreferenceGroup} from "./management/simple/PreferenceGroup";
import PlaylistsLoading from "./PlaylistsLoading";
import {AllSpotifyGenres} from "./SpotifyGenres";


interface PlaylistsProps {
    navigate: (location: string) => void;
    location: string;
}

const Playlists = (props: PlaylistsProps) => {
    const [currentSimplePreferences, setCurrentSimplePreferences] = useState<SimplePreference[]>();
    const [preferenceGroups, setPreferenceGroups] = useState<PreferenceGroup[]>();
    const [currentCombinatorialPreferences, setCurrentCombinatorialPreferences] = useState<CombinatorialPreference[]>();
    const {loggedIn} = useAuthorizationContext();
    const {membershipLevel} = useUserContext();

    useEffect(() => {
        if (loggedIn === undefined || !loggedIn)
            return;

        getPlaylistPreferences()
            .then((playlistPreferences: PlaylistPreferences) => {
                let simplePreferences = playlistPreferences.availableSimplePlaylistPreferences
                    .map((playlistPreference: AvailableSimplePlaylistPreference) => {
                        return {
                            name: playlistPreference.name,
                            group: playlistPreference.group,
                            selected: false,
                            userHasPreference: false, // Initialize to false 
                            mostRecentlySelected: false,
                            available: playlistPreference.available,
                            numberOfSongsApplicable: 0,
                        }
                    });
                
                for (let spotifyGenre of AllSpotifyGenres) {
                    simplePreferences.push({
                        name: spotifyGenre,
                        userHasPreference: false, // Initialize to false
                        group: "Subgenre",
                        available: true,
                        selected: false,
                        mostRecentlySelected: false,
                        numberOfSongsApplicable: 0
                    })
                }
                
                for (let userPreference of playlistPreferences.usersSimplePlaylistPreferences) { 
                    let preference = simplePreferences.find(preference => preference.group == userPreference.group && preference.name == userPreference.name);
                    if (preference) {
                        preference.userHasPreference = true;
                    }
                }
                setPreferenceGroups(playlistPreferences.preferenceGroups);
                setCurrentSimplePreferences(simplePreferences);
                let combinatorialPreferences = playlistPreferences.combinatorialPlaylistPreferences
                    .map((playlistPreference: CombinatorialPlaylistPreference) => {
                        return {
                            name: playlistPreference.playlistName,
                            id: playlistPreference.combinatorialPlaylistPreferenceId,
                            criteria: playlistPreference.criteria,
                            active: playlistPreference.active
                        }
                    });
                setCurrentCombinatorialPreferences(combinatorialPreferences);
            })
            .catch(res => {
                setCurrentSimplePreferences([]);
            })
    }, [loggedIn]);

    if (loggedIn === false)
        return <LandingPage/>;

    if (loggedIn === undefined)
        return <React.Fragment/>

    if (currentSimplePreferences === undefined || currentCombinatorialPreferences === undefined || preferenceGroups === undefined || membershipLevel === undefined)
        return <PlaylistsLoading/>

    let userIsNoneFree = membershipLevel != MembershipLevel.Free;
    let userHasUnlimitedPlaylists = membershipLevel == MembershipLevel.PremiumYearly;
    let userHasAccessToCombinatorialPlaylists = membershipLevel == MembershipLevel.FreeBeforePremiumIntroduced || membershipLevel == MembershipLevel.PremiumYearly;
    return <PlaylistsLoggedIn currentCombinatorialPreferences={currentCombinatorialPreferences}
                              currentSimplePreferences={currentSimplePreferences}
                              preferenceGroups={preferenceGroups}
                              navigate={props.navigate}
                              location={props.location}
                              userHasUnlimitedPlaylists={userHasUnlimitedPlaylists}
                              userIsNoneFree={userIsNoneFree}
                              userHasAccessToCombinatorialPlaylists={userHasAccessToCombinatorialPlaylists}/>;
}

interface PlaylistsLoggedInProps {
    userIsNoneFree: boolean;
    currentSimplePreferences: SimplePreference[];
    preferenceGroups: PreferenceGroup[];
    currentCombinatorialPreferences: CombinatorialPreference[];
    navigate: (location: string) => void;
    location: string;
    userHasUnlimitedPlaylists: boolean;
    userHasAccessToCombinatorialPlaylists: boolean;
}

const PlaylistsLoggedIn = (props: PlaylistsLoggedInProps) => {
    return (
        <PlaylistManagement
            currentSimplePreferences={props.currentSimplePreferences}
            currentCombinatorialPreferences={props.currentCombinatorialPreferences}
            preferenceGroups={props.preferenceGroups}
            navigate={props.navigate}
            location={props.location}
            userHasUnlimitedPlaylists={props.userHasUnlimitedPlaylists}
            userIsNoneFree={props.userIsNoneFree}
            userHasAccessToCombinatorialPlaylists={props.userHasAccessToCombinatorialPlaylists}/>

    )
}

export default Playlists;
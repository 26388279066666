import {useAuthorizationContext} from "../../context/authorizationContext";
import {MembershipLevel, useUserContext} from "../../context/userContext";
import PageSegmentLoading from "../common/PageSegmentLoading";
import React from "react";
import {Link} from "react-router-dom";

const MembershipChangePaymentDetailsSuccess = () => {
    const {loggedIn} = useAuthorizationContext();
    const {membershipLevel} = useUserContext();

    if (loggedIn === undefined || membershipLevel !== MembershipLevel.PremiumYearly)
        return <PageSegmentLoading/>

    return (
        <div className="pageContentSegment">
            <h2>Payment details updated</h2>
            <p>Your payment details have been succesfully updated.</p>
            <p><Link to={'/playlists'}>Try out your features</Link> or check out <Link to={'/plans'}>plans again</Link> if you need to make further changes.</p>
        </div>
    )
}
export default MembershipChangePaymentDetailsSuccess;
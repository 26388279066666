export const getTimeDifference = (date1: Date, date2?: Date): string => {
    if (date2 === undefined)
        return "";

    var differenceInSeconds = (date2.getTime() - date1.getTime()) / 1000;

    return `${differenceInSeconds} seconds`;
}

export const parseDate = (dateString: string): Date => {
    return new Date(Date.parse(dateString));
}

export const formatDateWithoutTime = (date?: Date): string => {
    if (date === undefined)
        return "";

    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
}

export const formatDate = (date?: Date): string => {
    if (date === undefined)
        return "";

    let datePart = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

    let hours = formatTimePart(date.getHours());
    let minutes = formatTimePart(date.getMinutes());
    let seconds = formatTimePart(date.getSeconds());
    let timePart = `${hours}:${minutes}:${seconds}`;

    return `${datePart} ${timePart}`;
}

const formatTimePart = (timePart: number): string => {
    return timePart < 10 ? `0${timePart}` : timePart.toString();
}
import React from "react";
import {useSearchParams} from "react-router-dom";
import Progress from "../../../progress/Progress";

const CreateCombinatorialPlaylistSuccess = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    let distributionProgressId = searchParams.get("distributionProgressId");
    if (distributionProgressId !== null)
        sessionStorage.setItem("latestDistributionProgressId", distributionProgressId);

    let playlistName = searchParams.get("playlistName");

    return (
        <div>
            <div className="pageContentSegment">
                <h2>Combination playlist {playlistName} created</h2>
                <p>Your combination playlist <span>{playlistName}</span> has been created and will be kept up to date.</p>
                <Progress progressId={distributionProgressId}/>
            </div>
            <div className="pageContentSegment">
                <h2>Want to see your combination playlists or create another one?</h2>
                <p>Go to <a href="/playlists/combinations">combinaton playlists</a> if you wish to see your created combination playlists or make further changes.</p>
            </div>
            <div className="pageContentSegment">
                <h2>Is your playlist not appearing in Spotify?</h2>
                <p>
                    This is most likely because you do not have any songs that are applicable for the playlist that you just created. The playlist is created in Spotify
                    when at least one of your songs match the playlists' criteria.
                </p>
            </div>
        </div>
    )
}

export default CreateCombinatorialPlaylistSuccess;
import React from "react";
import './Button.css'
import paths from '../../api/authorization/AuthorizationApi';
import {Link} from "react-router-dom";

interface ButtonProps {
    onClick?: () => void;
    marginTop?: boolean;
    marginBottom?: boolean;
    text: string;
    center?: boolean;
    noFixedWidth?: boolean;
}

interface SmallerButtonProps {
    onClick?: () => void;
    text: string;
}

export const ConfirmationButton = (props: ButtonProps) => {
    let classes = "button unselectable"
    if (props.marginTop) {
        classes += " buttonMarginTop";
    }
    if (props.marginBottom) {
        classes += " buttonMarginBottom";
    }
    if (props.center) {
        classes += " centerButton";
    }
    if (props.noFixedWidth) {
        classes += " noFixedWidth";
    }

    return (
        <div className={classes}>
            <div onClick={props.onClick} className="buttonConfirmation">{props.text}</div>
        </div>
    )
}

export const SmallerConfirmationButton = (props: SmallerButtonProps) => {
    return (
        <div className="smallerButton unselectable">
            <div onClick={props.onClick} className="buttonConfirmation">{props.text}</div>
        </div>
    )
}

export const SmallestConfirmationButton = (props: SmallerButtonProps) => {
    return (
        <div className="smallestButton unselectable">
            <div onClick={props.onClick} className="buttonConfirmation">{props.text}</div>
        </div>
    )
}

export const SmallerCancelButton = (props: SmallerButtonProps) => {
    return (
        <div className="smallerButton unselectable">
            <div onClick={props.onClick} className="buttonCancel">{props.text}</div>
        </div>
    )
}

export const WarningButton = (props: ButtonProps) => {
    let classes = "button unselectable"
    if (props.marginTop) {
        classes += " buttonMarginTop";
    }
    if (props.marginBottom) {
        classes += " buttonMarginBottom";
    }
    if (props.center) {
        classes += " centerButton";
    }
    if (props.noFixedWidth) {
        classes += " noFixedWidth";
    }

    return (
        <div className={classes}>
            <div onClick={props.onClick} className="buttonWarning">{props.text}</div>
        </div>
    )
}

export const SmallestWarningButton = (props: ButtonProps) => {
    let classes = "smallestButton unselectable"
    if (props.marginTop) {
        classes += " buttonMarginTop";
    }
    if (props.marginBottom) {
        classes += " buttonMarginBottom";
    }
    let containerClasses = ""
    if (props.center) {
        containerClasses += " centerButton";
    }
    if (props.noFixedWidth) {
        classes += " noFixedWidth";
    }

    return (
        <div className={containerClasses}>
            <div className={classes}>
                <div onClick={props.onClick} className="buttonWarning">{props.text}</div>
            </div>
        </div>
    )
}

interface LoginButtonProps {
    authorizeUrl?: string;
    noFixedWidth?: boolean;
    noMarginTop?: boolean;
}

export const GoToLoginButton = (props: LoginButtonProps) => {
    let buttonClasses = "button unselectable buttonMarginBottom buttonMarginTop";
    if (props.noFixedWidth) {
        buttonClasses += " noFixedWidth";
    }
    let loginSectionClasses = "loginSection";
    if (props.noMarginTop) {
        loginSectionClasses += " noMarginTop";
    }
    return (
        <div className={loginSectionClasses}>
            <div className="loginButton unselectable">
                <div className={buttonClasses}>
                    <a href={'/login'}>
                        <div className="buttonConfirmation">Get started for free</div>
                    </a>
                </div>
            </div>
        </div>
    )
}

export const LoginButton = (props: LoginButtonProps) => {
    const authorizeUrl = props.authorizeUrl !== undefined ? props.authorizeUrl : paths.authorize();
    let buttonClasses = "button unselectable buttonMarginBottom buttonMarginTop";
    if (props.noFixedWidth) {
        buttonClasses += " noFixedWidth";
    }
    let loginSectionClasses = "loginSection";
    if (props.noMarginTop) {
        loginSectionClasses += " noMarginTop";
    }
    return (
        <div className={loginSectionClasses}>
            <div className="loginButton unselectable">
                <div className={buttonClasses}>
                    <a href={authorizeUrl}>
                        <div className="buttonConfirmation">Sign in with Spotify</div>
                    </a>
                </div>
            </div>
            <PrivacyAndTermsDisclaimer/>
        </div>
    )
}

const PrivacyAndTermsDisclaimer = () => {
    return (
        <div className="privacyAndTermsDisclaimer button">
            <div className="privacyAndTermsDisclaimerTerms">By clicking 'Sign in with Spotify' and if you are not a Liked Songs Manager user,
                you will be registered and you will need to agree to Liked Songs Manager's <a href="/policies/terms">Terms of Service</a>.
            </div>
            <div>To learn more about how Liked Songs Manager collects, uses, and shares your personal data please see Liked Songs Manager's <a
                href="/policies/privacy-policy">Privacy Policy</a>.</div>
        </div>
    )
}
import React, {useEffect, useState} from 'react';
import './App.css';
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import Playlists from './pages/playlists/Playlists';
import NotFound from './pages/notfound/NotFound';
import NoCapacity from './pages/nocapacity/NoCapacity';
import DistributionSuccess from './pages/playlists/management/simple/success/DistributionSuccess';
import CreateCombinatorialPlaylistSuccess from './pages/playlists/management/combinatorial/success/CreateCombinatorialPlaylistSuccess';
import Admin from './pages/admin/Admin';
import Feedback from './pages/feedback/Feedback';
import CreateFeedbackSuccess from './pages/feedback/CreateFeedbackSuccess';
import {MembershipLevel, UserContext} from './context/userContext';
import {getMembershipLevel} from "./api/user/UserApi";
import Plans from './pages/plans/Plans';
import MembershipUpgradeSuccess from "./pages/plans/MembershipUpgradeSuccess";
import PlansLogin from "./pages/plans/PlansLogin";
import {FeedbackPolicy} from "./pages/feedback/FeedbackPolicy";
import MembershipChangePaymentDetailsSuccess from "./pages/plans/MembershipChangePaymentDetailsSuccess";
import SimplePageBuilder from './pages/SimplePage';
import UserDetails from "./pages/admin/UserDetails";
import {updateReferrer} from "./util/Referrer";
import {AuthorizationContext} from './context/authorizationContext';
import Header from "./header/Header";
import ComplexPageBuilder from "./pages/ComplexPage";
import Login from "./pages/common/Login";
import {Settings} from "./pages/settings/Settings";
import Faq from "./pages/faq/Faq";
import {Policies} from "./pages/policies/Policies";
import {PrivacyPolicy} from "./pages/policies/PrivacyPolicy";
import {Terms} from "./pages/policies/Terms";
import {Refund} from "./pages/policies/Refund";
import {Cancellation} from "./pages/policies/Cancellation";
import Licenses from "./pages/policies/Licenses";
import DataDeleted from "./pages/settings/DataDeleted";
import {Mood} from "./pages/playlists/groups/Mood";
import {Genre} from "./pages/playlists/groups/Genre";
import {Decade} from "./pages/playlists/groups/Decade";
import {Nationality} from "./pages/playlists/groups/Nationality";
import {LikedAt} from "./pages/playlists/groups/LikedAt";
import {Randomized} from "./pages/playlists/groups/Randomized";
import {LatestLikedSongs} from "./pages/playlists/groups/LatestLikedSongs";
import {Miscellaneous} from "./pages/playlists/groups/Miscellaneous";
import {CombinationPlaylistExamples} from "./pages/playlists/groups/Combinations";

function App() {
    const navigate = useNavigate();
    const location = useLocation();
    const [loggedIn, setLoggedIn] = useState<boolean | undefined>(undefined);
    const [membershipLevel, setMembershipLevel] = useState<MembershipLevel | undefined>(undefined);
    useEffect(() => {
        updateReferrer()
    }, []);

    useEffect(() => {
        getMembershipLevel()
            .then((result: string) => {
                setLoggedIn(true);
                setMembershipLevel(MembershipLevel[result as keyof typeof MembershipLevel]);
            })
            .catch(() => {
                setLoggedIn(false);
                setMembershipLevel(MembershipLevel.Free);
            });
    }, []);

    const defaultMetaDescription = "Make playlists from your Spotify liked songs with Liked Songs Manager and have them kept up to date automatically as you like new songs. Choose from many playlists such as genre, mood, nationality, and more.";
    let simplePageBuilder = SimplePageBuilder(defaultMetaDescription);
    let complexPageBuilder = ComplexPageBuilder(defaultMetaDescription);

    return (
        <React.Fragment>
            <AuthorizationContext.Provider value={{loggedIn, setLoggedIn}}>
                <Header navigate={navigate} location={location.pathname}/>
                <UserContext.Provider value={{membershipLevel: membershipLevel, setMembershipLevel: setMembershipLevel}}>
                    <Routes>
                        <Route path="/playlists"
                               element={complexPageBuilder(
                                   "Automate your Spotify playlist management",
                                   <Playlists location={location.pathname} navigate={navigate}/>)}/>
                        <Route path="/playlists/combinations"
                               element={complexPageBuilder(
                                   "Automate your Spotify playlist management",
                                   <Playlists location={location.pathname} navigate={navigate}/>)}/>
                        <Route path="/playlists/combinations/success" element={simplePageBuilder(
                            "Combination playlist created",
                            <CreateCombinatorialPlaylistSuccess/>)}/>
                        <Route path="/playlists/distribution-success" element={simplePageBuilder(
                            "Playlists updated",
                            <DistributionSuccess/>)}/>
                        <Route path="/login" element={simplePageBuilder(
                            "Sign in",
                            <Login/>,
                            "Sign in")}/>
                        <Route path="/faq" element={simplePageBuilder(
                            "FAQ",
                            <Faq/>,
                            "Answers to the most common questions.")}/>
                        <Route path="/policies" element={simplePageBuilder(
                            "Policies",
                            <Policies/>,
                            "The rough print and the fine print. We try to make all our policies as clear, fair, and readable as possible.")}/>
                        <Route path="/policies/privacy-policy" element={simplePageBuilder(
                            "Privacy policy",
                            <PrivacyPolicy/>,
                            "Our guiding principle is to collect only what we need. Here's what that means in practice.")}/>
                        <Route path="/policies/terms" element={simplePageBuilder(
                            "Terms of Service",
                            <Terms/>,
                            "From everyone at Holdsoft Solutions, thank you for using our services! We build them to help you do your best work. There are thousands of people using Liked Songs Manager. Because we don't know every one of our customers personally, we have to put in place some Terms of Service to help keep the ship afloat.")}/>
                        <Route path="/policies/refund-policy" element={simplePageBuilder(
                            "Refund Policy",
                            <Refund/>,
                            "Bad refund policies are infuriating. You feel like the company is just trying to rip you off. We never want our customers to feel that way, so our refund policy is simple: If you're ever unhappy with our products* for any reason, just contact our support team and we'll take care of you.")}/>
                        <Route path="/policies/cancellation-policy" element={simplePageBuilder(
                            "Cancellation policy",
                            <Cancellation/>,
                            "We want satisfied customers, not hostages. That's why we make it easy for you to cancel your Premium subscription.")}/>
                        <Route path="/policies/licenses" element={simplePageBuilder(
                            "Licenses",
                            <Licenses/>,
                            "The following sets forth attribution notices for third party software that may be contained in this application.")}/>
                        <Route path="/settings/data-deleted" element={simplePageBuilder("Data deleted", <DataDeleted/>)}/>
                        <Route path="/no-capacity" element={simplePageBuilder("No capacity", <NoCapacity/>)}/>
                        <Route path="/admin/users" element={simplePageBuilder("Automate your Spotify playlist management", <UserDetails/>)}/>
                        <Route path="/admin" element={simplePageBuilder("Automate your Spotify playlist management", <Admin/>)}/>
                        <Route path="/feedback" element={simplePageBuilder(
                            "Feedback",
                            <Feedback/>,
                            "Here you may provide specific feedback on existing products, for example songs being wrongly added to a playlist.")}/>
                        <Route path="/feedback/success" element={simplePageBuilder("Feedback has been sent", <CreateFeedbackSuccess/>)}/>
                        <Route path="/feedback/policy" element={simplePageBuilder(
                            "Feedback policy",
                            <FeedbackPolicy/>,
                            "Holdsoft Solutions has a policy that does not allow Holdsoft Solutions and its employees and contractors to accept, review or consider any unsolicited ideas, suggestions, proposals, materials, or the like including but not limited to products, services, product enhancements, product names, or content.")}/>
                        <Route path="/plans" element={complexPageBuilder(
                            "Membership plans",
                            <Plans/>,
                            "Support the development and get even more out of Liked Songs Manager with a Premium subscription.")}/>
                        <Route path="/plans/success" element={simplePageBuilder("Membership upgrade successful", <MembershipUpgradeSuccess/>)}/>
                        <Route path="/plans/payment-details-updated/success" element={simplePageBuilder("Payment details updated", <MembershipChangePaymentDetailsSuccess/>)}/>
                        <Route path="/plans/login" element={simplePageBuilder("Membership plans", <PlansLogin/>)}/>
                        <Route path="/playlists/mood" element={simplePageBuilder("Mood playlists", <Mood/>, "Mood playlists available on Liked Songs Manager.")}/>
                        <Route path="/playlists/genre"
                               element={simplePageBuilder("Genre and subgenre playlists", <Genre/>, "Genre and subgenre playlists available on Liked Songs Manager.")}/>
                        <Route path="/playlists/decade" element={simplePageBuilder("Decade playlists", <Decade/>, "Decade playlists available on Liked Songs Manager.")}/>
                        <Route path="/playlists/nationality"
                               element={simplePageBuilder("Nationality playlists", <Nationality/>, "Nationality playlists available on Liked Songs Manager.")}/>
                        <Route path="/playlists/liked-at" element={simplePageBuilder("Liked At playlists", <LikedAt/>, "Liked At playlists available on Liked Songs Manager.")}/>
                        <Route path="/playlists/randomized"
                               element={simplePageBuilder("Randomized playlists", <Randomized/>, "Randomized playlists available on Liked Songs Manager.")}/>
                        <Route path="/playlists/latest-liked-songs"
                               element={simplePageBuilder("Latest Liked Songs playlists", <LatestLikedSongs/>, "Latest Liked Songs playlists available on Liked Songs Manager.")}/>
                        <Route path="/playlists/miscellaneous"
                               element={simplePageBuilder("Miscellaneous playlists", <Miscellaneous/>, "Miscellaneous playlists available on Liked Songs Manager.")}/>
                        <Route path="/playlists/combination"
                               element={simplePageBuilder("Combination playlists", <CombinationPlaylistExamples/>, "Combination playlists allow you to build custom playlists from your liked songs by combining genre, decade, mood, and nationality filters.")}/>
                        <Route path="/settings" element={simplePageBuilder("Settings", <Settings/>)}/>
                        <Route path="/" element={complexPageBuilder("Automate your Spotify playlist management", <Playlists location={location.pathname} navigate={navigate}/>)}/>
                        <Route path="*" element={simplePageBuilder("Not found", <NotFound/>)}/>
                    </Routes>
                </UserContext.Provider>
            </AuthorizationContext.Provider>
        </React.Fragment>
    );
}

export default App;
import {startJob} from "../../api/admin/BatchApi";
import {BatchData, BatchExecution} from "../../api/admin/Statistics";
import {SmallerConfirmationButton} from "../common/Buttons";
import React, { useState } from "react";
import {formatDate, getTimeDifference, parseDate} from "../../util/Dates";
import { ErrorMessage } from "../common/ErrorMessage";

interface BatchProps {
    batchData: BatchData;
    jobStarted: () => void;
}

const Batch = (props: BatchProps) => {
    const [errorMessage, setErrorMessage] = useState<string>();
    
    let batchRows = props.batchData.latestBatchExecutions
        .map((batchExecution: BatchExecution) => <BatchRow key={batchExecution.id}
                                                           startTime={parseDate(batchExecution.startTime)}
                                                           endTime={batchExecution.endTime !== undefined ? parseDate(batchExecution.endTime) : undefined}/>);
    return (
        <div>
            <h3>Batch</h3>
            <table>
                <thead>
                <tr className={"bordered"}>
                    <th>Start Time</th>
                    <th>End Time</th>
                    <th>Duration</th>
                </tr>
                </thead>
                <tbody>
                {batchRows}
                </tbody>
            </table>
            <SmallerConfirmationButton onClick={startJobClick} text={"Start Job"}/>
            <ErrorMessage errorMessage={errorMessage} />
        </div>
    )

    function startJobClick() {
        setErrorMessage(undefined);
        
        startJob()
            .then((jobStarted: boolean) => {
                if (jobStarted) {
                    props.jobStarted();
                } else {
                    setErrorMessage("Job did not start, presumably because a job has not finished.");
                }
            });
    }
}

interface BatchRowProps {
    startTime: Date;
    endTime: Date | undefined;
}

const BatchRow = (props: BatchRowProps) => {
    return (
        <tr className={"bordered"}>
            <td>{formatDate(props.startTime)}</td>
            <td>{formatDate(props.endTime)}</td>
            <td>{getTimeDifference(props.startTime, props.endTime)}</td>
        </tr>
    )
}

export default Batch;
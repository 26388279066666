import React from "react";
import useDocumentTitle, { useMetaDescription } from "../util/ChangeTitleHook";
import {useUserContext} from "../context/userContext";
import {useAuthorizationContext} from "../context/authorizationContext";
import Footer from "../footer/Footer";

interface ComplexPageProps {
    page: React.ReactElement
    title: string;
    metaDescription: string;

}

const ComplexPage = (props: ComplexPageProps) => {
    const {loggedIn} = useAuthorizationContext();
    const {membershipLevel} = useUserContext();
    useDocumentTitle(props.title);
    useMetaDescription(props.metaDescription);
    
    let userLoggedInLoading = loggedIn !== undefined && membershipLevel !== undefined;
    if (!userLoggedInLoading)
        return <div id="pageContainer"/>

    return (
        <React.Fragment>
            {userLoggedInLoading && props.page}
        </React.Fragment>
    )
}

const ComplexPageBuilder = (defaultMetaDescription: string) => {
    return function (title: string, content: React.ReactElement, metaDescription?: string,) {
        return <ComplexPage metaDescription={metaDescription ?? defaultMetaDescription} page={content} title={title}/>
    }
}

export default ComplexPageBuilder;
import './Faq.css';
import React, {useState} from "react";

export const Faq = () => {
    return (
        <div>
            <div className="pageContentSegment">
                <h1>FAQ</h1>
                <h2>Subscription</h2>
                <FaqQuestion
                    question={"How do I cancel my Premium subscription?"}
                    answer={<div>
                        <p>If you wish to cancel your Premium subscription go to <a href="/plans">Plans</a>.</p>
                        <p>Note that you even if you cancel your Premium subscription, you will still keep your Premium advantages for the remainder of the subscription
                            period.</p>
                    </div>}/>
                <FaqQuestion
                    question={"How do I change payments details for my Premium subscription?"}
                    answer={<div>
                        <p>If you need to update your payment details for your Premium subscription go to <a href="/plans">Plans</a>.</p>
                    </div>}/>
                <FaqQuestion
                    question={"How do I re-activate my cancelled Premium subscription?"}
                    answer={<div>
                        <p>If you have cancelled your Premium subscription, but wish to re-activate it and the subscription has not yet passed the next billing date go to <a
                            href="/plans">Plans</a>.</p>
                    </div>}/>

                <h2>Playlists</h2>
                <FaqQuestion
                    question={"Can I rename playlists created by Liked Songs Manager?"}
                    answer={<p>Yes, you can rename your playlists to whatever you want, they will still be kept up to date by Liked Songs Manager. This includes your
                        combination playlists as well.</p>}/>
                <FaqQuestion
                    question={"Can I put playlists created by Liked Songs Manager into folders?"}
                    answer={<p>Yes, you can put your playlists into folders, they will still be kept up to date by Liked Songs Manager.</p>}/>
                <FaqQuestion
                    question={"How often are my liked songs checked for updates?"}
                    answer={<p>Your songs are checked continuously, about every hour.</p>}/>
                <FaqQuestion
                    question={"If I remove a song from a playlist, will it get re-added?"}
                    answer={<p>The song will not be re-added to the playlist.</p>}/>
                <FaqQuestion
                    question={"Will Liked Songs Manager remove the songs which I have removed from my liked songs from the managed playlists?"}
                    answer={
                        <React.Fragment>
                            <p>Unfortunately to properly support this, it would be necessary to fetch every user's entire library of liked songs every time Liked Songs Manager
                                checks whether there are new songs. This would lead to API throttling by Spotify, and is therefore not employed.</p>
                            <p>If you wish to recreate the playlists from scratch, this be achieved by deleting the playlist, and then creating it again.</p>
                        </React.Fragment>
                    }/>
                <FaqQuestion question={"What happens if a playlist would reach its maximum capacity (10000 songs)?"}
                             answer={<p>
                                 Liked Songs Manager will create an additional playlist when there is no remaining capacity in the playlist. E.g. if you have liked 15000 songs,
                                 and you
                                 select the 'Everything' playlist, Liked Songs Manager will create a playlist of the first 10000 songs called Everything, and a secondary
                                 playlist with 5000
                                 songs called
                                 Everything 2. Once that playlist reaches its limit, it will create a new playlist named Everything 3, and so forth.</p>}/>
                <h2>Errors</h2>
                <FaqQuestion
                    question={"The app keeps not working for me. What could be the reason?"}
                    answer={
                        <React.Fragment>
                            <p>There is a bug on Spotify's end, making them return an error response immediately when trying to fetch any number liked songs for a user who has more
                                than about
                                20000 songs. That unfortunately renders the service useless if you have this amount of songs. We're sincerely sorry about the inconvenience.</p>
                            <p>We have reported the bug <a target="_blank"
                                                           href="https://community.spotify.com/t5/Spotify-for-Developers/me-tracks-consistently-returns-502-for-user-with-many-liked/m-p/5583155">here</a>.
                            </p>
                        </React.Fragment>}/>
                <FaqQuestion
                    question={"My selected playlists are not being created. How can that be?"}
                    answer={<React.Fragment>
                        <p>This question has multiple answers, but the most common reasons are:</p>
                        <ul>
                            <li>You have no liked songs that match the playlist.</li>
                            <li>It may take a few seconds for the playlists to appear in Spotify. Occasionally it is also necessary to restart Spotify.</li>
                            <li>You have reached the maximum number of playlists you can have. This seems to be around 10000, but the number is not officially documented.
                                If you believe you have reached this limit, try and delete some of your playlists.
                            </li>
                            <li>There is a bug 🐛 in the system. Do not hesitate to <a href="mailto:support@likedsongsmanager.com">contact us</a> if you believe that this is the
                                case.
                            </li>
                        </ul>
                    </React.Fragment>}/>
            </div>
        </div>
    )
}


interface FaqQuestionProps {
    question: string;
    answer: JSX.Element;
}

export const FaqQuestion = (props: FaqQuestionProps) => {
    const [answerShown, setAnswerShown] = useState<boolean>(false);
    const chevronClasses = answerShown ? "chevron" : "chevron right";
    const faqQuestionAnswerContainerClasses = answerShown ? "faqQuestionContainer expanded" : "faqQuestionContainer collapsed";
    
    return (
        <div className="faqQuestionAnswerContainer">
            <div onClick={() => setAnswerShown(!answerShown)} className={faqQuestionAnswerContainerClasses}>
                <p className="faqQuestion">{props.question}</p>
                <div className={chevronClasses}/>
            </div>
            {answerShown && <div className="faqAnswer">
                {props.answer}
            </div>}
        </div>)
}

export default Faq;
import React from "react";

export const Policies = () => {
    return (
        <div className="pageContentSegment">
            <h1>Policies, Terms, and Legal Stuff</h1>
            <p>The rough print and the fine print. We try to make all our policies as clear, fair, and readable as possible.</p>
            <h2>Disclaimer</h2>
            <p>Each third party content provider is solely responsible for any content it provides, any warranties (to the extent that such warranties have not been
                disclaimed), and for any claims you may have relating to that content or your access or use of that content. Liked Songs Manager has no control over the third
                party content or the availability or quality of such third party content, and assumes no responsibility for material created or published even if accessed or
                called through a Liked Songs Manager service or API.</p>
            <p>In no event shall Liked Songs Managers or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to
                business interruption) arising out of the use or inability to use the materials on Liked Songs Manager's website, even if Liked Songs Manager or a
                Liked Songs Manager authorized representative has been notified orally or in writing of the possibility of such damage.
            </p>
            <h2>Terms of Service</h2>
            <p><a href={"/policies/terms"}>Link to Terms of Service</a></p>
            <p>All product and company names are trademarks or registered® trademarks of their respective holders. Use of them by Liked Songs Manager does not imply any
                affiliation with or endorsement by them.</p>
            <h2>Privacy Policy</h2>
            <p><a href={"/policies/privacy-policy"}>Link to Privacy Policy</a></p>
            <p>The policy sets out the different areas where user privacy is concerned and outlines the obligations & requirements of the users, the website and website owners.
                Furthermore the way this website processes, stores and protects user data and information will also be detailed within this policy.</p>
            <h2>Refund policy</h2>
            <p><a href={"/policies/refund-policy"}>Link to Refund Policy</a></p>
            <h2>Cancellation policy</h2>
            <p><a href={"/policies/cancellation-policy"}>Link to Cancellation Policy</a></p>
            <h2>External Links</h2>
            <p>Although this website only looks to include quality, safe and relevant external links, users are advised adopt a policy of caution before clicking any external web
                links mentioned throughout this website. (External links are clickable text / banner / image links to other websites)</p>
            <p>The owners of this website cannot guarantee or verify the contents of any externally linked website despite their best efforts.</p>
            <p>Users should therefore note they click on external links at their own risk and this website and its owners cannot be held liable for any damages or implications
                caused by visiting any external links mentioned.</p>
            <h2>Third party platforms</h2>
            <p>Spotify® and the <a href={"https://www.spotify.com"}>Spotify</a>, logo are trademarks or registered trademarks of Spotify, used under license agreement</p>
            <p>Paddle® and the <a href={"https://www.paddle.com"}>Paddle</a>, logo are trademarks or registered trademarks of Paddle, used under license agreement</p>
            <p>Adapted from the <a href="https://github.com/basecamp/policies">Basecamp open-source policies</a> / <a href="https://creativecommons.org/licenses/by/4.0/">CC BY
                4.0</a></p>
            <h2>Licenses</h2>
            <p><a href={"/policies/licenses"}>Link to Licenses</a></p>
        </div>
    )
}
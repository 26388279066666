import {CombinatorialPreference} from "./CombinatorialPreference";
import {deleteCombinatorialPlaylist} from '../../../../api/distributor/DistributeApi';
import React, {useEffect, useState} from "react";
import {ApiError, ErrorCode} from "../../../../api/ApiError";
import {Playlist} from "./Playlist";
import CreateCombinatorialPlaylist from "./CreateCombinatorialPlaylist";
import CurrentCombinatorialPreferences from "./CurrentCombinatorialPreferences";
import {DeleteModalNotShownKey, DeletePreferenceModal} from "../common/DeletePreferenceModal";

interface CombinatorialPreferenceManagementProps {
    currentCombinatorialPreferences: CombinatorialPreference[];
    availableDecadePlaylists: Playlist[];
    availableGenrePlaylists: Playlist[];
    availableNationalityPlaylists: Playlist[];
    availableMoodPlaylists: Playlist[];
    userHasAccessToCombinatorialPlaylists: boolean;
}

const CombinatorialPreferenceManagement = (props: CombinatorialPreferenceManagementProps) => {
    const [combinatorialPreferences, setCombinatorialPreferences] = useState<CombinatorialPreference[]>(props.currentCombinatorialPreferences);
    const [wizardInProgress, setWizardInProgress] = useState<boolean>(false);
    const [errorCode, setErrorCode] = useState<ErrorCode>();
    const [playlistBeingDeleted, setPreferenceBeingDeleted] = useState<CombinatorialPreference>();
    const [deleteModalShown, setDeleteModalShown] = useState<boolean>(false);

    return (
        <div>
            <CreateCombinatorialPlaylist
                numberOfCombinatorialPlaylists={combinatorialPreferences.length}
                wizardInProgress={wizardInProgress}
                availableDecadePlaylists={props.availableDecadePlaylists}
                availableGenrePlaylists={props.availableGenrePlaylists}
                availableNationalityPlaylists={props.availableNationalityPlaylists}
                availableMoodPlaylists={props.availableMoodPlaylists}
                updateWizardInProgress={updateWizardInProgress}
                userHasAccessToCombinatorialPlaylists={props.userHasAccessToCombinatorialPlaylists}/>
            {!wizardInProgress && <CurrentCombinatorialPreferences
                combinatorialPreferences={combinatorialPreferences}
                deletePreference={deletePreference}
                errorCode={errorCode}/>}
            <DeletePreferenceModal shown={deleteModalShown} confirmDeletePreference={confirmDeletePreference} cancelDeletePreference={cancelDeletePreference} />
        </div>
    )

    function updateWizardInProgress(inProgress: boolean) {
        setWizardInProgress(inProgress);
    }

    function deletePreference(preference: CombinatorialPreference) {
        let deleteModalNotShown = localStorage.getItem(DeleteModalNotShownKey);
        if (deleteModalNotShown == "true") {
            doDeletePreference(preference);
            return;
        }

        setDeleteModalShown(true);
        setPreferenceBeingDeleted(preference);
    }

    function confirmDeletePreference() {
        setDeleteModalShown(false);
        if (playlistBeingDeleted === undefined)
            return;

        doDeletePreference(playlistBeingDeleted);
    }
    
    function doDeletePreference(preference: CombinatorialPreference) {
        setPreferenceBeingDeleted(undefined);
        setErrorCode(undefined);
        deleteCombinatorialPlaylist(preference.id)
            .then(() => {
                setCombinatorialPreferences(combinatorialPreferences.filter(currentPreference => currentPreference.id !== preference.id));
            })
            .catch((error: ApiError) => setErrorCode(error.errorCode))
    }

    function cancelDeletePreference() {
        setDeleteModalShown(false);
        setPreferenceBeingDeleted(undefined);
    }
}

export default CombinatorialPreferenceManagement;